<div class="report-shipment-us-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>Report Shipment</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <form [formGroup]="formGroup" class="ups-form" (ngSubmit)="onFormSubmit($event, formGroup.value)">
        <div class="form-body">
            <div class="notifications">
                <div class="notification">
                    {{ 'pleaseNote' | translate }}
                    <strong>{{ 'reportShipment.notification1' | translate }}</strong>. <br>{{ 'reportShipment.notification2' | translate }}
                </div>
            </div>

            <div class="select-wrapper">
                <mat-form-field>
                    <mat-label>{{'Carrier' | translate}}</mat-label>
                    <mat-select required formControlName="carrier">
                        <mat-option value="" disabled selected>{{'reportShipment.selectCarrier'|translate}}</mat-option>
                        <mat-option *ngFor="let carrier of carriers" [value]="carrier.CarrierCode">{{
                            carrier.CarrierCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls.carrier?.touched && formGroup.controls.carrier?.invalid">
                    <span *ngIf="formGroup.controls.carrier?.errors?.required">
                        <span>{{'reportShipment.carrierRequired'|translate}}</span>
                    </span>
                    </mat-error>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isCarriersLoading"></upsc-element-blocker>
            </div>
            <div class="select-wrapper">
                <mat-form-field>
                    <mat-label>{{'ServiceType'|translate}}</mat-label>
                    <mat-select required formControlName="serviceType">
                        <mat-option value="" disabled selected>{{'reportShipment.selectServiceType'|translate}}</mat-option>
                        <mat-option *ngFor="let service of filteredServices" [value]="service.ServiceCode">
                            {{ service.Description || service.ServiceCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="formGroup.controls.serviceType?.touched && formGroup.controls.serviceType?.invalid">
                    <span *ngIf="formGroup.controls.serviceType?.errors?.required">
                        <span>{{'ServiceTypeRequired'|translate}}</span>
                    </span>
                    </mat-error>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isServiceTypesLoading"></upsc-element-blocker>
            </div>
            <mat-form-field>
                <mat-label>Coverage</mat-label>
                <input required matInput formControlName="coverage" type="number" min="1" max="1000000000">
                <mat-error *ngIf="formGroup.controls.coverage?.errors">
                    <span *ngIf="formGroup.controls.coverage?.errors?.required">
                        <span>Coverage is required</span>
                    </span>
                    <span *ngIf="formGroup.controls.coverage?.errors?.min">Minimum value is 1.</span>
                    <span *ngIf="formGroup.controls.coverage?.errors?.max">Maximum value is {{ maxInsuredValue }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'TrackingNumber'|translate}}</mat-label>
                <input required matInput formControlName="trackingNumber">
                <mat-error *ngIf="formGroup.controls.trackingNumber?.errors">
                    <span *ngIf="formGroup.controls.trackingNumber?.errors?.required">
                        <span>{{'trackingnumberrequired'|translate}}</span>
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Reference Number</mat-label>
                <input matInput formControlName="referenceNumber">
                <mat-error *ngIf="formGroup.controls.referenceNumber?.errors">
                    <span *ngIf="formGroup.controls.referenceNumber?.errors?.required">
                        <span>Reference number is required</span>
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Zipcode</mat-label>
                <input matInput formControlName="zipCode">
            </mat-form-field>
            <mat-checkbox formControlName="isInsuranceOnly">Insurance only</mat-checkbox>
            <mat-checkbox formControlName="isAgreeToTerms" class="terms">
                I have read, understand, agree, and full compiled with ParcelPro's
                <a href="#" (click)="openTermsAndConditionsDialog($event)">Terms and Conditions</a>
            </mat-checkbox>
        </div>

        <div class="form-footer">
            <div class="form-actions">
                <button type="button" mat-button mat-dialog-close>Close</button>
                <button type="submit" mat-raised-button [disabled]="!isFormValid()">Save</button>
            </div>
        </div>
    </form>
</ng-template>
