import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'localeNumber' })
export class LocaleNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {
  }

  public transform(value: number, minimumFractionDigits = 2, locale?: string): string {
    if (!locale) {
      return this.decimalPipe.transform(value, `1.2-${minimumFractionDigits}`);
    }

    return value.toLocaleString(locale, { minimumFractionDigits });
  }
}
