import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shipmentStatus'
})
export class ShipmentStatusPipe implements PipeTransform {

   transform(value: any): any {
       // console.log(value);
        switch (value) {
            case 0:
                return 'BIR'; // [MV3-1665] Billing Info text is changed to BIR
            case 1:
                return 'Manifest';
            case 2:
                return 'Pickup Scan';
            case 3:
                return 'Voided';
            case 4:
                return 'Label Printed';
            case 5:
                return 'In Transit';
            case 6:
                return 'Delivered';
            case 7:
                return 'Exception (Call customer service)';
            case 8:
                return 'Reprint';
            case 9:
                return 'TNF';
            case 10:
                return 'Billing Info Received (BIR)';
            case 11:
                return 'In Transit (Voided)';
            case 12:
                return 'Delivered (Voided)';
            case 13:
                return 'Voided Exception (Call customer service)';
            case 14:
                return 'Reinstated';
            default: value;
        }
    }

}
