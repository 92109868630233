import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'packages'
})
export class PackagesPipe implements PipeTransform {

  transform(value: any): any {
        switch (value) {
            case '25': return '10kgBox';
            case '24': return '25KgBox';
            case '21': return 'ExpressBox';
            case '01': return 'Letter';
            case '04': return 'Pak';
            case '03': return 'Tube';
            case '02': return 'YourPackaging';
            case '110': return 'Envelope';
            case '111': return 'Pak';
            case '112': return 'Tube';
            case '113': return 'LargeBox';
            case '114': return 'MediumBox';
            case '115': return 'SmallBox';
            default: return value;
        }
    }

}
