import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';

@Component({
  selector: 'upsc-report-shipments-popups',
  templateUrl: './report-shipments-popups.component.html',
  styleUrls: ['./report-shipments-popups.component.scss'],
})
export class ReportShipmentsPopupsComponent {
  public fileName: string = '';

  public constructor(public dialogRef: MatDialogRef<ReportShipmentsPopupsComponent>,
    private readonly reportShipmentService: ReportShipmentService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  public noBtn(): void {
    this.dialogRef.close();
  }

  public yesBtn(): void {
    if (this.data.title === 'Delete Record(s)') {
      this.handleDeleteRecords();
    }
    else if (this.data.title === 'Cancel Submission') {
      this.handleCancelSubmission();
    }
    else if (this.data.title === 'Submit for Insurance') {
      this.handleSubmitForInsurance();
    } else if (this.data.title === 'Delete File') {
      this.handleDeleteFile();
    }

    this.dialogRef.close();
  }


  private handleCancelSubmission(): void {
    this.reportShipmentService.cancelRecentUploadSubmission$.next(this.data.fileId);
  }

  private handleDeleteRecords(): void {
    this.reportShipmentService.deleteRow$.next(this.data.index);
  }

  private handleSubmitForInsurance(): void {
    this.reportShipmentService.submitForInsurance$.next(true);
  }

  private handleDeleteFile(): void {
    this.reportShipmentService.deleteFile$.next(null);
  }
}
