<div class="package-investigation-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ 'PackageInvestigation'|translate }}</h2>
        <mat-dialog-actions>
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>
<ng-template #form>
    <form [formGroup]="investigationForm" class="ups-form">
        <div class="form-body">
            <div class="form-sections">
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{ 'PackageInformation'|translate }}</h5>
                    </div>
                    <div class="investigation-info">
                        <mat-form-field>
                            <mat-label>{{ 'TrackingNumber'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   formControlName="trackingNumber"
                                   maxlength="50"
                                   autocomplete="off"
                                   pattern="[a-zA-Z0-9]+">
                            <mat-error *ngIf="investigationForm.controls.trackingNumber.errors?.required">
                            <span *ngIf="investigationForm.controls.trackingNumber.errors?.required">
                                {{ 'trackingnumberrequired'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{ 'ContactInformation'|translate }}</h5>
                    </div>
                    <div class="investigation-info">
                        <mat-form-field>
                            <mat-label>{{ 'CompanyName'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   formControlName="companyName"
                                   autocomplete="off">
                            <mat-error *ngIf="investigationForm.controls.companyName.errors?.required">
                            <span *ngIf="investigationForm.controls.companyName.errors?.required">                                
                                {{ 'companynamerequired'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'ParcelProAccountNumber'|translate }}</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="accountNo"
                                   readonly="true"
                                   class="readonly-txtbox"
                                   required>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'ContactName'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   formControlName="contactName"
                                   autocomplete="off">
                            <mat-error *ngIf="investigationForm.controls.contactName.errors?.required">
                            <span *ngIf="investigationForm.controls.contactName.errors?.required">                                
                                {{ 'contactnamerequired'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'PhoneNumber'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   formControlName="phoneNumber"
                                   pattern="\d*"
                                   autocomplete="off">
                            <mat-error *ngIf="investigationForm.controls.phoneNumber.errors?.required">
                            <span *ngIf="investigationForm.controls.phoneNumber.errors?.required">                                
                                {{ 'contactphonerequired'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'FaxNumber'|translate }}</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="faxNumber"
                                   autocomplete="off">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'EmailAddress'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   formControlName="email"
                                   autocomplete="off">
                            <mat-error *ngIf="investigationForm.controls.email.errors?.required">
                            <span *ngIf="investigationForm.controls.email.errors?.required">                                
                                {{ 'EnterValidEmail'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{ 'TypeOfInvestigation'|translate }}</h5>
                    </div>
                    <div class="investigation-info">
                        <mat-form-field>
                            <mat-label>Select Loss Type</mat-label>
                            <mat-select required
                                        formControlName="lossType"
                                        (selectionChange)="onLossChanged($event.value)">
                                <mat-option *ngFor="let item of lossTypes" value="{{item}}">{{ item }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="investigationForm.controls.lossType.errors?.required">
                            <span *ngIf="investigationForm.controls.lossType.errors?.required">                                
                                {{ 'selectValidLossType'|translate }}
                            </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="others">
                        <mat-form-field *ngIf="investigationForm.get('lossType').value=='Others'">
                            <mat-label>{{ 'AddOtherContentsHere'|translate }}</mat-label>
                            <textarea matInput
                                      [required]="investigationForm.get('lossType').value=='Others'"
                                      formControlName="others"
                                      autocomplete="off"
                                      matTextareaAutosize
                                      matAutosizeMinRows="5"
                                      matAutosizeMaxRows="5"></textarea>
                            <mat-error
                                    *ngIf="investigationForm.get('lossType').value=='Others' && investigationForm.controls.others.errors?.required">
                            <span *ngIf="investigationForm.get('lossType').value=='Others' && investigationForm.controls.others.errors?.required">                                
                                {{ 'othercommentrequired'|translate }}
                            </span>
                            </mat-error>
                            <!--<input matInput
                                   type="text"
                                   formControlName="others"
                                   placeholder="{{'AddOtherContentsHere'|translate}}"
                                   autocomplete="off">-->
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <div class="form-actions">
                <button mat-raised-button class="alternative-button" type="button" mat-dialog-close>
                    {{ 'Cancel' | translate }}
                </button>
                <button type="button" mat-raised-button [disabled]="!investigationForm.valid" (click)="SaveClaims()"
                        class="page-action">
                    {{ 'Submit'|translate }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
