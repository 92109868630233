import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { LookupService } from '../../../shared/services/lookup/lookup.service';
import { ReportShipmentService } from '../../services/report-shipment.service';
import { IReportShipmentEU } from './models/report-shipment-eu.interface';
import { NotificationType } from '../../../shared/models/notification-type';
import { ICustomerCarrier } from '../../../shared/models/customer-carrier.interface';
import { ShipmentService } from '../../../ship/services/shipment.service';
import { ICountry } from '../../../shared/models/country.interface';
import { Carriers } from '../../../shared/enum/general-enum';
import { Subscription } from 'rxjs';
// import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { User } from '../../../shared/services/user/models/user.model';
import { UserService } from '../../../shared/services/user/user.service';
import * as _ from 'lodash';
import { UserTermsConditionsComponent } from '../../../account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { IReportService } from '../../models/report-service.interface';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';

@Component({
  selector: 'upsc-report-shipment-eu-dialog',
  templateUrl: './report-shipment-eu-dialog.component.html',
  styleUrls: ['./report-shipment-eu-dialog.component.scss'],
})
export class ReportShipmentEuDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: User;
  public userCurrency = 'USD';
  public maxInsuredValue = 0;
  public isPostalCodeAware = true;

  public shipmentTypes = [
    {
      shipmentTypeCode: '1',
      shipmentTypeName: 'Insurance Only',
    },
    {
      shipmentTypeCode: '2',
      shipmentTypeName: 'Freight & Insurance',
    },
  ];
  public carriers: ICustomerCarrier[];
  public destinations = ['Domestic', 'International'];
  public serviceTypes: IReportService[];
  public filteredServices: IReportService[];
  public countries: ICountry[];

  public isCountriesLoading = false;
  public isCarriersLoading = false;
  public isServiceTypesLoading = false;

  public trackingNumberValidationSubscription: Subscription;
  public isTrackingNumberValidating = false;
  public isTrackingNumberValid = false;

  private shipFromCountryCode: string;
  private destination: string;
  private selectedCarrierCode: string;
  private shipmentType: string;
  private serviceType: string;

  private getReportServiceSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private lookupService: LookupService,
    private utilityService: UtilityService,
    private validationService: ValidationService,
    private reportShipmentService: ReportShipmentService,
    private shipmentService: ShipmentService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportShipmentEuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = data.user;
    this.loadInitialValues();
  }

  public ngOnInit() {
    if (this.user && this.userService.isEURUser(this.user.CountryCode)) {
      this.userCurrency = 'EUR';
    } else if (this.user && this.userService.isGBUser(this.user)) {
      this.userCurrency = 'GBP';
    }

    this.formGroup = this.formBuilder.group({
      userId: this.data.user.UserId,
      customerId: this.data.customer.CustomerId,

      carrierInformation: this.formBuilder.group({
        shipFromLocation: ['', Validators.compose([Validators.required])],
        shipmentType: ['', Validators.compose([Validators.required])],
        carrier: ['', Validators.compose([Validators.required])],
        destination: ['', Validators.compose([Validators.required])],
        serviceType: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      }),
      trackingInformation: this.formBuilder.group({
        trackingNumber: ['', Validators.compose([Validators.required])],
        insuredValue: ['', Validators.compose([Validators.required])],
        currencyCode: [this.userCurrency, Validators.compose([Validators.required])],
      }),
      shipToInformation: this.formBuilder.group({
        companyName: [''],
        phoneNumber: [''],
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        country: ['', Validators.compose([Validators.required])],
        province: [''],
        city: ['', Validators.compose([Validators.required])],
        postalCode: ['', Validators.compose([Validators.required])],
        weight: ['', Validators.compose([Validators.required])],
        referenceNumber: [''],
      }),
      isAgreeToTerms: [false, Validators.compose([Validators.requiredTrue])],
    });

    this.monitorValueChanges();
  }

  private loadInitialValues() {
    this.isCarriersLoading = true;
    this.lookupService.getCustomerCarriers(this.data.customer.CustomerId)
      .subscribe(
        (carriers) => {
          this.carriers = carriers;
          this.utilityService.delay(() => {
            this.isCarriersLoading = false;
          });
        },
      );

    this.isCountriesLoading = true;
    this.shipmentService.getShipToCountries(Carriers[Carriers.Ups])
      .subscribe(
        (countries) => {
          this.countries = _.cloneDeep(_.sortBy(countries, ['CountryName']));
          this.utilityService.delay(() => {
            this.isCountriesLoading = false;
          });
        },
      );
  }

  private monitorValueChanges() {
    (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.shipFromLocation.valueChanges
      .subscribe(
        (countryCode) => {
          this.shipFromCountryCode = countryCode;
          this.getServiceTypes();
          this.updateShipToCountry();
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.destination.valueChanges
      .subscribe(
        (destination) => {
          this.destination = destination;
          this.getServiceTypes();
          this.updateShipToCountry();
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.carrier.valueChanges
      .subscribe(
        (carrierCode) => {
          this.selectedCarrierCode = carrierCode;
          (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.serviceType.reset();
          this.getServiceTypes();
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.serviceType.valueChanges
      .subscribe(
        (serviceTypeCode) => {
          if (!serviceTypeCode) {
            return;
          }

          const selectedService = this.serviceTypes.find(item => item.ServiceCode === serviceTypeCode && item.CarrierCode === this.selectedCarrierCode);
          if (!selectedService) {
            return;
          }

          this.setMaxInsuredValue(selectedService.ReportMaxCoverage);
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.shipToInformation).controls.country.valueChanges
      .subscribe(
        (countryCode) => {
          if (!countryCode || !this.countries || !this.countries.length) {
            return;
          }

          const country = this.countries.find(item => item.CountryCode === countryCode);
          if (!country) {
            return;
          }

          this.isPostalCodeAware = country.IsPostalCodeAware;
          const postalCodeForm = (<UntypedFormGroup>this.formGroup.controls.shipToInformation).controls.postalCode;
          if (country.IsPostalCodeAware) {
            this.validationService.setFormControlValidators(postalCodeForm, Validators.compose([Validators.required]));
          } else {
            this.validationService.clearFormControlValidators([postalCodeForm]);
          }
        },
      );
  }

  public validateTrackingNumber(event, trackingNumber: string) {
    event.preventDefault();

    if (!trackingNumber.trim()) {
      return;
    }

    if (this.trackingNumberValidationSubscription) {
      this.trackingNumberValidationSubscription.unsubscribe();
      this.trackingNumberValidationSubscription = null;
    }

    this.isTrackingNumberValidating = true;
    this.trackingNumberValidationSubscription = this.reportShipmentService.validateTrackingNumber(trackingNumber)
      .subscribe(
        result => this.handleValidateTrackingNumberSuccess(result),
        err => this.handleValidateTrackingNumberFailure(err),
      );
  }

  private handleValidateTrackingNumberSuccess(result) {
    this.isTrackingNumberValidating = false;
    this.isTrackingNumberValid = true;
  }

  private handleValidateTrackingNumberFailure(err) {
     this.notificationService.notify(this.errorHandlerService.getHttpErrorMessage(err),
      'Failed validating tracking number', NotificationType.ERROR);

    const trackingNumberControl = (<UntypedFormGroup>this.formGroup.controls.trackingInformation).controls.trackingNumber;
    trackingNumberControl.setErrors({ existed: true });
    this.isTrackingNumberValidating = false;
    this.isTrackingNumberValid = false;
  }

  private getServiceTypes() {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.serviceType;
    if (!this.shipFromCountryCode || !this.selectedCarrierCode || !this.destination) {
      serviceTypeControl.setValue('');
      serviceTypeControl.disable();
      return;
    }

    const isDomestic = this.destination === 'Domestic';
    this.isServiceTypesLoading = true;
    // this.shipmentService.getShippingCarrierServices(this.selectedCarrierCode, isDomestic, this.shipFromCountryCode)
    //     .subscribe(
    //         (serviceTypes) => {
    //           serviceTypeControl.enable();
    //           this.serviceTypes = serviceTypes.filter(
    //               (service) => {
    //                 if (service.hasOwnProperty('IsAllowed')) {
    //                   return service.IsAllowed;
    //                 }
    //
    //                 return true;
    //               },
    //           );
    //
    //           this.isServiceTypesLoading = false;
    //         },
    //         (err) => {
    //           const errorMessage = err.json() ? err.json().Message : err.statusText;
    //           this.notificationService.notify(errorMessage, 'Failed Getting Carrier Services', NotificationType.ERROR);
    //           this.isServiceTypesLoading = false;
    //         },
    //     );

    if (this.serviceTypes) {
      this.handleGetReportServicesSuccess(this.serviceTypes);
    } else {
      this.utilityService.clearSubscriptions([this.getReportServiceSubscription]);
      this.getReportServiceSubscription = this.reportShipmentService.getReportServices()
        .subscribe(
          services => this.handleGetReportServicesSuccess(services),
          err => this.handleGetReportServicesFailure(err),
        );
    }
  }

  private handleGetReportServicesSuccess(services: IReportService[]) {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.serviceType;
    serviceTypeControl.enable();

    const carrierCode = (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.carrier.value.toString();
    this.serviceTypes = services;
    const isDomestic = (<UntypedFormGroup>this.formGroup.controls.carrierInformation).controls.destination.value === 'Domestic';
    this.filteredServices = _.cloneDeep(this.serviceTypes).filter(
      (service) => {
        return service.IsAllowed && carrierCode.toUpperCase() === service.CarrierCode.toUpperCase() && service.IsDomestic === isDomestic;
      },
    );

    this.isServiceTypesLoading = false;
  }

  private handleGetReportServicesFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Getting Carrier Services',
      NotificationType.ERROR);
    this.isServiceTypesLoading = false;
  }

  private updateShipToCountry() {
    const countryControl = (<UntypedFormGroup>this.formGroup.controls.shipToInformation).controls.country;

    if (!this.shipFromCountryCode || !this.destination || this.destination !== 'Domestic') {
      countryControl.enable();
      return;
    }

    countryControl.setValue(this.shipFromCountryCode);
    countryControl.disable();
  }

  public onFormSubmit(event, form) {
    event.preventDefault();

    const reportShipment: IReportShipmentEU = {
      ShipFromCountry: form.carrierInformation.shipFromLocation,
      ShipmentType: form.carrierInformation.shipmentType,
      Carrier: form.carrierInformation.carrier,
      Service: form.carrierInformation.serviceType,

      TrackingNumber: form.trackingInformation.trackingNumber.replace(/\s/g, ''),

      Coverage: form.trackingInformation.insuredValue,
      CoverageCurrencyCode: form.trackingInformation.currencyCode,
      MasterCustomerID: this.data.customer.CustomerId,

      ShipToCity: form.shipToInformation.city,
      ShipToCompanyName: form.shipToInformation.companyName,
      ShipToCountry: this.destination !== 'Domestic' ? form.shipToInformation.country : form.carrierInformation.shipFromLocation,
      ShipToFirstName: form.shipToInformation.firstName,
      ShipToLastName: form.shipToInformation.lastName,
      ShipToPhone: form.shipToInformation.phoneNumber,
      ShipToProvince: form.shipToInformation.province,
      ShipToZip: form.shipToInformation.postalCode,
      Weight: this.userService.standardizeLocalizedNumber(this.user, form.shipToInformation.weight).toString(),
      Reference: form.shipToInformation.referenceNumber,

      UserId: form.userId,
      CustomerId: form.customerId,
    };

    this.reportShipmentService.saveReportShipmentEU(reportShipment)
      .subscribe(
        result => this.handleSaveReportShipmentEUSuccess(result),
        err => this.handleSaveReportShipmentEUFailure(err),
      );
  }

  private handleSaveReportShipmentEUSuccess(result) {
    this.notificationService.notify(
      'Shipment Reported Successfully',
      'Success',
      NotificationType.SUCCESS);
    this.dialogRef.close(true);
  }

  private handleSaveReportShipmentEUFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed saving report shipment',
      NotificationType.ERROR);
  }

  public isFormValid(): boolean {
    return this.formGroup.valid && this.isTrackingNumberValid;
  }

  public openTermsAndConditionsDialog(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {},
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    let dialogRef: MatDialogRef<UserTermsConditionsComponent>;
    dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  private setMaxInsuredValue(value: number) {
    // [MV3-2084] Limit maximum value for insured value in the message to 2 decimal places.
    this.maxInsuredValue = +value.toFixed(2);
    const control = (<UntypedFormGroup>this.formGroup.controls.trackingInformation).controls.insuredValue;

    if (this.maxInsuredValue <= 0) {
      this.validationService.setFormControlValidators(
        control,
        Validators.compose([Validators.required, Validators.min(1)]));

      return;
    }

    this.validationService.setFormControlValidators(
      control,
      Validators.compose([Validators.required, Validators.min(1), Validators.max(this.maxInsuredValue)]));
  }
}
