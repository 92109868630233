import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteConfig } from '../../../config/site-config';
import { AppState } from '../../app.state';
import { SharedService } from '../../core/services/shared.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { NotificationType } from '../../shared/models/notification-type';
import { UserCurrencyPipe } from '../../shared/pipes/user-currency/user-currency.pipe';
import { BirService } from '../../shared/services/bir/bir.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { User } from '../../shared/services/user/models/user.model';
import { IShipmentException } from './models/shipment-exception.interface';

@Component({
    selector: 'upsc-bir',
    templateUrl: './bir.component.html',
    styleUrls: ['./bir.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BirComponent implements OnInit {

    public user: User;
    public shipmentExceptions: IShipmentException[];
    public shipmentDates: any[] = [];
    public formatUS = 'MM/DD/YYYY - dddd';
    public formatOther = 'DD/MM/YYYY - dddd';
    public voidShipmentId: string;
    public customMessage: string;
    public voidedMessage = 'Your Shipment has been successfully voided. Any Return label generated for this shipment will be voided. Please note this has also CANCELLED insurance coverage.';
    public userCurrency: string;

    public dtOptions: any = {};
    public dtTrigger: Subject<any> = new Subject<any>();

    // @ViewChild('CustomInformation') customInfo: ElementRef;

    constructor(private birService: BirService,
                @Inject(LOCALE_ID) public localeId: string,
                private notificationService: NotificationService,
                private userCurrencyPipe: UserCurrencyPipe,
                private sharedService: SharedService,
                private dialogService: DialogService,
                private errorHandlerService: ErrorHandlerService,
                private storageService: StorageService,
                private router: Router,
                private readonly appState: AppState,
    ) {
        this.getShipmentExceptions();
    }

    updateShipmentDate(shipmentId, shipmentDate) {
        const shipDate = shipmentDate;
        if (this.user.CountryCode.toLowerCase() === 'us') {
            shipmentDate = moment(shipmentDate.split('-')[0], 'MM/DD/YYYY').format('MM/DD/YYYY');
        } else {
            shipmentDate = moment(shipmentDate.split('-')[0], 'MM/DD/YYYY').format('MM/DD/YYYY');
        }
        if (moment(shipmentDate, 'MM/DD/YYYY').isValid()) {
            this.birService.updateShipmentDate(shipmentId, shipmentDate).subscribe(response => {
                if (response) {
                    const shipment = this.shipmentExceptions.filter(x => x.ShipmentId === shipmentId);
                    if (shipment && shipment.length > 0) {
                        shipment[0].ReportType = 'SAVED';
                        shipment[0].ShipDate = shipDate;
                    }
                    this.notificationService.notify(
                        '',
                        'Shipment Date Updated Successfully',
                        NotificationType.SUCCESS);
                }
                // redirect if all BIR is done
                this.checkBIRExits();
            }, error => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(error),
                    'Updating Shipment Date Failed',
                    NotificationType.ERROR);
            });
        }
    }

    confirmVoid(shipmentId, shipmentDate) {
        this.dialogService.confirm('Void BIR Shipment', 'Are you sure you want to void this shipment?').subscribe(isConfirmed => {
            if (isConfirmed) {
                this.voidShipment(shipmentId, shipmentDate);
            }
        });
    }

    voidShipment(shipmentId, shipmentDate) {
        this.birService.voidShipment(shipmentId).subscribe(() => {

            const shipment = this.shipmentExceptions.filter(x => x.ShipmentId === shipmentId);
            if (shipment && shipment.length > 0) {
                shipment[0].ReportType = 'VOID';
                shipment[0].ShipDate = shipmentDate;
            }

            this.notificationService.notify(this.voidedMessage, '', NotificationType.SUCCESS);
            // redirect if all BIR is done
            this.checkBIRExits();
        }, error => {
            this.notificationService.notify(
                this.errorHandlerService.getHttpErrorMessage(error),
                'Voiding the Shipment Failed',
                NotificationType.ERROR);
        });
    }

    updateShipDate(shipmentId, shipdate) {
        const shipment = this.shipmentExceptions.filter(x => x.ShipmentId === shipmentId);
        if (shipment && shipment.length > 0) {
            shipment[0].ShipDate = shipdate;
        }
    }

    getShipDates() {
        // declaration
        let format = '';

        // format based on locale
        if (this.user.CountryCode.toLowerCase() === 'us') {
            format = this.formatUS;
        } else {
            format = this.formatOther;
        }


        for (let i = 0; i < this.shipmentExceptions.length; i++) { // loops through all exceptions
            this.shipmentExceptions[i].ShipmentDates = [];
            let dates = 1;

            if (moment().day() !== 0) //exclude sunday
            {
                this.shipmentExceptions[i].ShipmentDates.push(moment().format(format.split('-')[0]) + '- Today');
            }

            while (this.shipmentExceptions[i].ShipmentDates.length !== 8) {
                if (moment().add(dates, 'd').day() === 0) {
                    dates++;
                } // exclude SundayF
                this.shipmentExceptions[i].ShipmentDates.push(moment().add(dates, 'd').format(format));
                dates++;
            }


            /*[MV3-1799] Ship Date drop down should display Today + 7 consecutive days.
             // Below code create dates based on Return Label
            var noofDates = this.shipmentExceptions[i].IsReturnLabel == true ? 21 : 14;

            for (let j = 1; j < noofDates; j++) {     // looping to fill the dates dropdown

              // should not include sunday and past date
              if (moment(this.shipmentExceptions[i].CreateDate).add(j, 'd').day() != 0 // restrict sunday
                  && moment(this.shipmentExceptions[i].CreateDate).add(j, 'd').format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY') // restrict past dates
              )
                if (moment().format(format) == moment(this.shipmentExceptions[i].CreateDate).add(j, 'd').format(format)) // For Today
                  this.shipmentExceptions[i].ShipmentDates.push(moment(this.shipmentExceptions[i].CreateDate).add(j, 'd').format(format.split('-')[0]) + '- Today');
                else
                  this.shipmentExceptions[i].ShipmentDates.push(moment(this.shipmentExceptions[i].CreateDate).add(j, 'd').format(format));
            }

            // in case if the user void / saved without changing the date, then it means it is the first date in dropdown.
            // hence taking the dropdown first value and storing in ShipDate property
            if (this.shipmentExceptions[i].ShipmentDates.length > 0)
              this.shipmentExceptions[i].ShipDate = this.shipmentExceptions[i].ShipmentDates[0]; */

        }

    }

    checkBIRExits() {
        if ((this.shipmentExceptions.length > 0
                && this.shipmentExceptions.filter(x => x.ReportType === 'BIR').length > 0)
            || ((this.user.CountryCode === 'GB' || 'DE') && (this.shipmentExceptions.length > 0))) {
            return;
        } else {
            this.router.navigate(['dashboard']);
        }
    }

    ngOnInit() {
        this.dtOptions = SiteConfig.tableConfig;
        this.user = this.appState.user$();
        this.userCurrency = this.userCurrencyPipe.transform(this.user);
    }

    private getShipmentExceptions() {
        this.birService.getShipmentExceptions()
            .pipe(
                map(items => {
                    return items.map(item => {
                        item.AutoVoidDate = dayjs(item.CreateDate).add(15, 'days').toDate();
                        return item;
                    });
                }),
            )
            .subscribe(
                (exceptions) => {
                    if (exceptions) {
                        this.shipmentExceptions = exceptions;
                    }
                    this.checkBIRExits();
                    this.getShipDates();

                    this.dtTrigger.next(null);
                },
                (error) => {
                    this.dtTrigger.next(null);
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(error),
                        'Error Loading BIR Exceptions',
                        NotificationType.ERROR);
                });
    }
}
