import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shipmentStatusPercent'
})
export class ShipmentStatusPercentPipe implements PipeTransform {

 transform(value: any): any {
        switch (value) {
            case 0:
                return '';
            case 1:
                return '30%';
            case 2:
                return '30%';
            case 3:
                return '';
            case 4:
                return '10%';
            case 5:
                return '50%';
            case 6:
                return '100%';
            case 7:
                return '';
            case 8:
                return '10%';
            case 9:
                return '';
            case 10:
                return '';
            case 11:
                return '50%';
            case 12:
                return '100%';
            case 13:
                return '';
            case 14:
                return '';
            default: value;
        }

    }

}
