import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shipmentStatusBar'
})
export class ShipmentStatusBarPipe implements PipeTransform {

  transform(value:any):any{
       // console.log(value);
         switch (value) {
            case 0:
                return '';
            case 1:
                return 'progress-bar progress-bar-success';
            case 2:
                return 'progress-bar progress-bar-success';
            case 3:
                return '';
            case 4:
                return 'progress-bar progress-bar-success';
            case 5:
                return 'progress-bar progress-bar-success';
            case 6:
                return 'progress-bar progress-bar-success';
            case 7:
                return '';
            case 8:
                return 'progress-bar progress-bar-success';
            case 9:
                return '';
            case 10:
                return '';
            case 11:
                return 'progress-bar progress-bar-danger';
            case 12:
                return 'progress-bar progress-bar-success';
            case 13:
                return '';
            case 14:
                return '';
            default: value;
        }

    }

}
