import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'customeDatePipe'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any): any {

    return value.toString().split('.').join('/');
  }

}