<!-- Cancelled Quote Message modal -->
<div class="modal " id="cancelledQuoteInfo" tabindex="-1" role="dialog" aria-labelledby="Cancelled Quote Information" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body" >
                YOUR QUOTE HAS BEEN CANCELLED. 
            </div>
            <div class="modal-footer" style="text-align:center !important;">
                <a href="javascript:;" role="button" data-dismiss="modal" class="btn btn-sm btn-primary" >OK</a>
            </div>
        </div>
    </div>
</div>
<!-- /Cancelled Quote Message modal -->