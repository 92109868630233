<div class="bir white-content-page">
    <upsc-page-header [pageTitle]="'BIR.title'|translate"></upsc-page-header>

    <p class="bir-information" >{{ 'BIR.info' | translate }}</p>

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe" [hidden]="!shipmentExceptions">
            <thead>
            <tr>
                <th>
                    {{'created'|translate}}
                </th>
                <th>
                    {{'shipTo'|translate}}
                </th>
                <th>
                    {{'Tracking'|translate}} #
                </th>
                <th>
                    {{'Coverage'|translate}}
                </th>
                <th>
                    {{'Reference'|translate}} #
                </th>
                <th>
                    {{ 'BIR.autoVoidOn' | translate }}
                </th>
                <th>
                    {{'Status'|translate}}
                </th>
                <th >

                        <span> {{'ShipDate'|translate}}</span>
                        <a href="javascript:;" class="ship-date" matTooltip="{{ 'BIR.shipDatesInfo' | translate }}" matTooltipPosition="above" >
                                <mat-icon>info_outline</mat-icon> </a>
                </th>
                <th>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let exception of shipmentExceptions" #shipmentDate>
                <td>
                    <span *ngIf="localeId==='en-US' then USCreateDate else otherCreateDate"></span>
                    <ng-template #USCreateDate>{{ exception.CreateDate | date : 'MM/dd/yyyy' }}</ng-template>
                    <ng-template #otherCreateDate>{{ exception.CreateDate | date : 'yyyy/MM/dd' }}</ng-template>
                </td>
                <td>
                    {{exception.ShipToCompanyName}}
                </td>
                <td>
                    {{exception.TrackingNumber}}
                </td>
                <td>
                    {{ exception.InsuredValue | userLocaleNumber:user }} {{ userCurrency }}
                </td>
                <td>
                    {{exception.ReferenceNumber}}
                </td>
                <td>
                    <span *ngIf="localeId==='en-US' then USAutoVoidDate else otherAutoVoidDate"></span>
                    <ng-template #USAutoVoidDate>{{ exception.AutoVoidDate | date : 'MM/dd/yyyy' }}
                    </ng-template>
                    <ng-template #otherAutoVoidDate>{{ exception.AutoVoidDate | date : 'yyyy/MM/dd' }}
                    </ng-template>
                </td>
                <td>
                    <strong>
                        <span *ngIf="exception.ReportType==='BIR'" class="bir">{{ exception.ReportType }}</span>
                        <span *ngIf="exception.ReportType==='VOID'"
                              class="bir void">{{ exception.ReportType }}</span>
                        <span *ngIf="exception.ReportType==='SAVED'"
                              class="bir saved">{{ exception.ReportType }}</span>
                    </strong>
                </td>
                <td>
                    <span *ngIf="exception.ReportType==='BIR' then shipDateEditable else shipDateReadOnly "></span>
                    <ng-template #shipDateReadOnly>
                        <span *ngIf="localeId==='en-US' then USShipDate else otherShipDate"></span></ng-template>
                    <ng-template #USShipDate>{{ exception.ShipDate  }}</ng-template>
                    <ng-template #otherShipDate>{{ exception.ShipDate  }}</ng-template>
                    <ng-template #shipDateEditable>
                        <select id="shipDates"
                                name="shipDates"
                                #shipDate
                                class="form-control txtbox"
                                *ngIf="exception.ShipmentDates.length > 0"
                                (change)="updateShipDate(exception.ShipmentId, shipDate.value)">
                            <option *ngFor="let shipmentDate of exception.ShipmentDates"
                                    value="{{shipmentDate}}"> {{shipmentDate}}
                            </option>
                        </select>
                    </ng-template>
                </td>
                <td>
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                (click)="updateShipmentDate(exception.ShipmentId, shipmentDate.children[7].children['shipDates'].value)"
                                *ngIf="exception.ReportType==='BIR' && exception.ShipmentDates.length > 0">{{'Save'|translate}}
                        </button>
                        <button type="button"
                                mat-raised-button
                                class="alternative-button"
                                *ngIf="exception.ReportType==='BIR'"
                                (click)="confirmVoid(exception.ShipmentId, shipmentDate.children[7].children['shipDates'].value)">{{'Void'|translate}}
                        </button>
                    </div>
                </td>
            </tr>
            <!--<tr *ngIf="noRecordsFound">
                  <td colspan="7" class="text-center">
                    <span style="color:red;font-size:12px;"> {{'NoRecordsFound'|translate}} </span>
                  </td>
                </tr>-->
            </tbody>
        </table>
    </div>

    <div class="row-bottom-2 instruction">
        <div class="bir-footer">
            <p class="tracking-information" >{{ 'BIR.shipmentsTrackingInformation' | translate }}</p>

            <a routerLink="/dashboard" class="dashboard-link" mat-raised-button >{{'BIR.dashboardLink'|translate}}</a>
            <!-- <p>Attention: Shipments displayed do not show tracking information from the carrier.</p>
            <p>Shipments now created will show auto-validated 15 days after the original created date should they not be
                used before
                then.
            </p>
            <p>PLEASE NOTE, that insurance will be canceled to the below listed shipments should they be auto-voided.
                You must either
                void the shipments or change the ship date. Voided packages will CANCEL insurance coverage. Should you
                change the
                ship date you may do 3 more times before you will be required to void the shipment and create a new one
                in order
                for your shipment to have proper coverage.
            </p> -->
        </div>
    </div>
</div>
<!--<a href="#customInfo" #CustomInformation role="button" data-toggle="modal" data-dismiss="modal" class="btn hidden">Custom
    Message</a>
<upsc-custom-info [CustomMessage]="customMessage"></upsc-custom-info>-->
<!-- Void Shipment Confirm Modal -->
<!--<div class="modal "
     id="voidconfirmation"
     tabindex="-2"
     role="dialog"
     aria-labelledby="Void Shipment Confirmation"
     aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                Are you sure you want to void this shipment?
            </div>
            <div class="modal-footer" style="text-align:center !important;">
                <a href="javascript:;"
                   role="button"
                   data-dismiss="modal"
                   class="btn btn-sm btn-default text-uppercase ">Cancel</a>
                <a href="javascript:;"
                   role="button"
                   data-dismiss="modal"
                   (click)="voidShipment(voidShipmentId)"
                   class="btn btn-sm btn-primary white-font text-uppercase">Yes</a>
            </div>
        </div>
    </div>
</div>-->
<!-- /Void Shipment Confirm Modal -->
