<div class="reports white-content-page">
    <ng-container [ngTemplateOutlet]="header"></ng-container>

    <div class="ups-content-block content-actions">
        <div class="left-actions"></div>
        
        <div class="right-actions">
            <div class="action">
                <upsc-days-dropdown-menu (notify)="onDaysChange($event)"></upsc-days-dropdown-menu>
            </div>
            <form [formGroup]="searchFormGroup" (ngSubmit)="onSearchSubmit($event, searchFormGroup.value)" class="action">
                <mat-form-field>
                    <mat-label>{{'searchShipment'|translate}}</mat-label>
                    <input matInput type="text" formControlName="keyword">
                    <mat-icon matSuffix (click)="onSearchSubmit($event, searchFormGroup.value)" class="search">search</mat-icon>
                </mat-form-field>
            </form>
            <button type="button"
                            mat-raised-button
                            class="alternative-button action export"
                            (click)="download()">
                        {{'Export'|translate}}
                    </button>
        </div>
    </div>
    

    <upsc-responsive-table *ngIf="false"
                           [columns]="reportedShipmentColumns"
                           [items]="reportedShipmentItems"
                           [usePaginator]="true"
                           [totalItemCount]="totalItemCount"
                           [pageSize]="tablePageSize"
                           [pageIndex]="tablePageIndex"
                           (pageChanged)="onPageChanged($event)"
                           (rowActionClicked)="onReportedShipmentActionClicked($event)"></upsc-responsive-table>

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!reportedShipmentList">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th>
                    {{'Account' | translate}}
                </th>
                <th>
                    {{'Carrier' | translate}}
                </th>
                <th>
                    {{'ReportDate'|translate}}
                </th>
                <th>
                    {{'ShipDate'|translate}}
                </th>
                <th>
                    {{'Tracking'|translate}} #
                </th>
                <th>{{ 'Confirmation' | translate }}</th>
                <th>
                    {{'Coverage'|translate}}
                </th>
                <th>
                    {{'Status'|translate}}
                </th>
                <th class="text-center all"></th>
            </tr>
            </thead>
            <tbody #tb>
            <tr *ngFor="let item of reportedShipmentList | paginate:paginate">
                <td class="trigger-cell"></td>
                <td> {{item.CustomerId}}</td>
                <td> {{item.CarrierCode}}</td>
                <td>{{item.ReportDate | date : this.shortTableDateFormat }}</td>
                <td>{{item.ShipDate | date : this.tableDateFormat }}</td>
                <td>
                    <strong>
                        <a href="#" (click)="TrackShipmentDetails($event, item)">{{item.TrackingNumber}}</a>
                    </strong>
                </td>
                <td>{{ item.Confirmation }}</td>
                <td><strong> {{ item.InsuredValue | userLocaleNumber:user }} {{ userCurrency }} </strong>
                </td>
                <td>
                    <div class="actions">
                        <span class="status" *ngIf="item.Status==0 || item.Status == 1">
                            <button type="button"
                                    class="btn btn-transparentbullet"
                                    style="background-color:#3FA700 !important;"></button>
                            <span> {{'OK'|translate}} </span>
                        </span>
                        <span class="status" *ngIf="item.Status==3">
                            <button type="button" class="btn btn-redbullet"></button>
                            <span>  {{'Void'|translate}} </span>
                        </span>
                    </div>
                </td>
                <td>
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                color="primary"
                                *ngIf="CalculateDays(item.ShipDate)<24 && item.Status!=3"
                                (click)="deleteReportedShipmentConfirmation(item.ShipmentId)">
                            {{'Void'|translate}}
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <pagination-controls [directionLinks]="true"
                             [previousLabel]="''"
                             [nextLabel]="''"
                             [autoHide]="true"
                             (pageChange)="onPageChanged($event)"></pagination-controls>
        <upsc-element-blocker *ngIf="isDataLoading"></upsc-element-blocker>

    </div>
</div>

<ng-template #header>
    <div class="ups-page-header">
        <h1 class="page-title">{{'ReportShipmentHistory'|translate}}</h1>
        <div class="page-actions">
            <button mat-raised-button
                    type="button"
                    class="page-action"
                    (click)="openReportShipmentModal($event)">
                {{'ReportShipment'|translate}}
            </button>
        </div>
    </div>
</ng-template>
