<div class="shipment-history white-content-page">
    <upsc-page-header [pageTitle]="'ShipmentHistory'|translate"></upsc-page-header>

    <div class="ups-content-block content-actions">
        <div class="left-actions">
            <form [formGroup]="dateRangeFormGroup" class="action">
                <mat-form-field>
                    <mat-label>{{ 'View' | translate }}</mat-label>
                    <mat-select formControlName="dayCount">
                        <mat-option [value]="dateRangeAll">{{'All'|translate}}</mat-option>
                        <mat-option *ngFor="let dateRange of predefinedDayRanges" [value]="dateRange">
                            {{ 'lastX' | translate:{ x: dateRange } }}
                            {{ (dateRange > 1 ? 'days' : 'day') | translate }}
                        </mat-option>
                        <mat-option [value]="dateRangeCustom">{{'Custom'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <upsc-date-range-selector
                [customDateRangeFormGroup]="customDateRangeFormGroup"
                [formattedStartDate]="formattedStartDate"
                [formattedEndDate]="formattedEndDate">
            </upsc-date-range-selector>

            <mat-form-field *ngIf="false">
                <mat-label>{{ 'SelectFields'|translate }}</mat-label>
                <mat-select multiple
                            [(ngModel)]="selectedColumnNames"
                            (valueChange)="onColumnSelectionChanged($event)">
                    <mat-select-trigger>{{ selectedColumnNames?.length }} columns selected</mat-select-trigger>
                    <mat-option *ngFor="let key of columnNames" [value]="key">{{ key | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="right-actions">
            <form [formGroup]="searchFormGroup" (ngSubmit)="onSearchSubmit($event, searchFormGroup.value)">
                <mat-form-field>
                    <mat-label>{{'searchShipment'|translate}}</mat-label>
                    <input matInput type="text" formControlName="keyword">
                    <mat-icon matSuffix (click)="onSearchSubmit($event, searchFormGroup.value)" class="search">search</mat-icon>
                </mat-form-field>
            </form>
            <button type="button"
                    mat-raised-button
                    class="alternative-button action export"
                    (click)="download()">
                {{'Export'|translate}}
            </button>
        </div>
    </div>

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!displayedShipments">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th [hidden]="!shipmenthistoryuserfields.Carrier">
                    {{'Carrier'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.CreatedDate">
                    {{'ShipDate'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.ShipTo">
                    {{'ShipTo'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.Services">
                    {{'Services'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.Status">
                    {{'Status'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.Coverage">
                    {{'Coverage'|translate}}
                </th>
                <th [hidden]="!shipmenthistoryuserfields.TrackingNumber">{{'TrackingNumber'|translate}}</th>
                <th [hidden]="!shipmenthistoryuserfields.ReferenceNumber">{{'ReferenceNumber'|translate}}</th>
                <th class="all"></th>
            </tr>
            </thead>
            <tbody #tb>
            <tr *ngFor="let item of displayedShipments | paginate:paginate;">
                <td class="trigger-cell"></td>
                <td [hidden]="!shipmenthistoryuserfields.Carrier">{{ item?.CarrierCode | carrier }} </td>
                <td [hidden]="!shipmenthistoryuserfields.CreatedDate">{{item?.ShipDate | date : (userService.isUserEU(user) ? 'dd-MM-yyyy' : 'MM-dd-yyyy') }}</td>
                <td [hidden]="!shipmenthistoryuserfields.ShipTo">
<!--                    <popover-content #myPopover-->
<!--                                     title=""-->

<!--                                     placement="auto bottom"-->
<!--                                     [animation]="true"-->
<!--                                     [closeOnClickOutside]="true">-->
<!--                        <br>-->
<!--                        <span class="show"><b>{{ item?.ShipTo?.CompanyName }}</b> </span>-->
<!--                        <span class="show">{{item?.ShipTo?.StreetAddress}} </span>-->
<!--                        <span class="show">{{item?.ShipTo?.ApartmentSuite}} </span>-->
<!--                        <span class="show">{{item?.ShipTo?.City + ' ' + item?.ShipTo?.State + ' ' +-->
<!--                                    item?.ShipTo?.Zip + ' ' + item?.ShipTo?.Country }} </span>-->
<!--                        <br>-->
<!--                        <span class="show text-upper"><b>{{'Reference'|translate}}:</b> </span>-->
<!--                        <span class="show">{{item?.ReferenceNumber}} </span>-->
<!--                        <br>-->
<!--                        <span class="show text-upper"><b>{{'CustomerReference'|translate}}:</b> </span>-->
<!--                        <span class="show">{{item?.CustomerReferenceNumber}} </span>-->
<!--                        <br>-->
<!--                    </popover-content>-->
                    <!-- below code snippet for popup window -->

                    <!-- <a href="javascript:;" [popover]="myPopover" *ngIf="item?.ShipTo?.CompanyName!=''">{{
                        item?.ShipTo?.CompanyName }} </a>
                    <a href="javascript:;" [popover]="myPopover" *ngIf="item?.ShipTo?.CompanyName==''">{{
                        item?.ShipTo?.FirstName + ' ' + item?.ShipTo?.LastName }} </a> -->
                    <a class="popup-container" href="javascript:;" *ngIf="item?.ShipTo?.CompanyName!=''"> 
                        {{ item?.ShipTo?.CompanyName }}
                        <div class="popup-box">
                            {{ item?.ShipTo?.FirstName + ' ' + item?.ShipTo?.LastName }} <br>
                            {{ item?.ShipTo?.StreetAddress }} <br> 
                            {{ item?.ShipTo?.City + ', ' + item?.ShipTo?.State + ' ' + item?.ShipTo?.Zip + ' ' + item?.ShipTo?.Country}}
                            <!-- <div class="triangleDown"> </div>     -->
                        </div>
                    </a>
                    <a href="javascript:;" *ngIf="item?.ShipTo?.CompanyName==''">{{ item?.ShipTo?.FirstName + ' ' + item?.ShipTo?.LastName }} </a>
                </td>
                <td [hidden]="!shipmenthistoryuserfields.Services">{{item?.ServiceCode | services}}</td>
                <td [hidden]="!shipmenthistoryuserfields.Status">
                    @if (item?.Status >= 0 && item?.Status <= 14) {
                        <span>{{ ('shipmentStatus.s' + item?.Status) | translate }}</span>
                    }
                </td>
                <td [hidden]="!shipmenthistoryuserfields.Coverage">
                    <strong>{{ item?.InsuredValue | userLocaleNumber:user }} {{ userCurrencyCode }}</strong>
                </td>
                <td [hidden]="!shipmenthistoryuserfields.TrackingNumber">
                    <span [class.is-commercial-invoice]="item.IsCommercialInvoice">
                        <ng-container *ngIf="item?.CarrierCode === 1 || (item?.CarrierCode | carrier) === 'UPS'; else showTrackingNumberFedex">
                            <a href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum={{item?.TrackingNumber}}/trackdetails" target="_blank">{{ item?.TrackingNumber || 'N/A' }}</a>
                        </ng-container>

                        <ng-template #showTrackingNumberFedex>
                            <ng-container *ngIf="item?.CarrierCode === 2 || (item?.CarrierCode | carrier) === 'FEDEX'; else showTrackingNumberAll">
                                <a href="https://www.fedex.com/fedextrack/?trknbr={{item?.TrackingNumber}}" target="_blank">{{ item?.TrackingNumber || 'N/A' }}</a>
                            </ng-container>
                        </ng-template>

                        <ng-template #showTrackingNumberAll>
                            <span>{{ item?.TrackingNumber || 'N/A' }}</span>
                        </ng-template>
                    </span>
                </td>
                <td [hidden]="!shipmenthistoryuserfields.ReferenceNumber">
                    <span>{{ item?.ReferenceNumber }}</span>
                </td>
                <td>
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                (click)="openShipmentDetail(item?.ShipmentId)">{{'View'|translate}}
                        </button>
                        <button type="button"
                                mat-raised-button
                                (click)="confirmVoid(item?.ShipmentId)"
                                class="alternative-button"
                                *ngIf="item?.Status==4 || item?.Status==10 || item?.Status==0 || item?.Status==1">
                            {{'Void'|translate}}
                        </button>
                        <!--<a href="#shipmentdetail" role="button" class="btn btn-default btn-grey btn-xs text-uppercase" (click)="GetShipment(item?.ShipmentId)"
                            data-toggle="modal">{{'View'|translate}}</a>-->
                        <a href="javascript:;"
                           role="button"
                           mat-raised-button
                           class="secondary-button hide-me"
                           (click)="printWindow(item?.ShipmentId)"
                           *ngIf="item?.Status==10 || item?.Status==1 || item?.Status==0">{{'Print'|translate}}</a>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <ngx-responsive-table *ngIf="displayedShipments && false"
                              [tableConfig]="tableConfig"
                              [tableData]="displayedShipments">
            <table>
                <thead>
                <tr>
                    <th [hidden]="!shipmenthistoryuserfields.TrackingNumber">{{ 'TrackingNumber' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.Carrier">{{ 'Carrier' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.CreatedDate">{{ 'ShipDate' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.ShipTo">{{ 'ShipTo' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.Services">{{ 'Services' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.Status">{{ 'Status' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.Coverage">{{ 'Coverage' | translate }}</th>
                    <th [hidden]="!shipmenthistoryuserfields.ReferenceNumber">{{ 'ReferenceNumber' | translate }}</th>
                    <th class="all"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of displayedShipments | paginate:paginate">
                    <td [hidden]="!shipmenthistoryuserfields.TrackingNumber" collapsed-order="1">
                    <span [class.is-commercial-invoice]="item.IsCommercialInvoice">
                        {{ item?.TrackingNumber || 'N/A' }}
                    </span>
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.Carrier" collapsed-order="5">
                        {{ item?.CarrierCode | carrier }}
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.CreatedDate" collapsed-order="4">
                        {{ item?.ShipDate | date : 'MM-dd-yyyy' }}
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.ShipTo">
<!--                        <popover-content #myPopover-->
<!--                                         title=""-->
<!--                                         placement="auto bottom"-->
<!--                                         [animation]="true"-->
<!--                                         [closeOnClickOutside]="true">-->
<!--                            <br>-->
<!--                            <span class="show"><b>{{ item?.ShipTo?.CompanyName }}</b> </span>-->
<!--                            <span class="show">{{item?.ShipTo?.StreetAddress}} </span>-->
<!--                            <span class="show">{{item?.ShipTo?.ApartmentSuite}} </span>-->
<!--                            <span class="show">{{item?.ShipTo?.City + ' ' + item?.ShipTo?.State + ' ' +-->
<!--                                    item?.ShipTo?.Zip + ' ' + item?.ShipTo?.Country }} </span>-->
<!--                            <br>-->
<!--                            <span class="show text-upper"><b>{{'Reference'|translate}}:</b> </span>-->
<!--                            <span class="show">{{item?.ReferenceNumber}} </span>-->
<!--                            <br>-->
<!--                            <span class="show text-upper"><b>{{'CustomerReference'|translate}}:</b> </span>-->
<!--                            <span class="show">{{item?.CustomerReferenceNumber}} </span>-->
<!--                            <br>-->
<!--                        </popover-content>-->
                        <!-- below code snippet for popover window -->

                        <!-- <a href="javascript:;" [popover]="myPopover" *ngIf="item?.ShipTo?.CompanyName!=''">{{
                            item?.ShipTo?.CompanyName }} </a>
                        <a href="javascript:;" [popover]="myPopover" *ngIf="item?.ShipTo?.CompanyName==''">{{
                            item?.ShipTo?.FirstName + ' ' + item?.ShipTo?.LastName }} </a> -->
                            <a href="javascript:;" *ngIf="item?.ShipTo?.CompanyName!=''">{{
                                item?.ShipTo?.CompanyName }} </a>
                            <a href="javascript:;"  *ngIf="item?.ShipTo?.CompanyName==''">{{
                                item?.ShipTo?.FirstName + ' ' + item?.ShipTo?.LastName }} </a>
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.Services">
                        {{ item?.ServiceCode | services }}
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.Status" collapsed-order="2">
                        {{ item?.Status | shipmentStatus }}
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.Coverage" collapsed-order="3">
                        <strong>{{ item?.InsuredValue | userLocaleNumber:user }} {{ userCurrencyCode }}</strong>
                    </td>
                    <td [hidden]="!shipmenthistoryuserfields.ReferenceNumber">
                        <span>{{ item?.ReferenceNumber || 'N/A' }}</span>
                    </td>
                    <td>
                        <div class="actions">
                            <button type="button"
                                    mat-raised-button
                                    (click)="openShipmentDetail(item?.ShipmentId)">{{'View'|translate}}
                            </button>
                            <button type="button"
                                    mat-raised-button
                                    (click)="confirmVoid(item?.ShipmentId)"
                                    class="alternative-button"
                                    *ngIf="item?.Status==4 || item?.Status==10 || item?.Status==0 || item?.Status==1">
                                {{'Void'|translate}}
                            </button>
                            <!--<a href="#shipmentdetail" role="button" class="btn btn-default btn-grey btn-xs text-uppercase" (click)="GetShipment(item?.ShipmentId)"
                                data-toggle="modal">{{'View'|translate}}</a>-->
                            <a href="javascript:;"
                               role="button"
                               mat-raised-button
                               class="secondary-button hide-me"
                               (click)="printWindow(item?.ShipmentId)"
                               *ngIf="item?.Status==10 || item?.Status==1 || item?.Status==0">{{'Print'|translate}}</a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </ngx-responsive-table>

        <pagination-controls [directionLinks]="true"
                             [previousLabel]="''"
                             [nextLabel]="''"
                             [autoHide]="true"
                             (pageChange)="onPageChanged($event)"></pagination-controls>

        <upsc-element-blocker *ngIf="isSearching"></upsc-element-blocker>
    </div>
</div>
