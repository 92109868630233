<ul class="items">
    <li class="item">
        <img ngSrc="/assets/images/fact_check.svg"
             class="icon"
             alt="{{ 'bulkUpload.tipsAndTricks.items.requiredColumns.title' | translate:{ n: '' } }}"
             height="47"
             width="47">
        <div class="title">{{ 'bulkUpload.tipsAndTricks.items.requiredColumns.title' | translate:{ n: ('bulkUpload.tipsAndTricks.items.requiredColumns.columns' | translate)?.length || 0 } }}</div>
        <ol class="columns description">
            @for (column of ('bulkUpload.tipsAndTricks.items.requiredColumns.columns' | translate); track column) {
                <li class="column">{{ column }}</li>
            }
        </ol>
    </li>
    <li class="item">
        <img ngSrc="/assets/images/insured_value.svg"
             class="icon"
             alt="{{ 'bulkUpload.tipsAndTricks.items.insuredValue.title' | translate }}"
             height="47"
             width="47">
        <div class="title">{{ 'bulkUpload.tipsAndTricks.items.insuredValue.title' | translate }}</div>
        <p class="description">{{ 'bulkUpload.tipsAndTricks.items.insuredValue.description' | translate }}</p>
    </li>
    <li class="item">
        <img ngSrc="/assets/images/edit_note.svg"
             class="icon"
             alt="{{ 'bulkUpload.tipsAndTricks.items.noBlanks.title' | translate }}"
             height="47"
             width="47">
        <div class="title">{{ 'bulkUpload.tipsAndTricks.items.noBlanks.title' | translate }}</div>
        <p class="description">{{ 'bulkUpload.tipsAndTricks.items.noBlanks.description' | translate }}</p>
    </li>
    <li class="item">
        <img ngSrc="/assets/images/alarm.svg"
             class="icon"
             alt="{{ 'bulkUpload.tipsAndTricks.items.submissionDeadline.title' | translate }}"
             height="47"
             width="47">
        <div class="title">{{ 'bulkUpload.tipsAndTricks.items.submissionDeadline.title' | translate }}</div>
        <p class="description">{{ 'bulkUpload.tipsAndTricks.items.submissionDeadline.description' | translate }}</p>
    </li>
</ul>
