import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import * as XLSX from 'xlsx';
import { ReportShipmentsRecentUploadsComponent } from '../report-shipments-recent-uploads/report-shipments-recent-uploads.component';
import { ReportShipmentsReviewErrorsComponent } from '../report-shipments-review-errors/report-shipments-review-errors.component';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';
import { IReportShipmentRecord } from './report-shipments.model';
import { Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { IReportShipmentEU } from 'app/history/report-history/report-shipment-eu-dialog/models/report-shipment-eu.interface';
import { User } from 'app/shared/services/user/models/user.model';
import { AppState } from 'app/app.state';
import { IReportedShipmentFileUpload } from 'app/shared/models/shipments/reported-shipment-file-upload.interface';
import { Subscription } from 'rxjs';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ReportShipmentsPopupsComponent } from 'app/report-shipments-popups/report-shipments-popups.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IReportService } from 'app/history/models/report-service.interface';
import { ShipmentService } from 'app/ship/services/shipment.service';
import { ICountry } from 'app/shared/models/country.interface';
import { Carriers } from 'app/shared/enum/general-enum';
import { TipsTricksComponent } from './tips-tricks/tips-tricks.component';

@Component({
  selector: 'upsc-report-shipments-bulkupload',
  templateUrl: './report-shipments-bulkupload.component.html',
  styleUrls: ['./report-shipments-bulkupload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    S3FileDownloaderModule,
    MatFormFieldModule,
    MatError,
    NgClass,
    ReportShipmentsRecentUploadsComponent,
    ReportShipmentsReviewErrorsComponent,
    MatExpansionPanel,
    MatIcon,
    MatDivider,
    MatButtonModule,
    MatDialogModule,
    ElementBlockerModule,
    TipsTricksComponent,
  ],
})
export class ReportShipmentsBulkuploadComponent implements OnInit, OnDestroy {

  public isSubmissionComplete: boolean = false;
  public isFileInputDisabled: boolean = false;
  public selectedFileName: string | null = null;
  public columnHeaders: string[];
  public submitBtnMessage: string;
  public duplicateAddressMessage: string;
  public duplicateSubmissionCompleteMessage: string = '';
  public errorMessage: string = '';
  public isCancelButtonClicked: boolean = false;
  public isRecentUpload: boolean = false;
  public dataFromFile: IReportShipmentRecord[];
  public showReview: boolean = false;
  public showInstructions: boolean = true;
  public maxInsuredValue = 25000;
  public fileUploads: IReportedShipmentFileUpload[] = [];
  public editingExistingFile: boolean = false;
  public isDataLoadingFromFile: boolean = false;
  public countries: ICountry[];
  private existingTrackingNumbers: string[] = [];
  private uploadedFile: File;
  private cancelRecentUploadSubscription: Subscription;
  private dataSubmittedSubscription: Subscription;
  private fileDownloadedSubscription: Subscription;
  private downloadAndSubmitFileSubscription: Subscription;
  private saveProgressSubscription: Subscription;
  private deleteFileSubscription: Subscription;
  public currentFileId: number = -1;
  public isPanelVisible: boolean = false;
  private serviceTypes: IReportService[];
  private getReportServiceSubscription: Subscription;
  private backToUploadSubscription: Subscription;
  private trackingNumbers: string[] = [];

  public requiredHeaders: string[] = ['carrier', 'fromcountry', 'insuredvalue', 'servicelevel', 'shipdate', 'tocountry', 'trackingnumber'];

  private user: User;
  private propertyValidators: { [key in keyof IReportShipmentEU]?: any[] };

  @ViewChild('fileInput') public fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('tipsAndTricksPanel') public tipsAndTricksPanel: MatExpansionPanel;

  public buttonClass = 'transparent-button';

  public constructor(
    private spinnerService: SpinnerService,
    private readonly dialog: MatDialog,
    private readonly shipmentService: ShipmentService,
    private reportShipmentService: ReportShipmentService,
    private readonly appState: AppState,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
  ) {
    this.user = this.appState.user$();

    this.propertyValidators = {
      ShipFromCountry: [Validators.required],
      ShipmentType: [Validators.required],
      Carrier: [Validators.required],
      ShipToCountry: [Validators.required],
      Service: [Validators.required],
      TrackingNumber: [Validators.required],
      Coverage: [Validators.required, Validators.min(1), Validators.max(this.maxInsuredValue)],
      ShipDate: [this.validateShipDate.bind(this)],
      ShipToCompanyName: [Validators.maxLength(35)],
      ShipFromCompanyName: [Validators.maxLength(35)],
      ShipToLastName: [Validators.maxLength(20)],
      ShipFromContactName: [Validators.maxLength(20)],
      ShipToAddress1: [Validators.maxLength(80)],
      ShipToAddress2: [Validators.maxLength(50)],
      ShipToAddress3: [Validators.maxLength(80)],
      ShipFromAddress1: [Validators.maxLength(80)],
      ShipFromAddress2: [Validators.maxLength(80)],
      ShipFromAddress3: [Validators.maxLength(80)],
    };
  }

  public ngOnInit(): void {
    this.subscribeToCancelRecentUpload();
    this.subscribeToDataSubmitted();
    this.subscibeToFileDownloaded();
    this.subscribeToDownloadAndSubmit();
    this.subscribeToSaveProgress();
    this.subscribeToDeleteFile();
    this.getFileUploads();
    this.loadServiceTypes();
    this.loadCountries();
    this.subscribeToBackToUpload();
  }

  public ngOnDestroy(): void {
    if (this.cancelRecentUploadSubscription) {
      this.cancelRecentUploadSubscription.unsubscribe();
    }

    if (this.dataSubmittedSubscription) {
      this.dataSubmittedSubscription.unsubscribe();
    }

    if (this.fileDownloadedSubscription) {
      this.fileDownloadedSubscription.unsubscribe();
    }

    if (this.downloadAndSubmitFileSubscription) {
      this.downloadAndSubmitFileSubscription.unsubscribe();
    }

    if (this.saveProgressSubscription) {
      this.saveProgressSubscription.unsubscribe();
    }

    if (this.deleteFileSubscription) {
      this.deleteFileSubscription.unsubscribe();
    }

    if (this.getReportServiceSubscription) {
      this.getReportServiceSubscription.unsubscribe();
    }

    if (this.backToUploadSubscription) {
      this.backToUploadSubscription.unsubscribe();
    }
  }

  public handleClick(event: MouseEvent): void {
    // Trigger a click event on the hidden file input
    this.fileInput.nativeElement.click();

    (event.target as HTMLInputElement).value = '';
    const files: FileList = (event?.target as HTMLInputElement).files;
    this.currentFileId = -1;
    this.handleFiles(files);
  }

  public handleDrop(event: DragEvent): void {
    event.preventDefault();
    const files: FileList = event.dataTransfer?.files;
    if (files && files.length > 0) {
      if (this.handleFileSizeError(files)) {
        this.currentFileId = -1;
        this.handleFiles(files);
      }
    }
  }

  public allowDrop(event: DragEvent): void {
    event.preventDefault();
  }

  public handleFileSizeError(files: FileList): boolean {
    const maxFileSize = 4 * 1024 * 1024; // 4 MB
    if (files && files.length > 0) {
      if (files[0].size > maxFileSize) {
        this.dialog.open(ReportShipmentsPopupsComponent, {
          width: '592px',
          minHeight: '215px',
          maxHeight: '344px',
          data: {
            title: 'Exceed File Size',
            message: 'Your file exceed the maximum file size 4MB. Please correct the file and upload again.',
            yesMessage: 'Close',
          },
        });
        return false;
      }
    }

    return true;
  }

  public onFileInputChange(event: Event): void {
    // Handle file input change (e.g., when a user selects a file using the file explorer)
    const files: FileList = (event?.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      if (this.handleFileSizeError(files)) {
        this.currentFileId = -1;
        this.handleFiles(files);
      }
    }
  }

  public replaceFile(): void {
    this.isFileInputDisabled = false;
    this.isCancelButtonClicked = true;
    this.closeFile(this.isCancelButtonClicked);
  }

  public closeFile(isCancelButtonClicked?: boolean): void {
    this.duplicateSubmissionCompleteMessage = '';
    this.selectedFileName = '';
    this.showFile(false, isCancelButtonClicked);
  }

  public showSubmissionComplete(event?: Event): void {
    if (this.submitBtnMessage === 'Upload Again') {
      this.onFileInputChange(event);
    }
    else if (this.submitBtnMessage === 'Review') {
      this.spinnerService.show();
      this.isFileInputDisabled = true;
      this.showReviewSummary();
      this.spinnerService.hide();
    }
  }

  public loadFileForReview(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        // Create a FileList from the File object
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileList = dataTransfer.files;
        // Pass the FileList to handleFiles method
        this.currentFileId = fileId;
        this.uploadedFile = file;
        this.handleFiles(fileList);
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.error,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public downloadFile(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        // Trigger download using the File object
        this.triggerFileDownload(file);
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.error,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public deleteFile(): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: 'Delete File',
        message: 'Are you sure you want to delete this file? You can upload a new file after deleting this one.',
        noMessage: 'No, Don\'t Delete',
        yesMessage: 'Yes',
      },
    });
  }

  public onDragEnter(event: DragEvent): void {
    event.preventDefault();
    const uploadBox = event.target as HTMLElement;
    uploadBox.classList.add('upload-box-hover');
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    const uploadBox = event.target as HTMLElement;
    uploadBox.classList.remove('upload-box-hover');
  }

  private submitData(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: async (file) => {
        const fileData = await this.readExcelFile(file, false);
        const dataToSend = fileData.map(record => record.ReportShipment);

        const trackingNumbers = new Set<string>();
        let hasErrors = false;
        for (const item of dataToSend) {
          if (!trackingNumbers.has(item.TrackingNumber)) {
            trackingNumbers.add(item.TrackingNumber);
          } else {
            hasErrors = true;
            break;
          }
        }

        if (hasErrors) {
          this.notificationService.notify(
            'Found duplicated Tracking Number(s)',
            'Error submiting file',
            NotificationType.ERROR
          );
          this.reportShipmentService.submitFileResult$.next({ fileId, success: false });
          this.spinnerService.hide();
        } else {
          this.reportShipmentService.saveBulkReportShipmentEU(dataToSend)
            .subscribe({
              next: () => {
                this.notificationService.notify(
                  null,
                  'File submitted successfully',
                  NotificationType.SUCCESS
                );
                this.reportShipmentService.dataSubmitted$.next({ data: dataToSend, isFinal: true, hasErrors: false });
                this.reportShipmentService.submitFileResult$.next({ fileId, success: true });
                this.spinnerService.hide();
              },
              error: err => {
                let errorMessage = err.error.Message;
                const prefix = 'The following tracking numbers already exist: ';

                if (err.error.Code === 7 && !errorMessage.startsWith(prefix)) {
                  errorMessage = 'Following duplicate tracking numbers found: ' + errorMessage;
                }

                this.notificationService.notify(
                  errorMessage,
                  'Error submiting file',
                  NotificationType.ERROR
                );
                this.reportShipmentService.submitFileResult$.next({ fileId, success: false });
                this.spinnerService.hide();
              },
            });
        }
      },
      error: (error) => {
        this.notificationService.notify(
          error.message,
          'Error downloading file',
          NotificationType.ERROR
        );
        this.reportShipmentService.submitFileResult$.next({ fileId, success: false });
        this.spinnerService.hide();
      },
    });

  }

  private triggerFileDownload(file: File): void {
    const url = window.URL.createObjectURL(file);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release the object URL
    window.URL.revokeObjectURL(url);
  }

  private handleFiles(files: FileList): void {
    this.spinnerService.show();
    // Only accepting .csv, .xls, .xlsx extensions
    const allowedExtensions: string[] = ['.csv', '.xls', 'xlsx', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    let file: File;
    if (files) {
      file = files[0];
      this.uploadedFile = file;
    }

    if (file && allowedExtensions.includes(file.type)) {
      this.processFile(file);
      this.spinnerService.hide();
    } else if (file !== undefined) {
      this.dialog.open(ReportShipmentsPopupsComponent, {
        width: '592px',
        minHeight: '215px',
        maxHeight: '344px',
        data: {
          title: 'Incorrect File Extension',
          message: 'Please upload a file with valid extension(csv, xls, xlsx)',
          yesMessage: 'Close',
        },
      });
      // Hide spinner if file type is not allowed
      this.spinnerService.hide();
    }
    else {
      this.spinnerService.hide();
    }
  }

  private async readExcelFile(file: File, shouldValidate: boolean): Promise<IReportShipmentRecord[]> {
    return new Promise<IReportShipmentRecord[]>((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const arrayBuffer = e.target.result as ArrayBuffer;
          const workbook: XLSX.WorkBook = XLSX.read(arrayBuffer, { type: 'binary' });
          const sheetName: string = workbook.SheetNames[0];
          const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
          const parsedData: string[][] = this.sheetToArray(worksheet);

          const dataRows = parsedData.slice(1); // Exclude the header row
          const hasData = dataRows.some(row =>
            row && row.some(cell => cell && cell.toString().trim() !== '')
          );

          if (!hasData) {
            // FI-756: Display new error message for invalid file
            this.handleExcelDataError();
            reject(new Error('Complete all required fields, include all necessary columns, and ensure column names match the Bulk Upload template format.'));
            return;
          }

          const result = await this.validateAndParseData(parsedData, shouldValidate);
          resolve(result);
        } catch (error) {
          this.handleMissingRequiredColumns(error?.message);
          reject(error);
        }
      };
      reader.onerror = (e) => {
        this.notificationService.notify(
          null,
          'Error reading file',
          NotificationType.ERROR
        );
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private async validateAndParseData(parsedData: any[], shouldValidate: boolean): Promise<IReportShipmentRecord[]> {
    this.resetDuplicateAndErrorMessages();
    this.columnHeaders = parsedData[0];

    if (shouldValidate) {
      if (!this.isColumnHeadersMatched(parsedData)) {
        // FI-756: Display new error message for invalid file
        this.handleExcelDataError();
        this.submitBtnMessage = 'Upload Again';
        this.isFileInputDisabled = false;
        return;
      }

      // FI-756: Show different error messages in missing required columns pop up
      const missingRequiredColumnErrorMessage = this.checkMissingRequiredFields(parsedData);
      if (missingRequiredColumnErrorMessage) {
        throw new Error(missingRequiredColumnErrorMessage);
      }

      this.trackingNumbers = this.collectTrackingNumbers(parsedData);
      const findDuplicates = this.trackingNumbers.filter((item, index) => this.trackingNumbers.indexOf(item) !== index);

      await this.validateTrackingNumbers(this.trackingNumbers);
      this.existingTrackingNumbers = this.existingTrackingNumbers.concat(findDuplicates);
    }

    const normalizedHeaders = this.columnHeaders.map(header => header.toLowerCase().replace(/\s/g, '').trim());
    const headerIndexMap = this.createHeaderIndexMap(normalizedHeaders);

    const dataRows = parsedData.slice(1).filter(row => row.length > 0); // Exclude headers
    const parsedRows = dataRows.map((row, index) => {
      const parsedRow = this.parseRow(row, headerIndexMap);
      parsedRow.Index = index;
      return parsedRow;
    });
    return parsedRows;
  }

  private createHeaderIndexMap(headers: string[]): { [key: string]: number } {
    const headerIndexMap: { [key: string]: number } = {};
    headers.forEach((header, index) => {
      headerIndexMap[header] = index;
    });
    return headerIndexMap;
  }

  private parseRow(row: any[], headerIndexMap: { [key: string]: number }): IReportShipmentRecord {
    const parsedRow: Partial<IReportShipmentEU> = {};
    const errors: string[] = [];

    Object.keys(headerIndexMap).forEach(normalizedHeader => {
      const propertyName = this.headerToPropertyMap[normalizedHeader];
      if (propertyName !== undefined) {
        const index = headerIndexMap[normalizedHeader];
        let value = row[index];

        // Trim whitespace from strings
        if (typeof value === 'string') {
          value = value.trim();
        }

        // Apply parsing logic for specific fields
        switch (propertyName) {
          case 'Coverage':
            if (value === undefined || value === null || value.toString().trim() === '') {
              errors.push('The insured value cannot be zero, negative, or blank.');
              value = undefined;
            } else if (isNaN(value) || typeof value === 'string' && !/^-?\d*\.?\d+$/.test(value)) {
              errors.push('The insured value must be numeric.');
              value = undefined;
            } else {
              value = parseFloat(value);
              if (value <= 0) {
                errors.push('The insured value cannot be zero, negative, or blank.');
                value = undefined;
              } else if (value > this.maxInsuredValue) {
                errors.push('The insured value is greater than the policy limit.');
                value = undefined;
              }
            }

            break;
          case 'Weight':
            // FI-748: Weight is not required
            if (value) {
              // Accept positive values only
              if (!isNaN(value)) {
                value = parseFloat(value);

                if (value <= 0) {
                  errors.push(`${this.formatPropertyName(propertyName)} must be a positive number.`);
                }
              }
              else {
                errors.push(`${this.formatPropertyName(propertyName)} must be a positive number.`);
              }
            }

            break;
          case 'ShipDate': {
            const country = this.getUserCountry();
            const parsedDate = this.parseDateBasedOnCountry(value, country);
            if (parsedDate) {
              value = parsedDate;
            } else {
              value = undefined;
            }

            break;
          }
          case 'ShipmentType':
            if (value !== undefined && value !== '1' && value !== '2') {
              const lowerCaseValue = value.toLowerCase();
              if (lowerCaseValue === 'insurance only') {
                value = 1;
              } else if (lowerCaseValue === 'freight & insurance') {
                value = 2;
              } else {
                value = undefined;
                errors.push('Incorrect Shipment Type.');
              }
            }

            break;
          case 'Service': {
            const service = this.serviceTypes.find(service =>
              (service.ServiceCode.toLowerCase() === value?.toLowerCase() ||
                service.Description.toLowerCase() === value?.toLowerCase()) &&
              service.CarrierCode.toLowerCase() === parsedRow.Carrier?.toLowerCase() && service.IsAllowed === true
            );

            if (service) {
              value = service.ServiceCode;
              this.maxInsuredValue = service.ReportMaxCoverage;
            } else {
              value = undefined;
              errors.push('Incorrect Service.');
            }

            break;
          }
          case 'TrackingNumber':
            if (value === undefined || value.trim() === '') {
              value = undefined;
              errors.push('The tracking number cannot be blank.');
            } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
              errors.push('Invalid Tracking Number: Special characters or spaces are not allowed.');
            } else if (this.existingTrackingNumbers.includes(value)) {
              errors.push('Duplicate tracking number.');
            }

            break;
          case 'ShipFromCountry': {
            if (value !== undefined) {
              const country = this.getCountryFromValue(value);
              if (!country) {
                value = undefined;
                errors.push('Incorrect Ship From Country.');
              } else {
                value = country.CountryCode;
              }
            }

            break;
          }
          case 'ShipToCountry': {
            if (value !== undefined) {
              const country = this.getCountryFromValue(value);
              if (!country) {
                value = undefined;
                errors.push('Incorrect Ship To Country.');
              } else {
                value = country.CountryCode;
              }
            }

            break;
          }
        }

        // Apply validators
        const validators = this.propertyValidators[propertyName];
        if (validators) {
          // Update max coverage validator dynamically
          if (propertyName === 'Coverage') {
            const service = this.serviceTypes.find(service =>
              service.ServiceCode === parsedRow.Service && service.CarrierCode === parsedRow.Carrier
            );
            if (service) {
              validators.push(Validators.max(service.ReportMaxCoverage));
            }
          }

          const control = { value } as AbstractControl;
          const validationErrors: ValidationErrors | null = this.applyValidators(control, validators);

          if (validationErrors) {
            // Collect error messages
            Object.keys(validationErrors).forEach(errorKey => {
              let errorMessage = '';
              switch (errorKey) {
                case 'required':
                  errorMessage = `${this.formatPropertyName(propertyName)} is required.`;
                  break;
                case 'min':
                  errorMessage = `${this.formatPropertyName(propertyName)} should be greater than or equal to ${validationErrors[errorKey].min}.`;
                  break;
                case 'max':
                  errorMessage = `${this.formatPropertyName(propertyName)} should be less than or equal to ${validationErrors[errorKey].max}.`;
                  break;
                case 'invalidDate':
                  errorMessage = `The ship date must be in the format ${this.getUserDateFormat()}.`;
                  break;
                // FI-743: System not allowing users to select date from past 30 days
                case 'dateOutOfRange':
                  errorMessage = `The ship date must be within the past 30 days.`;
                  break;
                case 'maxlength':
                  errorMessage = `${this.formatPropertyName(propertyName)} exceeds the maximum length of ${validationErrors[errorKey].requiredLength} characters.`;
                  break;
                default:
                  errorMessage = `${this.formatPropertyName(propertyName)} is invalid.`;
              }
              errors.push(errorMessage);
            });
          }
        }

        // Assign the value to the parsed row
        (parsedRow as any)[propertyName] = value;
      } else {
        this.notificationService.notify(
          `Header "${normalizedHeader}" is not mapped to any property.`,
          'Error matching header',
          NotificationType.WARNING
        );
      }
    });

    parsedRow.UserId = this.user.UserId;
    parsedRow.CustomerId = this.user.CustomerId;
    parsedRow.ShipToFirstName = '';

    const recordToReturn: IReportShipmentRecord = {
      ReportShipment: parsedRow as IReportShipmentEU,
      Index: -1,
      Errors: errors ? errors : [],
      IsDeleted: false,
      IsEdited: false,
    };

    return recordToReturn;
  }

  private getCountryFromValue(value: string): ICountry {
    const country = this.countries.find(c => c.CountryCode === value
      || c.CountryName.trim().toLowerCase() === value.trim().toLowerCase());

    return country;
  }

  private headerToPropertyMap: { [key: string]: keyof IReportShipmentEU } = {
    'carrier': 'Carrier',
    'fromcountry': 'ShipFromCountry',
    'insuredvalue': 'Coverage',
    'servicelevel': 'Service',
    'shipdate': 'ShipDate',
    'tocountry': 'ShipToCountry',
    'trackingnumber': 'TrackingNumber',
    'shipmenttype': 'ShipmentType',
    'shiptocompanyname': 'ShipToCompanyName',
    'shiptophonenumber': 'ShipToPhone',
    'shiptoattention': 'ShipToLastName',
    'shiptoaddress1': 'ShipToAddress1',
    'shiptoaddress2': 'ShipToAddress2',
    'shiptoaddress3': 'ShipToAddress3',
    'shiptoemail': 'ShipToEmail',
    'shiptozipcode': 'ShipToZip',
    'shiptoprovince': 'ShipToProvince',
    'shiptocity': 'ShipToCity',
    'weight': 'Weight',
    'referencenumber': 'Reference',
    'shipfromcompanyname': 'ShipFromCompanyName',
    'shipfromcontactname': 'ShipFromContactName',
    'shipfromaddress1': 'ShipFromAddress1',
    'shipfromaddress2': 'ShipFromAddress2',
    'shipfromaddress3': 'ShipFromAddress3',
    'shipfromcity': 'ShipFromCity',
    'shipfromprovince': 'ShipFromProvince',
    'shipfromzipcode': 'ShipFromZip',
    'shipfromphonenumber': 'ShipFromPhone',
    'shipfromemail': 'ShipFromEmail',
  };

  private resetDuplicateAndErrorMessages(): void {
    this.duplicateSubmissionCompleteMessage = '';
    this.errorMessage = '';
    this.submitBtnMessage = 'Review';
  }

  private applyValidators(control: AbstractControl, validators: any[]): ValidationErrors | null {
    let errors: ValidationErrors | null = null;
    for (const validator of validators) {
      const result = validator(control);
      if (result) {
        errors = { ...errors, ...result };
      }
    }
    return errors;
  }

  private sheetToArray(sheet: XLSX.WorkSheet): string[][] {
    return XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  }

  private isColumnHeadersMatched(parsedData): boolean {
    const columnHeaders: string[] = parsedData[0];
    const areAllStrings: boolean = columnHeaders.every(header => typeof header === 'string');
    if (columnHeaders.length < 7 || !areAllStrings) {
      return false;
    }

    const lowercaseColumnHeaders: string[] = columnHeaders.map(header => header.toLowerCase().replace(/\s/g, '').trim()).sort();
    return this.requiredHeaders.every(header => lowercaseColumnHeaders.includes(header));
  }

  private showFile(isSubmissionComplete: boolean, isCancelButtonClicked?: boolean): void {
    this.isSubmissionComplete = isSubmissionComplete;
    this.isFileInputDisabled = isSubmissionComplete;

    if (isCancelButtonClicked) {
      this.isFileInputDisabled = false;
    }
  }

  private showReviewSummary(): void {
    this.isFileInputDisabled = true;
    this.isSubmissionComplete = true;
    this.showReview = true;
    this.showInstructions = false;
  }

  private subscribeToCancelRecentUpload(): void {
    this.cancelRecentUploadSubscription = this.reportShipmentService.cancelRecentUploadSubmission$.subscribe({
      next: (fileId) => {
        if (fileId > 0) {
          this.reportShipmentService.updateFile(fileId, null, 0, 'Cancelled', false)
            .subscribe({
              next: () => {
                this.handleSubmitOrCancelSubmission();
              },
              error: (error) => {
                this.spinnerService.hide();
                this.notificationService.notify(
                  error.error.Message,
                  'Error canceling submit',
                  NotificationType.ERROR
                );
              },
            });
        } else {
          this.handleSubmitOrCancelSubmission();
        }
      },
      error: (err) => {
        console.error('Error in cancelRecentUploadSubmission$ subscription:', err);
      },
    });
  }

  private subscribeToSaveProgress(): void {
    this.saveProgressSubscription = this.reportShipmentService.saveProgress$.subscribe({
      next: () => {
        this.handleSubmitOrCancelSubmission();
      },
      error: (err) => {
        console.error('Error in saveProgress$ subscription:', err);
      },
    });
  }

  private subscribeToDataSubmitted(): void {
    this.dataSubmittedSubscription = this.reportShipmentService.dataSubmitted$.subscribe({
      next: (obj) => {
        const newFile = this.createFileFromData(obj.data);
        const numberOfShipments = obj.data.length;
        const currentTime = new Date();
        const hasErrors = obj.hasErrors;
        let status = 'Pending';

        if (obj.isFinal) {
          status = 'Accepted';
        }

        this.spinnerService.show();
        if (this.currentFileId === -1) {
          this.reportShipmentService.uploadFile(newFile, currentTime, numberOfShipments, status, hasErrors)
            .subscribe({
              next: () => {
                this.spinnerService.hide();
                this.getFileUploads();
              },
              error: (error) => {
                this.spinnerService.hide();
                this.notificationService.notify(
                  error.error.Message,
                  'Error uploading file',
                  NotificationType.ERROR
                );
              },
            });
        } else {
          this.reportShipmentService.updateFile(this.currentFileId, newFile, numberOfShipments, status, obj.hasErrors)
            .subscribe({
              next: () => {
                this.spinnerService.hide();
                this.getFileUploads();
              },
              error: (error) => {
                this.spinnerService.hide();
                this.notificationService.notify(
                  error.error.Message,
                  'Error uploading file',
                  NotificationType.ERROR
                );
              }
            });
        }
      },
      error: (err) => {
        console.error('Error in submitForInsurance$ subscription:', err);
      },
    });
  }

  private subscibeToFileDownloaded(): void {
    this.fileDownloadedSubscription = this.reportShipmentService.fileDownloaded$.subscribe({
      next: async (data) => {
        this.spinnerService.show();
        this.isDataLoadingFromFile = true;
        this.editingExistingFile = true;
        this.currentFileId = data.fileId;
        this.uploadedFile = data.file;
        await this.processFile(data.file);
        this.showReviewSummary();
        this.spinnerService.hide();
        this.isDataLoadingFromFile = false;
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error in fileDownloaded$ subscription:', err);
      },
    });
  }

  private subscribeToDownloadAndSubmit(): void {
    this.downloadAndSubmitFileSubscription = this.reportShipmentService.downloadAndSubmitFile$.subscribe({
      next: (fileId) => {
        this.submitData(fileId);
      },
      error: (err) => {
        console.error('Error in downloadAndSubmitFile$ subscription:', err);
      },
    });
  }

  private subscribeToDeleteFile(): void {
    this.deleteFileSubscription = this.reportShipmentService.deleteFile$.subscribe({
      next: () => {
        this.resetPageState();
      },
      error: (err) => {
        console.error('Error in deleteFile$ subscription:', err);
      },
    });
  }

  private handleSubmitOrCancelSubmission(): void {
    this.editingExistingFile = false;
    this.isFileInputDisabled = false;
    this.cdr.detectChanges();
    this.isSubmissionComplete = false;
    this.showReview = false;
    this.showInstructions = true;
    this.selectedFileName = '';
    this.dataFromFile = [];
    this.reportShipmentService.showHeaders$.next(true);
    this.getFileUploads();
  }

  private collectTrackingNumbers(parsedData: string[][]): string[] {
    const trackingNumbers: string[] = [];
    const trackingNumberIndex = this.columnHeaders.findIndex(header => header.toLowerCase().replace(/\s/g, '').trim() === 'trackingnumber');
    if (trackingNumberIndex !== -1) {
      parsedData.slice(1).forEach(row => {
        if (row.length === 0) {
          // empty row - skipping
          return;
        }

        const trackingNumber = row[trackingNumberIndex]?.toString();
        if (trackingNumber) {
          trackingNumbers.push(trackingNumber);
        } else {
          throw new Error("TrackingNumber is missing from the file. Please correct the file and upload again.");
        }
      });
    }

    return trackingNumbers;
  }

  private async validateTrackingNumbers(trackingNumbers: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.reportShipmentService.validateTrackingNumbers(trackingNumbers)
        .subscribe({
          next: msg => {
            this.handleExistingTrackingNumbers(msg);

            resolve();
          },
          error: (err) => {
            if (err.status !== 200 && err.statusText !== 'OK') {
              this.notificationService.notify(
                err.error,
                'Error validating tracking numbers',
                NotificationType.ERROR
              );
              reject(err);
            } else {
              const message = err.error.text;

              this.handleExistingTrackingNumbers(message);

              resolve();
            }
          },
        });
    });
  }

  private handleExistingTrackingNumbers(message: any): void {
    if (message !== true) {
      const prefix = 'The following tracking numbers already exist: ';
      if (message.startsWith(prefix)) {
        message = message.substring(prefix.length, message.length - 1);
      }

      const existingNumbers = message.split(',');
      if (existingNumbers) {
        this.existingTrackingNumbers = existingNumbers;
      }
    } else {
      this.existingTrackingNumbers = [];
    }
  }

  private getFileUploads(): void {
    this.spinnerService.show();
    this.reportShipmentService.getReportedShipmentFileUploads().subscribe({
      next: (fileUploads) => {
        this.fileUploads = fileUploads;
        this.spinnerService.hide();
      },
      error: (error) => {
        this.notificationService.notify(
          error.error,
          'Error fetching file uploads',
          NotificationType.ERROR
        );
        this.spinnerService.hide();
      },
    });
  }

  private createFileFromData(data: IReportShipmentEU[]): File {
    // Access the original uploaded file's name and type
    const originalFile = this.uploadedFile;
    const fileName = originalFile.name;
    const fileType = originalFile.type;
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Determine the bookType for XLSX.write based on the file extension
    let bookType: XLSX.BookType;
    switch (fileExtension) {
      case 'xlsx':
        bookType = 'xlsx';
        break;
      case 'xls':
        bookType = 'biff8';
        break;
      case 'csv':
        bookType = 'csv';
        break;
      default:
        bookType = 'xlsx';
    }

    // Use the original column headers
    const headers = this.columnHeaders;
    const dataArray: unknown[][] = [];
    dataArray.push(headers); // Add headers as the first row

    // Map each data item back to the original headers
    data.forEach((item) => {
      const row: unknown[] = [];
      headers.forEach((header) => {
        const normalizedHeader = header.toLowerCase().replace(/\s/g, '').trim();
        const propertyName = this.headerToPropertyMap[normalizedHeader];
        if (propertyName !== undefined) {
          row.push(item[propertyName]);
        } else {
          row.push(''); // Fill with empty string if property not found
        }
      });
      dataArray.push(row);
    });

    // Convert the array of arrays into a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to binary format
    const wbout = XLSX.write(workbook, { bookType: bookType, type: 'array' });

    // Create a new file with the same name and type as the original
    const newFile = new File([wbout], fileName, { type: fileType });

    return newFile;
  }

  private async processFile(file: File): Promise<void> {
    this.isFileInputDisabled = true;
    this.selectedFileName = file.name;
    try {
      this.dataFromFile = await this.readExcelFile(file, true);
    } catch (error) {
      this.resetPageState();
    } finally {
      this.spinnerService.hide();
    }
  }

  private resetPageState(): void {
    this.isFileInputDisabled = false;
    this.isCancelButtonClicked = true;
    this.duplicateSubmissionCompleteMessage = '';
    this.selectedFileName = '';
    this.isSubmissionComplete = false;
    this.showReview = false;
    this.showInstructions = true;
    this.dataFromFile = [];
    this.editingExistingFile = false;
    this.reportShipmentService.showHeaders$.next(true);
    this.cdr.detectChanges();
  }

  public toggleTipsAndTricks(): void {
    this.tipsAndTricksPanel.toggle();
    if (this.tipsAndTricksPanel.expanded) {
      this.buttonClass = 'alternative-button';
    }
    else {
      this.buttonClass = 'transparent-button';
    }
  }

  public closePanel(): void {
    this.tipsAndTricksPanel.close();
    this.buttonClass = 'transparent-button';
  }

  private getUserCountry(): string {
    return this.user?.CountryCode;
  }

  private getUserDateFormat(): string {
    switch (this.user?.CountryCode) {
      case 'DE':
      case 'FR':
      case 'IT':
      case 'GB':
        return 'dd/mm/yyyy';
      case 'HK':
        return 'yyyy/mm/dd';
      case 'US':
      default:
        return 'mm/dd/yyyy';
    }
  }

  private parseDateBasedOnCountry(dateString: string, country: string): Date | null {
    if (dateString === undefined || dateString === null || dateString.trim() === '') {
      return null;
    }

    const [datePart, timePart] = dateString.split(' ');
    const dateParts = datePart.split('/');
    if (dateParts.length !== 3) {
      return null;
    }

    const countryDateFormats: { [countryCode: string]: string } = {
      'DE': 'd/m/y',
      'FR': 'd/m/y',
      'IT': 'd/m/y',
      'GB': 'd/m/y',
      'HK': 'y/m/d',
      'US': 'm/d/y',
    };

    const format = countryDateFormats[country] || 'm/d/y';

    // Parse date parts
    const [part1, part2, part3] = dateParts.map(part => parseInt(part, 10));

    if (isNaN(part1) || isNaN(part2) || isNaN(part3)) {
      return null;
    }

    let day: number;
    let month: number;
    let year: number;

    switch (format) {
      case 'd/m/y':
        [day, month, year] = [part1, part2, part3];
        break;
      case 'm/d/y':
        [month, day, year] = [part1, part2, part3];
        break;
      case 'y/m/d':
        [year, month, day] = [part1, part2, part3];
        break;
      default:
        return null;
    }

    // Handle two-digit years
    if (year < 100) {
      year += 2000;
    }

    const date = new Date(year, month - 1, day);

    // Validate date
    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month - 1 ||
      date.getDate() !== day
    ) {
      return null;
    }

    if (timePart) {
      const timeParts = timePart.split(':').map(part => parseInt(part, 10));
      if (timeParts.length === 2 || timeParts.length === 3) {
        const [hours, minutes, seconds] = timeParts;
        if (!isNaN(hours) && !isNaN(minutes) && (seconds === undefined || !isNaN(seconds))) {
          date.setHours(hours, minutes, seconds || 0);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    return date;
  }

  private validateShipDate(control: AbstractControl): ValidationErrors | null {
    const dateValue = control.value;
    if (!dateValue || !(dateValue instanceof Date)) {
      return { 'invalidDate': true };
    }

    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30, 0, 0, 0);  // FI-743: System not allowing users to select date from past 30 days
    const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

    if (dateValue < startOfDay || dateValue > endOfDay) {
      return { 'dateOutOfRange': true };
    }

    return null;
  }

  private loadServiceTypes(): void {
    if (this.serviceTypes && this.serviceTypes.length > 0) {
      return;
    }

    this.getReportServiceSubscription = this.reportShipmentService.getReportServices()
      .subscribe({
        next: services => this.serviceTypes = services,
        error: err => {
          this.notificationService.notify(
            err,
            'Error loading service types',
            NotificationType.ERROR
          );
        },
      });
  }

  private loadCountries(): void {
    this.shipmentService.getShipToCountries(Carriers[Carriers.Ups])
      .subscribe(
        (countries) => {
          this.countries = countries;
        },
      );
  }

  private subscribeToBackToUpload(): void {
    this.backToUploadSubscription = this.reportShipmentService.backToUpload$.subscribe({
      next: () => {
        this.resetPageState();
      },
      error: (err) => {
        console.error('Error in backToUpload$ subscription:', err);
      },
    });
  }

  private handleExcelDataError(): void {
    this.notificationService.notify(
      'Complete all required fields, include all necessary columns, and ensure column names match the Bulk Upload template format.',
      'Data Submission Error',
      NotificationType.ERROR
    );
  }

  private checkMissingRequiredFields(parsedData: Array<Array<string>>): string {
    let errorMessage = "";
    let numMissingFields = 0;

    // Note: TrackingNumber is already handled in collectTrackingNumbers()
    const requiredColumns = ['Shipment Type', 'To Country', 'Carrier', 'ShipDate', 'ServiceLevel', 'InsuredValue', 'From Country'];
    requiredColumns.forEach(col => {
      const colIndex = this.columnHeaders.findIndex(header => header?.toLowerCase() === col?.toLowerCase());
      if (colIndex !== -1) {
        parsedData.slice(1).forEach(row => {
          if (row?.length > 0) {
            const val = row[colIndex]?.toString();
            if (!val?.trim()) {
              numMissingFields += 1;

              if (col?.toLowerCase() === 'shipment type') {
                errorMessage = 'Shipment Type is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'to country') {
                errorMessage = 'To Country is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'carrier') {
                errorMessage = 'Carrier is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'shipdate') {
                errorMessage = 'ShipDate is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'servicelevel') {
                errorMessage = 'ServiceLevel is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'insuredvalue') {
                errorMessage = 'InsuredValue is missing from the file. Please correct the file and upload again.';
              }

              if (col?.toLowerCase() === 'from country') {
                errorMessage = 'From Country is missing from the file. Please correct the file and upload again.';
              }
            }
          }
        })
      }
    });

    if (numMissingFields > 1) {
      errorMessage = 'Multiple fields are missing from the file. Please correct the file and upload again.';
    }

    return errorMessage;
  }

  private handleMissingRequiredColumns(message: string): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '600px',
      minHeight: '215px',
      maxHeight: '345px',
      data: {
        title: 'Missing required columns',
        message: message,
        yesMessage: 'Close',
      },
    });
    // Hide spinner if file type is not allowed
    this.spinnerService.hide();
    this.resetPageState();
  }

  private formatPropertyName(name: string): string {
    return name.replace(/(?!^)([A-Z])/g, ' $1');
  }
}
