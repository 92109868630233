import { Pipe, PipeTransform } from '@angular/core';
import { LocaleNumberPipe } from '../locale-number/locale-number.pipe';
import { User } from '../../services/user/models/user.model';
import { UserService } from '../../services/user/user.service';

@Pipe({ name: 'userGBDELocaleNumber' })
export class UserGBDELocaleNumberPipe implements PipeTransform {
  constructor(private userService: UserService,
              private localeNumberPipe: LocaleNumberPipe) {
  }

  public transform(value: number, user: User, minimumFractionDigits = 2): string {
    let locale = 'en-US';    
    if (!user) {
      return this.localeNumberPipe.transform(0, minimumFractionDigits, locale);
    }
   var val = value.toString()
    switch (true) {
      case this.userService.isDEUser(user):
        locale = 'de-DE';
        break;
      case this.userService.isGBUser(user):
        locale = 'en-GB';
       // value = Number(val.replace('.',','));
        break;
      default:
        locale = 'en-US';
        break;
    }
    if(locale == 'en-GB'){
      var output = this.localeNumberPipe.transform(value, minimumFractionDigits,  'de-DE');
    }else if(locale == 'de-DE')
    {
      var output = this.localeNumberPipe.transform(value, minimumFractionDigits, locale);
    }
      else{
      var output = this.localeNumberPipe.transform(value, minimumFractionDigits, locale);
    }
    return output;

    
  }
}
