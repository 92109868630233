import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import { Subject, Subscription } from 'rxjs';
import { AppState } from '../../app.state';
import { SavedQuotesService } from '../services/saved-quotes.service';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { ISavedQuote } from '../models/saved-quote.interface';
import { Observable } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import { Router } from '@angular/router';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { Package } from '../../ship/models/package.model';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SavedQuoteDialogComponent } from './saved-quote-dialog/saved-quote-dialog.component';
import * as _ from 'lodash';
import { SiteConfig } from '../../../config/site-config';
import { ShipmentService } from '../../ship/services/shipment.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { UserCurrencyPipe } from '../../shared/pipes/user-currency/user-currency.pipe';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de', localeDeExtra);

@Component({
  selector: 'upsc-saved-quotes',
  templateUrl: './saved-quotes.component.html',
  styleUrls: ['./saved-quotes.component.scss'],
  providers: [DatePipe],
})
export class SavedQuotesComponent implements OnInit, OnDestroy {
  public userCurrency: string;
  public user: User;
  public isEUUser : boolean = false;
  public savedQuotes: ISavedQuote[];
  public filteredSavedQuotes: ISavedQuote[];
  public filterLastDays = 7;

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  public asyncSavedQuotes: Observable<ISavedQuote[]>;
  public paginate: PaginationInstance;
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;

  private currentPage = 1;
  private pageSize = 5;
  private totalItems = 0;
  private isEmpty = true;

  private getSavedQuotesSubscription: Subscription;
  private getSavedQuoteSubscription: Subscription;
  private deleteSavedQuoteSubscription: Subscription;
  private createHighValueShipmentSubscription: Subscription;
  private confirmShipmentSubscription: Subscription;

  public constructor(private spinnerService: SpinnerService,
                     private utilityService: UtilityService,
                     private storageService: StorageService,
                     private notificationService: NotificationService,
                     private dialog: MatDialog,
                     private router: Router,
                     private shipmentService: ShipmentService,
                     private translateService: TranslateService,
                     private userCurrencyPipe: UserCurrencyPipe,
                     private errorHandlerService: ErrorHandlerService,
                     private savedQuotesService: SavedQuotesService,
                     private readonly appState: AppState,
                     private userService: UserService,
                     private datePipe: DatePipe
  ) {
    dayjs.extend(isSameOrAfter);

    this.user = this.appState.user$();
    this.userCurrency = this.userCurrencyPipe.transform(this.user);
    this.translateService.onLangChange.subscribe(() =>{
      this.updateDTOptionsLanguage();
  })
  }

  public ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('dashboard.noShipments'),
  };
    this.isEUUser = this.userService.isEUUser(this.user?.CountryCode);
    this.updatePaginateConfig();
    this.getSavedQuotes(this.currentPage, this.pageSize);
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.getSavedQuoteSubscription,
      this.deleteSavedQuoteSubscription,
    ]);
  }

  public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
            emptyTable: this.translateService.instant('dashboard.noShipments')
        };

    this.rerender();
}

private rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      if(this.isEmpty) {
          dtInstance.destroy();
      }
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(null);
  });
}

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.currentPage,
      itemsPerPage: this.pageSize,
    };
  }

  private getSavedQuotes(pageNumber: number, pageSize: number) {
    this.spinnerService.show();
    this.utilityService.clearSubscriptions([this.getSavedQuotesSubscription]);
    this.getSavedQuotesSubscription = this.savedQuotesService.getSavedQuotes(pageNumber, pageSize)
      .subscribe(
        (quotes) => {
          if (!quotes || !quotes.length) {
            this.isEmpty = true;
            this.filteredSavedQuotes = [];
            this.dtTrigger.next(null);
            this.spinnerService.hide();
            return;
          } else {
            this.isEmpty = false;
          }
          
          // Filter saved quotes to be within the last {{ filterLastDays }} days.
          this.filteredSavedQuotes = _.cloneDeep(
            quotes.filter(
              (item) => {
                const lastXDays = dayjs().subtract(this.filterLastDays, 'days');

                // [MV3-1821] Change the range of displayed saved quotes.
                return dayjs(item.ShipDate).isSameOrAfter(lastXDays, 'day');
              }));

          this.totalItems = quotes[0].CollectionSize;
          this.currentPage = pageNumber;
          this.pageSize = pageSize;
          this.updatePaginateConfig();

          this.dtTrigger.next(null);
          this.spinnerService.hide();
        },
        (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Getting Quotes',
            NotificationType.ERROR);
          this.dtTrigger.next(null);
          this.spinnerService.hide();
        },
      );
  }

  public onPageChanged(pageNumber: number) {
    this.getSavedQuotes(pageNumber, this.pageSize);
  }

  public getSavedQuote(event, savedQuote: ISavedQuote) {
    event.preventDefault();

    this.utilityService.clearSubscriptions([this.getSavedQuoteSubscription]);
    this.getSavedQuoteSubscription = this.savedQuotesService.getSavedQuote(savedQuote.QuoteId).subscribe(
      quote => this.openSavedQuoteDialog(quote),
      err => this.handleGetSavedQuoteFailure(err),
    );
  }

  private openSavedQuoteDialog(quote: Package) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        quote,
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    const dialogRef = this.dialog.open(SavedQuoteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (!result) {
          return;
        }
      },
    );
  }

  private handleGetSavedQuoteFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting a saved quote',
      NotificationType.ERROR);
  }

  // [MV3-1717] Change functionality from editing a quote (not implemented) to making a shipment.
  public createShipment(event, savedQuote: ISavedQuote) {
    event.preventDefault();

    this.utilityService.clearSubscriptions([this.getSavedQuoteSubscription]);
    this.getSavedQuoteSubscription = this.savedQuotesService.getSavedQuote(savedQuote.QuoteId).subscribe(
      quote => this.makeShipment(quote),
      err => this.handleGetSavedQuoteFailure(err),
    );
  }

  private makeShipment(quote: Package) {
    this.shipmentService.DetailsQuote = quote;
    this.storageService.set('shipment-details', quote);

    // Handle AES shipment.
    if (this.isAESShipment(quote)) {
      this.submitToACE(quote);
      return;
    }

    // Handle high-value shipment.
    if (quote.IsHighValueShipment) {
      this.spinnerService.show();
      this.utilityService.clearSubscriptions([this.createHighValueShipmentSubscription]);
      this.createHighValueShipmentSubscription = this.shipmentService.createHighValueShipment(quote.QuoteId)
        .subscribe(
          res => this.handleCreateHighValueShipmentSuccess(res, quote),
          err => this.handleCreateHighValueShipmentFailure(err),
        );

      return;
    }

    // Handle normal shipment.
    this.spinnerService.show();
    this.utilityService.clearSubscriptions([this.confirmShipmentSubscription]);
    this.confirmShipmentSubscription = this.shipmentService.confirmShipment(quote.QuoteId)
      .subscribe(
        resQuote => this.handleConfirmShipmentSuccess(resQuote),
        err => this.handleConfirmShipmentFailure(err),
      );
  }

  private isAESShipment(quote: Package): boolean {
    const hasHighValueCommodity = !!quote.Commodities &&
      quote.Commodities.reduce(
        (isExceedLimit, commodity) => {
          return isExceedLimit || +commodity.CustomsValue > 2500;
        },
        false);
    const isUseAESDirect = +quote.FilingOption === 1;

    return hasHighValueCommodity && isUseAESDirect;
  }

  private async submitToACE(quote: Package) {
    this.spinnerService.show();

    this.shipmentService.submitToACE(quote?.QuoteId, 12)
      .subscribe(
        res => this.handleSubmitToACESuccess(res),
        err => this.handleSubmitToACEFailure(err),
      );
  }

  private handleSubmitToACESuccess(res) {
    this.notificationService.notify('The quote has been submitted to AES.', 'SUCCESS!', NotificationType.SUCCESS);
    this.spinnerService.hide();
    this.router.navigate(['/pending-shipments']);
  }

  private handleSubmitToACEFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed updating quote status to send to AES.',
      NotificationType.ERROR);
    this.spinnerService.hide();
  }

  private handleCreateHighValueShipmentSuccess(res, quote: Package) {
    this.shipmentService.DetailsQuote = quote;
    this.storageService.set('shipment-details', quote);
    this.goToShipmentDetails(quote);
    this.spinnerService.hide();
  }

  private handleCreateHighValueShipmentFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Creating High-Value Shipment',
      NotificationType.ERROR);
    this.spinnerService.hide();
  }

  private handleConfirmShipmentSuccess(quote: Package) {
    this.shipmentService.DetailsQuote = quote;
    this.storageService.set('shipment-details', quote);
    this.notificationService.notify('Shipment has been confirmed', 'Success', NotificationType.SUCCESS);
    this.goToShipmentDetails(quote);
    this.spinnerService.hide();
  }

  private handleConfirmShipmentFailure(err) {
    this.spinnerService.hide();
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed confirming shipment',
      NotificationType.ERROR);
  }

  private goToShipmentDetails(quote: Package) {
    const queryParams = this.shipmentService.getQuoteQueryParameters(quote);
    this.router.navigate(['/ship/details'], { queryParams });
  }

  public deleteSavedQuote(event, savedQuote: ISavedQuote) {
    event.preventDefault();

    this.utilityService.clearSubscriptions([this.deleteSavedQuoteSubscription]);
    this.deleteSavedQuoteSubscription = this.savedQuotesService.deleteQuote(savedQuote.QuoteId).subscribe(
      result => this.deleteSavedQuoteSuccess(result, savedQuote),
      err => this.deleteSavedQuoteFailure(err),
    );
  }

  private deleteSavedQuoteSuccess(result, savedQuote: ISavedQuote) {
    // TODO: update the local list instead of getting the whole list again.
    this.getSavedQuotes(this.currentPage, this.pageSize);
    this.notificationService.notify('The saved quote has successfully been deleted.', 'SUCCESS!', NotificationType.SUCCESS);
  }

  private deleteSavedQuoteFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed deleting a saved quote',
      NotificationType.ERROR);
  }

  public shouldShowCreateButton(quote: Package) {
    // [MV3-1717] TODO: hide the CREATE button for saved quotes with the past ship date.
    return true;
  }
}
