<!-- Modal -->
<div class="modal " id="trackingdetails" tabindex="-1" role="dialog" aria-labelledby="Tracking Details" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header popup-header" style="color:#573616;">
                <div class="row">
                    <div class="col-md-9 col-sm-6 col-xs-6" style="margin-top: 5px; font-size: 14px;margin-left:2%;"> <span class="text-uppercase"  >{{'TrackingDetails'|translate}}</span>
                    </div>
                     <div class="col-md-2 col-sm-6 col-xs-6 " style="text-align:right;">                        
                        <button type="button" class="btn btn-sm btn-default btn-grey text-uppercase " data-dismiss="modal" aria-label="Close" >{{'Close'|translate}}</button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row row-bottom-3">
                        <div class="col-md-3 col-sm-4 col-xs-12 ">
                            <span class="show grey-font text-uppercase " style="font-size:14px;" > {{'Carrier'|translate}} </span>
                            <span style="color:#573616; font-size:16px;font-weight:normal;">  {{trackingShipmentDetail?.ReportedPackage?.Carrier}} </span>
                        </div>
                        <div class="col-md-4 col-sm-5 col-xs-12 ">
                            <span class="show grey-font text-uppercase" style="font-size:14px;" > {{'TrackingNumber'|translate}} </span>
                            <span style="color:#573616; font-size:16px;font-weight:normal;">  {{trackingShipmentDetail?.ReportedPackage?.TrackingNumber}} </span>
                        </div>
                        <div class="col-md-2 col-sm-3 col-xs-12 col-md-offset-3 ">
                            <span class="show grey-font text-uppercase" style="font-size:14px;" >  {{'Coverage'|translate}} </span>
                            <span style="color:#573616; font-size:16px;font-weight:normal;"> 
                               {{trackingShipmentDetail?.ReportedPackage?.Coverage | number : '1.2-2'}} USD</span>
                        </div>
                    </div>
                    <div class="row row-bottom-1">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span style="font-size:14px;"> <label class="grey-font text-uppercase" style="font-weight:normal;" >{{'Status'|translate}}</label> <label style="color:#573616;font-weight:normal;" > IN TRANSIT </label> </span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
                                    style="width:40%;">
                                </div>
                                <!--<div class="{{shipmentdetails?.Status | shipmentstatusbar}}" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
                                    [ngStyle]="{'width':shipmentdetails?.Status | shipmentstatuspercent}">
                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div class="row row-bottom-4">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <span class="grey-font row-bottom-1 text-uppercase" style="font-size:14px;" >{{'ShippingDestination'|translate}}</span>
                            <p style="margin-top:5px;">
                                <span class="show">{{trackingShipmentDetail?.Package?.ShipTo?.CompanyName}} </span>
                                <span class="show">{{trackingShipmentDetail?.Package?.ShipTo?.FirstName + ' ' + trackingShipmentDetail?.Package?.ShipTo?.LastName}} </span>
                                <span class="show">{{trackingShipmentDetail?.Package?.ShipTo?.StreetAddress}} </span>
                                <span class="show">{{trackingShipmentDetail?.Package?.ShipTo?.ApartmentSuite}} </span>
                                <span class="show">{{trackingShipmentDetail?.Package?.ShipTo?.City + ' ' +trackingShipmentDetail?.Package?.ShipTo?.State + ' ' +
                                     trackingShipmentDetail?.Package?.ShipTo?.Country + ' ' + trackingShipmentDetail?.Package?.ShipTo?.Zip }} </span>
                            </p>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <span class="grey-font row-bottom-1 text-uppercase" style="font-size:14px;" >{{'ShippingDetails'|translate}}</span>
                            <div class="row" style="margin-top:5px;">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <p>
                                        <span class="show row-bottom-1 text-capitalize ">
                                                <button type="button" class="btn btn-bulletpoint"></button> 
                                               {{'ShipmentType'|translate}}
                                         </span>
                                        <span class="show row-bottom-1 text-capitalize">
                                                <button type="button" class="btn btn-bulletpoint"></button> 
                                               {{'ServiceType'|translate}}
                                         </span>
                                        <span class="show row-bottom-1 text-capitalize">
                                                <button type="button" class="btn btn-bulletpoint"></button> 
                                               {{'Weight'|translate}}
                                         </span>
                                    </p>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <p>
                                        <span *ngIf="trackingShipmentDetail?.ReportedPackage?.ShipmentType==1"> {{'InsuranceOnly'|translate}} </span>
                                        <span *ngIf="trackingShipmentDetail?.ReportedPackage?.ShipmentType==2"> {{'ShipAndInsure'|translate}} </span>
                                        <!--<span class="show row-bottom-1"> {{trackingShipmentDetail?.ReportedPackage?.ShipmentType}} </span>-->
                                        <span class="show row-bottom-1"> {{trackingShipmentDetail?.ReportedPackage?.ServiceDesc}} </span>
                                        <span class="show row-bottom-1"> {{trackingShipmentDetail?.ReportedPackage?.Weight}} </span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12">
                            </div>
                        </div>
                    </div>
                    <div class="row row-bottom-3">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span class="grey-font text-uppercase" style="font-size:14px;" > {{'ShippingProgress'|translate}}  </span>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-responsive">
                                <table class="table table-inverse table-striped">
                                    <thead>
                                        <tr class="grid-head text-nowrap text-uppercase black-font">
                                            <td>
                                                {{'Location'|translate}}
                                            </td>
                                            <td>
                                                {{'ShipDate'|translate}}
                                            </td>
                                            <td>
                                                {{'LocalTime'|translate}}
                                            </td>
                                            <td>
                                                {{'Description'|translate}}
                                            </td>
                                            <td>
                                                {{'SignedBy'|translate}}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody class="text-nowrap grid-rows" style="font-size:12px;" >
                                        <tr>
                                            <td>
                                                Jojhn's Jewel'
                                            </td>
                                            <td>
                                                12-12-2017
                                            </td>
                                            <td>
                                                5:24PM
                                            </td>
                                            <td>
                                                Lorem Ipsum
                                            </td>
                                            <td>
                                                john Lee
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>