import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AppState } from '../../app.state';
import { CarrierPipe } from '../pipes/carrier.pipe';
import { ServicesPipe } from '../pipes/services.pipe';
import { ShipmentStatusPipe } from '../pipes/shipment-status.pipe';
import { ShipmentHistoryService } from '../services/shipment-history.service';
import { ShipmentHistoryUserFields } from '../models/shipment-history-user-fields';
import { ShipmentHistoryDatePipe } from '../pipes/shipment-history-date.pipe';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { TrackingDetailsDialogComponent } from '../../history/report-history/tracking-details-dialog/tracking-details-dialog.component';
import { IQuickTrackShipment } from '../../shared/models/shipments/quick-track-shipment.interface';
import { ShipmentDetailComponent } from '../shipment-detail/shipment-detail.component';
import { IRowColumn } from '../../shared/components/responsive-table/models/row-column.interface';
import moment from 'moment';
import { Moment, MomentInput } from 'moment';
import * as _ from 'lodash';
import { ShipmentStatus } from '../../shared/enum/general-enum';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from '../../core/services/storage/storage.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { Package } from '../../ship/models/package.model';
import { PaginationInstance } from 'ngx-pagination';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { UserService } from '../../shared/services/user/user.service';
import { SharedService } from '../../core/services/shared.service';
import { SiteConfig } from '../../../config/site-config';
import { User } from '../../shared/services/user/models/user.model';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NgxResponsiveTableConfig } from '../../shared/components/ngx-responsive-table/models/ngx-responsive-table-config';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'upsc-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.scss'],
})
export class ShipmentHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild('datePicker') datePicker: ElementRef;

  public searchFormGroup: UntypedFormGroup;
  public dateRangeFormGroup: UntypedFormGroup;
  public customDateRangeFormGroup: UntypedFormGroup;

  public data: any[];
  public displayedShipments: Package[];
  public shipmentdetails: any;
  public filterDate: string;
  public pageIndex = 1;
  public totalrows: number;
  public NoMoreRecords = '';
  public voidshipmentid: string;
  public showshipmentdetail: boolean;
  public columnNames: string[];
  public selectedColumnNames: string[];

  public predefinedDayRanges = [1, 7, 15, 30, 60];
  public dateRangeAll = 0;
  public dateRangeCustom = -1;
  public defaultStartDate = moment().subtract(7, 'days').startOf('day').toDate();
  public defaultEndDate = moment().toDate();
  public formattedStartDate = moment().subtract(7, 'days').startOf('day').format('MM/DD/YYYY');
  public formattedEndDate = moment().format('MM/DD/YYYY');

  public isSearching = false;
  public searchMode = false;
  public user: User;
  public userCurrencyCode = 'USD';
  public tableConfig: NgxResponsiveTableConfig;
  private parameterDateFormat = 'YYYY-MM-DD';

  public paginate: PaginationInstance;
  private totalItems = 0;

  private currentPredefinedDateRange = 7;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  private userColumnsStorageName = 'shipment-history-selected-columns';
  private searchShipmentsSubscription: Subscription;
  private getShipmentHistorySubscription: Subscription;

  public columns: IRowColumn[];
  public items: any[];
  public totalItemCount: number;
  public tablePageSize = 25;
  public tablePageIndex = 1;
  public isDataLoading = false;
  public sessionLanguage : string;


  public constructor(public shipmentservice: ShipmentHistoryService,
                     public shipmenthistoryuserfields: ShipmentHistoryUserFields,
                     private carrierpipe: CarrierPipe,
                     private servicespipe: ServicesPipe,
                     private shipmentstatuspipe: ShipmentStatusPipe,
                     private currencypipe: CurrencyPipe,
                     private shipmentdatepipe: ShipmentHistoryDatePipe,
                     private cdRef: ChangeDetectorRef,
                     private notificationService: NotificationService,
                     private carrierPipe: CarrierPipe,
                     private servicesPipe: ServicesPipe,
                     private shipmentStatusPipe: ShipmentStatusPipe,
                     private decimalPipe: DecimalPipe,
                     private currencyPipe: CurrencyPipe,
                     private dialogService: DialogService,
                     private storageService: StorageService,
                     private spinnerService: SpinnerService,
                     private formBuilder: UntypedFormBuilder,
                     private utilityService: UtilityService,
                     public userService: UserService,
                     private sharedService: SharedService,
                     private translateService: TranslateService,
                     private errorHandlerService: ErrorHandlerService,
                     private dialog: MatDialog,
                     private readonly appState: AppState
  ) {
    this.user = this.appState.user$();

    this.tableConfig = {
      allowDensityChange: true,
      defaultDensity: 'loose',
      small: {
        maxCollapsedColumnCount: 3,
      },
      medium: {
        isFreezeFirstColumn: true,
      },
    };

    // this.GetData(this.pageIndex.toString());
    this.columnNames = Object.keys(shipmenthistoryuserfields);
    this.restoreUserColumns();

    // [MV3-1695] Display currency based on the user name.
    switch (true) {
      case this.userService.isDEFRITUser(this.user):
        this.userCurrencyCode = 'EUR';
        break;
      case this.userService.isGBUser(this.user):
        this.userCurrencyCode = 'GBP';
        break;
      default:
        this.userCurrencyCode = 'USD';
        break;
    }

    this.translateService.onLangChange.subscribe((event) => {
      this.updateDTOptionsLanguage();
    });
  }

  public ngOnInit() {

    this.sessionLanguage = sessionStorage.getItem('lang');
    
    this.dtOptions = SiteConfig.tableConfig;

    this.dtOptions.language = {
      emptyTable: this.translateService.instant('dashboard.noShipments'),
    };

    this.searchFormGroup = this.formBuilder.group({
      keyword: [''],
    });

    this.dateRangeFormGroup = this.formBuilder.group({
      dayCount: [this.currentPredefinedDateRange],
    });

    this.dateRangeFormGroup.controls.dayCount.valueChanges
      .subscribe(
        (value) => {
          this.currentPredefinedDateRange = +value;
          this.tablePageIndex = 1;

          if (+value === -1) {
            return;
          }

          this.onDateRangeChanged(+value);
        },
      );

    this.customDateRangeFormGroup = this.formBuilder.group({
      startDate: [this.defaultStartDate],
      endDate: [this.defaultEndDate],
    });

    this.customDateRangeFormGroup.controls.startDate.valueChanges
      .subscribe(
        (value) => {
          this.formattedStartDate = moment(value).format('MM/DD/YYYY');

          if (!this.customDateRangeFormGroup.controls.endDate.value) {
            return;
          }

          this.dateRangeFormGroup.controls.dayCount.setValue(-1);
          this.onCustomDateRangeChanged(value, this.customDateRangeFormGroup.controls.endDate.value);
        },
      );

    this.customDateRangeFormGroup.controls.endDate.valueChanges
      .subscribe(
        (value) => {
          this.formattedEndDate = moment(value).format('MM/DD/YYYY');

          if (!this.customDateRangeFormGroup.controls.startDate.value) {
            return;
          }

          this.dateRangeFormGroup.controls.dayCount.setValue(-1);
          this.onCustomDateRangeChanged(this.customDateRangeFormGroup.controls.startDate.value, value);
        },
      );

    // [MV3-1670] Change filters to trigger an API request.
    this.dateRangeFormGroup.controls.dayCount.setValue(this.currentPredefinedDateRange);

    this.searchFormGroup.controls.keyword.valueChanges.subscribe(value => {
      if (!value) {
        this.tablePageIndex = 1;
        this.onDateRangeChanged(this.currentPredefinedDateRange);
      }
    });

    this.updatePaginateConfig();
  }

  public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
            emptyTable: this.translateService.instant('dashboard.noShipments')
        };

    this.rerender();
  }
  
  rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        if(this.totalItems === 0){
          dtInstance.destroy();
        }
        // Call the dtTrigger to rerender again
        this.dtTrigger.next(null);
    });
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.searchShipmentsSubscription,
      this.getShipmentHistorySubscription,
    ]);
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  private restoreUserColumns() {
    this.selectedColumnNames = this.storageService.get(this.userColumnsStorageName) || this.columnNames;
    Object.keys(this.shipmenthistoryuserfields).map(
      (key) => {
        this.shipmenthistoryuserfields[key] = this.selectedColumnNames.includes(key);
      },
    );
  }

  openShipmentDetail(shipmentId: string) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '800px',
      data: {
        shipmentId,
        shipmentType: 'web',
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    let dialogRef: MatDialogRef<ShipmentDetailComponent>;
    dialogRef = this.dialog.open(ShipmentDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result === 'voided') {
          this.onDateRangeChanged(this.currentPredefinedDateRange);
        }
      });
  }

  public ngAfterViewInit() {
    
  }

  GetData(index: string, fromDate?: string, toDate?: string) {
    this.utilityService.clearSubscriptions([this.getShipmentHistorySubscription]);

    this.isSearching = true;
    this.searchMode = false;
    this.getShipmentHistorySubscription = this.shipmentservice.getShipmentHistory(index, this.tablePageSize.toString(), fromDate, toDate)
      .subscribe(
        (packages) => {
          this.data = packages;
          this.displayedShipments = <any[]>_.cloneDeep(packages);

          // [MV3-1670] Stop setting filters as they are used for an API request.
          // this.dateRangeFormGroup.controls.dayCount.setValue(this.currentPredefinedDateRange);

          if (!this.data || !this.data.length) {
            this.displayedShipments = [];
            this.dtTrigger.next(null);
            this.isSearching = false;

            return;
          }

          if (this.currentPredefinedDateRange === 0) {
            const clonedData = _.cloneDeep(this.data);
            const dateRangeStart = this.utilityService.getMinMoment(clonedData.map(item => item.ShipDate));
            const dateRangeEnd = this.utilityService.getMaxMoment(clonedData.map(item => item.ShipDate));

            this.customDateRangeFormGroup.patchValue(
              {
                startDate: dateRangeStart.toDate(),
                endDate: dateRangeEnd.toDate(),
              },
              { emitEvent: false });

            this.formattedStartDate = dateRangeStart.format('MM/DD/YYYY');
            this.formattedEndDate = dateRangeEnd.format('MM/DD/YYYY');
          }

          this.totalItems = +packages[0].TotalShipments;
          this.tablePageIndex = +index;
          this.updatePaginateConfig();

          this.dtTrigger.next(null);
          this.isSearching = false;
        },
        (error) => {
          this.dtTrigger.next(null);
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            this.translateService.instant('ErrorLoadingShipmentHistory'),
            NotificationType.ERROR);
          this.isSearching = false;
        });
  }

  confirmVoid(shipmentId: string) {
    this.dialogService.confirm('', this.translateService.instant('Msg_RemoveShipmentConfirmation'))
      .subscribe(
        (isConfirmed) => {
          if (isConfirmed) {
            this.voidShipment(shipmentId);
          }
        });
  }

  voidShipment(shipmentId: string) {
    this.shipmentservice.deleteShipment(shipmentId)
      .subscribe(
        (response) => {
          this.notificationService.notify(
            this.translateService.instant('Msg_VoidedInfo'),
            this.translateService.instant('VoidedShipment_Msg'),
            NotificationType.SUCCESS);

          // Refresh the data so in voided status record, "Void" button will not be shown
          this.onDateRangeChanged(this.currentPredefinedDateRange);
        },
        (error) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            this.translateService.instant('ErrorVoidingShipment'),
            NotificationType.ERROR);

        });
  }

  //#region Export Data
  // Final Code for Download CSV Function
  public download() {
    if (this.data && this.data.length > 0) {
      this.spinnerService.show();
      const startDate = +this.dateRangeFormGroup.controls.dayCount.value !== 0 ? moment(this.customDateRangeFormGroup.controls['startDate'].value).format(this.parameterDateFormat) : null;
      const endDate = +this.dateRangeFormGroup.controls.dayCount.value !== 0 ? moment(this.customDateRangeFormGroup.controls['endDate'].value).format(this.parameterDateFormat) : null;

      this.shipmentservice.getShipmentHistory(
        '1',        
        this.searchMode?this.data[0].TotalCount:this.data[0].TotalShipments,
        startDate, endDate)
        .subscribe(
          (dataToExport) => {
            this.spinnerService.hide();
            this.CreateFile(dataToExport);
          },
          (err) => {
            this.notificationService.notify(
              this.errorHandlerService.getHttpErrorMessage(err),
              this.translateService.instant('ExportFailed'),
              NotificationType.ERROR);
            this.spinnerService.hide();
          });

    }
  }

  CreateFile(dataToExport) {
    var csvData = this.ConvertToCSV(dataToExport);
    // var a = document.createElement('a');
    // a.setAttribute('style', 'display:none;');
    // document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'shipment-history_' + new Date().toLocaleDateString() + '.csv');
    //  var url = window.URL.createObjectURL(blob);
    // a.href = url;
    // a.download = 'shipment-history_' + new Date().toLocaleDateString() + '.csv';
    //  a.click();
  }

  // convert Json to CSV data in Angular2
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = '';

    array = this.shipmentdatepipe.transform(array, this.filterDate);

    row += 'ShipToCompanyName,ShipToFirstName,ShipToLastName,ShipToAddress1,ShipToAddress2,ShipToCity,ShipToState,ShipToZip,ShipToCountry,ShipToPhone,ShipToEmail,TrackingNumber,InsuredValue,ReferenceNumber,CustomerReferenceNumber,ShipFromFirstName,ShipFromLastName,ShipDate,CreateDate,ServiceDesc,Status';

    //This is to export columns displayed in Grid
    /*for (let key in this.shipmenthistoryuserfields) {
        if (this.shipmenthistoryuserfields.hasOwnProperty(key) && this.shipmenthistoryuserfields[key]) {
            row += key + ',';
        }
    }
          row = row.slice(0, -1); // remove last comma
          */

    str += row + '\r\n'; //append columns to str and moves to next line

    str = this.fillColumns(array, str, row);

    /*  //This is to include all columns
      for (var index in objArray[0]) {
          //Now convert each value to string and comma-separated
          row += index + ',';
      }
      row = row.slice(0, -1);
      //append Label row with line break
      str += row + '\r\n';

      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','

              line += array[i][index];
          }
          str += line + '\r\n';
      }
      */

    return str;
  }

  fillColumns(array, stringToExport, row) {
    if (array && array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        var line = '';
        if (row.includes('ShipToCompanyName'))      
        var splitData = array[i].ShipTo.CompanyName.replaceAll(',',' ')            
         line += splitData + ',';
        if (row.includes('ShipToFirstName'))
        var splitFirstName = array[i].ShipTo.FirstName.replaceAll(',',' ')            
        line += splitFirstName + ',';
          //line += array[i].ShipTo.FirstName + ',';
        if (row.includes('ShipToLastName'))
        var splitLastName = array[i].ShipTo.LastName.replaceAll(',',' ')            
        line += splitLastName + ',';         
        if (row.includes('ShipToAddress1'))        
        var splitStreetAddress = array[i].ShipTo.StreetAddress.replaceAll(',',' ')            
        line += splitStreetAddress + ',';  
         // line += '"' + array[i].ShipTo.StreetAddress + '"' + ',';
        if (row.includes('ShipToAddress2'))
          //line += '"' + array[i].ShipTo.ApartmentSuite + '"' + ',';
          var splitStreetApartmentSuite = array[i].ShipTo.ApartmentSuite.replaceAll(',',' ')            
        line += splitStreetApartmentSuite + ',';  
        if (row.includes('ShipToCity'))
        var splitStreetCity= array[i].ShipTo.City.replaceAll(',',' ')            
        line += splitStreetCity + ',';  
        //  line += array[i].ShipTo.City + ',';
        if (row.includes('ShipToState'))
        var splitStreetState= array[i].ShipTo.State.replaceAll(',',' ')            
        line += splitStreetState + ',';  
         // line += array[i].ShipTo.State + ',';
        if (row.includes('ShipToZip'))
        var splitZip= array[i].ShipTo.Zip.replaceAll(',',' ')            
        line += splitZip + ','; 
         // line += array[i].ShipTo.Zip + ',';
        if (row.includes('ShipToCountry'))
        var splitCountry= array[i].ShipTo.Country.replaceAll(',',' ')            
        line += splitCountry + ','; 
        //  line += array[i].ShipTo.Country + ',';
        if (row.includes('ShipToPhone'))
        var splitTelephoneNo= array[i].ShipTo.TelephoneNo.replaceAll(',',' ')            
        line += splitTelephoneNo + ','; 
          //line += array[i].ShipTo.TelephoneNo + ',';
        if (row.includes('ShipToEmail'))
        var splitEmail= array[i].ShipTo.Email.replaceAll(',',' ')            
        line += splitEmail+ ','; 
        //  line += array[i].ShipTo.Email + ',';
        if (row.includes('TrackingNumber'))
        var splitTrackingNumber= array[i].TrackingNumber.replaceAll(',',' ')            
        line += splitTrackingNumber+ ','; 
          //line += array[i].TrackingNumber + ',';
        if (row.includes('InsuredValue'))
          //Below value is enclosed in double quotes to escape comma in value eg. 2,500
          // TODO : below boolean value should be replaced by 'code'. Currently this is not supported.
          line += '"' + this.currencypipe.transform(array[i].InsuredValue, 'USD', false, '1.2-2') + '"' + ',';
        if (row.includes('InsuredValueMultiplier'))
          line += ',';
        if (row.includes('InsuredValueThreshold'))
          line += ',';
        if (row.includes('InsuredValueMultiplierEnabled'))
          line += ',';
        if (row.includes('ReferenceNumber'))
        var splitReferenceNumber= array[i].ReferenceNumber.replaceAll(',',' ')            
        line += splitReferenceNumber+ ','; 
         // line += array[i].ReferenceNumber + ',';
        if (row.includes('CustomerReferenceNumber'))
        var splitNumber = array[i].CustomerReferenceNumber.replaceAll(',',' ');        
        line += splitNumber + ',';
        if (row.includes('ShipFromFirstName'))
        var splitFirstName= array[i].ShipFrom.FirstName.replaceAll(',',' ')            
        line += splitFirstName+ ','; 
          //line += array[i].ShipFrom.FirstName + ',';
        if (row.includes('ShipFromLastName'))
        var splitLastName= array[i].ShipFrom.LastName.replaceAll(',',' ')            
        line += splitLastName+ ',';
//          line += array[i].ShipFrom.LastName + ',';
        if (row.includes('ShipDate'))
        var splitShipDate=  array[i].ShipDate.replaceAll(',',' ')            
        line += splitShipDate+ ',';
         // line += array[i].ShipDate + ',';
        if (row.includes('CreateDate'))
        var splitShipDates=  array[i].ShipDate.replaceAll(',',' ')            
        line += splitShipDates+ ',';
          //line += array[i].ShipDate + ',';
        if (row.includes('ServiceDesc'))
          line += this.servicespipe.transform(array[i].ServiceCode) + ',';
        if(row.includes('Status'))        
        line += this.shipmentstatuspipe.transform(array[i].Status) + ',';
        
        

        /*   if (row.includes('Carrier'))
               line += this.carrierpipe.transform(array[i].CarrierCode) + ',';
           if (row.includes('Status'))
               line += this.shipmentstatuspipe.transform(array[i].Status) + ',';   */

        line = line.slice(0, -1);
        stringToExport += line + '\r\n';
      }
    }
    return stringToExport;
  }

  
  //#endregion

  public onColumnSelectionChanged(columnNames: string[]) {
    this.selectedColumnNames = columnNames;

    Object.keys(this.shipmenthistoryuserfields).forEach(
      (key) => {
        this.shipmenthistoryuserfields[key] = columnNames.includes(key);
      },
    );

    this.storageService.set(this.userColumnsStorageName, columnNames);
  }

  public onSearchSubmit(event, form) {
    event.preventDefault();
    if(this.isDate(form.keyword)){
      this.searchShipments(this.getFormattedDate(new Date(form.keyword)), '1');
    }else{
      this.searchShipments(form.keyword, '1');
    }
  }

  private searchShipments(keyword: string, index: string) {
    if (!keyword) {
      this.tablePageIndex = 1; // resetting the grid
      this.onDateRangeChanged(this.currentPredefinedDateRange);
      return;
    }

    this.isSearching = true;
    this.searchMode = true;
    this.utilityService.clearSubscriptions([this.searchShipmentsSubscription]);
    this.searchShipmentsSubscription = this.shipmentservice.searchShipments(keyword, index, this.tablePageSize?.toString())
      .subscribe(
        shipments => this.handleSearchShipmentsSuccess(shipments, index),
        err => this.handleSearchShipmentsFailure(err),
      );
  }

  private handleSearchShipmentsSuccess(shipments, index: string) {
    this.data = shipments;
    this.displayedShipments = _.cloneDeep(this.data);

    if (!this.data || !this.data.length) {
      this.dtTrigger.next(null);
      this.isSearching = false;
      this.totalItems = 0;
      this.updatePaginateConfig();

      return;
    }

    this.totalItems = +this.data[0].TotalCount;
    this.tablePageIndex = +index;
    this.updatePaginateConfig();

    this.dtTrigger.next(null);
    this.isSearching = false;
  }

  private handleSearchShipmentsFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('FailedSearchingShipments'),
      NotificationType.ERROR);
    this.isSearching = false;
  }

  public onDateRangeChanged(dayCount: number) {
    let dateRangeStart = moment().subtract(dayCount, 'days').startOf('day');
    let dateRangeEnd = moment().add(7, 'days').startOf('day');

    switch (dayCount) {
      case -1:
        dateRangeStart = moment(this.customDateRangeFormGroup.controls.startDate.value).startOf('day');
        dateRangeEnd = moment(this.customDateRangeFormGroup.controls.endDate.value).endOf('day');

        break;
      default:
        break;
    }

    if (![-1, 0].includes(dayCount)) {
      this.setCustomDateRangeValues(dateRangeStart, dateRangeEnd, false);
    }

    switch (dayCount) {
      case 0:
        this.GetData(this.tablePageIndex.toString());

        break;
      default:
        this.onCustomDateRangeChanged(dateRangeStart, dateRangeEnd);

        break;
    }
  }

  private setCustomDateRangeValues(dateRangeStart: Moment, dateRangeEnd: Moment, isEmitEvent = false) {
    this.customDateRangeFormGroup.patchValue(
      {
        startDate: dateRangeStart.toDate(),
        endDate: dateRangeEnd.toDate(),
      },
      {
        emitEvent: isEmitEvent,
      });

    this.formattedStartDate = dateRangeStart.format('MM/DD/YYYY');
    this.formattedEndDate = dateRangeEnd.format('MM/DD/YYYY');
  }

  public onCustomDateRangeChanged(startDateString: MomentInput, endDateString: MomentInput) {
    const dateRangeStart = moment(startDateString);
    const dateRangeEnd = moment(endDateString);

    // [MV3-1670] Use the date range filters as API request parameters.
    this.GetData(
      this.tablePageIndex.toString(),
      dateRangeStart.startOf('day').format(this.parameterDateFormat),
      dateRangeEnd.startOf('day').format(this.parameterDateFormat));

    this.dtTrigger.next(null);
  }

  public onPageChanged(pageNumber: number) {
    this.tablePageIndex = pageNumber;
    if (!this.searchMode)
      this.onDateRangeChanged(this.currentPredefinedDateRange);
    else this.searchShipments(this.searchFormGroup.controls.keyword.value, this.tablePageIndex.toString());
  }

  public toLocaleNumber(value: number): string {
    if (this.userService.isDEUser(this.user)) {
      return value.toLocaleString('de-DE', { minimumFractionDigits: 2 });
    }

    return this.decimalPipe.transform(value, '1.2-2');
  }

  private getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '-' + day + '-' + year;
  }

  private isDate(dateToCheck) {
    return isNaN(dateToCheck) && !isNaN(Date.parse(dateToCheck));
  }

  public openTrackedShipmentDialog(event, quickTrackShipment: IQuickTrackShipment) {
    event.preventDefault();

    //this.isGettingShipmentDetails = true;
    this.shipmentservice.getShipmentByShipmentId(quickTrackShipment.ShipmentId)
        .subscribe(
            shipment => this.handleGetShipmentSuccess(shipment),
            err => this.handleGetShipmentFailure(err),
        );
}

private handleGetShipmentSuccess(shipmentPackage: Package) {
  const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
          shipmentPackage,
          shipmentType: 'web',
      },
  };

  const dialogRef = this.dialog.open(TrackingDetailsDialogComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(
      (result) => {
          if (!result) {
              return;
          }
      },
  );

  //this.isGettingShipmentDetails = false;
}
private handleGetShipmentFailure(err) {
  this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('dashboard.failedGettingShipmentDetails'),
      NotificationType.ERROR);
  //this.isGettingShipmentDetails = false;
}
}
