<!--<div class="dropdown text-capitalize text-right black-font">
    <button class="btn dropdown-toggle btn-grey" type="button" data-toggle="dropdown">
      {{'ViewReports'|translate}}
  <span class="caret"></span></button>
    <ul class="dropdown-menu text-capitalize dropdown-menu-right reports-dropdown-menu">
        <li><a href="javascript:;">{{'EndofDayReport'|translate}}</a></li>
        <li><a href="javascript:;">{{'DailyShipmentSummary'|translate}}</a></li>
    </ul>
</div>-->
<mat-form-field [floatLabel]="'auto'" >
    <mat-select placeholder="{{'ViewReports'|translate}}">
        <mat-option> <a href="/reports/end-of-day-reports">{{'EndofDayReport'|translate}}</a> </mat-option>
        <mat-option> <a href="javascript:;">{{'DailyShipmentSummary'|translate}}</a> </mat-option>
    </mat-select>
</mat-form-field>