<div class="claims page-center">
    <div class="ups-page-header">
        <h1 class="page-title"> {{'PackageInvestigation'|translate}} </h1>
        <!--<div class="page-actions">
            <button type="button" mat-raised-button (click)="SaveClaims()" class="page-action">
                {{'Submit'|translate}}
            </button>
        </div>-->
    </div>

    <div class="claim-sections">
        <ng-container [ngTemplateOutlet]="instructions"></ng-container>
        <!--<ng-container [ngTemplateOutlet]="form"></ng-container>-->
    </div>

    <!--<form #claimsForm="ngForm" novalidate>-->
        <!--<div class="row row-bottom-2">-->
            <!--<div class="col-md-6  ">-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6 orange-font">-->
                        <!--<label class="ctrl-label"><h4> {{'PackageInformation'|translate}} </h4></label>-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6 form-group">-->
                        <!--<mat-form-field>-->
                            <!--<input matInput-->
                                   <!--required-->
                                   <!--type="text"-->
                                   <!--name="trackingNumber"-->
                                   <!--placeholder="{{'TrackingNumber'|translate}}"-->
                                   <!--[(ngModel)]="claims.trackingnumber"-->
                                   <!--maxlength="50"-->
                                   <!--autocomplete="off"-->
                                   <!--pattern="[a-zA-Z0-9]+"-->
                                   <!--class="text-bold black-font text-small">-->
                        <!--</mat-form-field>-->
                        <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="trackingNumber" >{{'TrackingNumber'|translate}} *</label>-->
                              <!--<input id="trackingNumber" name="trackingNumber" required placeholder="{{'TrackingNumber'|translate}} *" class="form-control input-md text-bold black-font text-small txtbox"-->
                                <!--type="text" [(ngModel)]="claims.trackingnumber" maxlength="50" autocomplete="off" pattern="[a-zA-Z0-9]+">&ndash;&gt;-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6 orange-font">-->
                        <!--<label class="ctrl-label"><h4> {{'ContactInformation'|translate}} </h4></label>-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6">-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--required-->
                                           <!--type="text"-->
                                           <!--name="companyName"-->
                                           <!--placeholder="{{'CompanyName'|translate}}"-->
                                           <!--[(ngModel)]="claims.companyname"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="companyName">{{'CompanyName'|translate}} *</label>-->
                                <!--<input id="companyName" name="companyName" required placeholder="{{'CompanyName'|translate}} *" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.companyname">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--type="text"-->
                                           <!--name="accountNo"-->
                                           <!--readonly="true"-->
                                           <!--class="readonly-txtbox"-->
                                           <!--placeholder="{{'ParcelProAccountNumber'|translate}} *"-->
                                           <!--[(ngModel)]="claims.ParcelProAccount">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="accountNo">{{'ParcelProAccountNumber'|translate}}</label>-->
                                <!--<input id="accountNo" name="accountNo" readonly="true" class="form-control input-md txtbox readonly-txtbox" type="text" [(ngModel)]="claims.ParcelProAccount">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--required-->
                                           <!--type="text"-->
                                           <!--name="contactName"-->
                                           <!--placeholder="{{'ContactName'|translate}}"-->
                                           <!--[(ngModel)]="claims.contactname"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="contactName">{{'ContactName'|translate}} *</label>-->
                                <!--<input id="contactName" name="contactName" required placeholder="{{'ContactName'|translate}} *" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.contactname">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--required-->
                                           <!--type="text"-->
                                           <!--name="phoneNumber"-->
                                           <!--placeholder="{{'PhoneNumber'|translate}}"-->
                                           <!--[(ngModel)]="claims.contactphone"-->
                                           <!--pattern="\d*"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="phoneNumber">{{'PhoneNumber'|translate}} *</label>-->
                                <!--<input id="phoneNumber" name="phoneNumber" required placeholder="{{'PhoneNumber'|translate}} *" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.contactphone" pattern="\d*">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--type="text"-->
                                           <!--name="faxNumber"-->
                                           <!--placeholder="{{'FaxNumber'|translate}}"-->
                                           <!--[(ngModel)]="claims.contactfax"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="faxNumber">{{'FaxNumber'|translate}}</label>-->
                                <!--<input id="faxNumber" name="faxNumber" placeholder="{{'FaxNumber'|translate}}" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.contactfax">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="row">-->
                            <!--<div class="col-md-12 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--required-->
                                           <!--type="text"-->
                                           <!--name="email"-->
                                           <!--placeholder="{{'EmailAddress'|translate}}"-->
                                           <!--[(ngModel)]="claims.contactemail"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="email">{{'EmailAddress'|translate}} *</label>-->
                                <!--<input id="email" name="email" required placeholder="{{'EmailAddress'|translate}} *" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.contactemail">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6 orange-font">-->
                        <!--<label class="ctrl-label"><h4> {{'TypeOfInvestigation'|translate}} </h4></label>-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-6">-->
                        <!--<div class="row">-->
                            <!--<div class="form-group  col-md-12">-->
                                <!--<mat-form-field>-->
                                    <!--<mat-select required-->
                                                <!--name="lossType"-->
                                                <!--placeholder="Select Loss Type"-->
                                                <!--[(ngModel)]="claims.typeofdamage">-->
                                        <!--<mat-option *ngFor="let item of lossTypes" value="{{item}}"> {{item}}-->
                                        <!--</mat-option>-->
                                    <!--</mat-select>-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-smaller" for="lossType"> &nbsp; </label>-->
                                <!--<select id="lossType" name="lossType" [(ngModel)]="claims.typeofdamage" required class="form-control txtbox">-->
                                                      <!--<option value="0" > {{'SelectLossType'|translate}} </option>-->
                                                      <!--<option *ngFor="let item of lossTypes" value="{{item}}" > {{item}} </option>-->
                                                      <!--</select>&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="col-md-6">-->
                        <!--<div class="row" *ngIf="claims.typeofdamage=='Others'">-->
                            <!--<div class="col-md-9 form-group">-->
                                <!--<mat-form-field>-->
                                    <!--<input matInput-->
                                           <!--required-->
                                           <!--type="text"-->
                                           <!--name="others"-->
                                           <!--placeholder="{{'AddOtherContentsHere'|translate}}"-->
                                           <!--[(ngModel)]="claims.other"-->
                                           <!--autocomplete="off">-->
                                <!--</mat-form-field>-->
                                <!--&lt;!&ndash;<label class="control-label ctrl-label text-normal text-extra-small" for="others">{{'IfOther'|translate}}:*</label>-->
                                <!--<input id="others" name="others" required placeholder="{{'AddOtherContentsHere'|translate}}" autocomplete="off" class="form-control input-md txtbox"-->
                                  <!--type="text" [(ngModel)]="claims.other">&ndash;&gt;-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="col-md-5 col-md-offset-1 ">-->
                <!--<div class="row">-->
                    <!--<div class="col-md-12 orange-font">-->
                        <!--<h4> {{'ClaimsInstructions.Instructions'|translate}} </h4>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-12 text-smaller ">-->
                        <!--<strong>{{'ClaimsInstructions.Step1'|translate}}.</strong>-->
                        <!--<p> {{'ClaimsInstructions.Step1_Msg'|translate}} </p>-->
                        <!--<strong>{{'ClaimsInstructions.Step2'|translate}}.</strong>-->
                        <!--<p> {{'ClaimsInstructions.Step2_Msg'|translate}} </p>-->
                        <!--<strong>{{'ClaimsInstructions.Additions'|translate}}:</strong>-->
                        <!--<p> {{'ClaimsInstructions.Additions_Msg'|translate}} </p>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</form>-->
</div>

<!--<a href="#claimsRequestedInfo"
   #claimsRequestedInfo
   role="button"
   data-toggle="modal"
   data-dismiss="modal"
   class="hidden">{{'Message'|translate}}</a>-->
<!-- Claims Requested Info -->
<!--<div class="modal "
     id="claimsRequestedInfo"
     tabindex="-1"
     role="dialog"
     aria-labelledby="Claims Requested Information"
     aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content popup-header">
            <div class="modal-body">
                {{'ClaimsRequest_Msg'|translate}}
            </div>
            <div class="modal-footer" style="text-align:center !important;">
                <button type="button" data-dismiss="modal" class="btn btn-sm btn-primary text-uppercase">
                    {{'OK'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>-->
<!-- /Claims Requested Info -->
<!--<a href="#invalidTrackingNumber"
   #invalidTrackingNumber
   role="button"
   data-toggle="modal"
   data-dismiss="modal"
   class="hidden">{{'Message'|translate}}</a>-->
<!-- Invalid Tracking Number Info -->
<!--<div class="modal "
     id="invalidTrackingNumber"
     tabindex="-1"
     role="dialog"
     aria-labelledby="Invalid Tracking Number Information"
     aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                {{'ClaimsRequestInvalidTrackingNumber'|translate}}
            </div>
            <div class="modal-footer" style="text-align:center !important;">
                <button type="button" data-dismiss="modal" class="btn btn-sm btn-primary text-uppercase">
                    {{'OK'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>-->
<!-- /Invalid Tracking Number Info -->

<ng-template #form>
    <form class="claim-form ups-form">
        <div class="form-body">
            <div class="form-sections">
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{'PackageInformation'|translate}}</h5>
                    </div>

                    <mat-form-field>
                        <input matInput
                               required
                               type="text"
                               name="trackingNumber"
                               placeholder="{{'TrackingNumber'|translate}}"
                               [(ngModel)]="claims.trackingnumber"
                               maxlength="50"
                               autocomplete="off"
                               pattern="[a-zA-Z0-9]+">
                    </mat-form-field>
                </div>
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{'ContactInformation'|translate}}</h5>
                    </div>

                    <mat-form-field>
                        <input matInput
                               required
                               type="text"
                               name="companyName"
                               placeholder="{{'CompanyName'|translate}}"
                               [(ngModel)]="claims.companyname"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               type="text"
                               name="accountNo"
                               readonly="true"
                               class="readonly-txtbox"
                               placeholder="{{'ParcelProAccountNumber'|translate}} *"
                               [(ngModel)]="claims.ParcelProAccount">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               required
                               type="text"
                               name="contactName"
                               placeholder="{{'ContactName'|translate}}"
                               [(ngModel)]="claims.contactname"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               required
                               type="text"
                               name="phoneNumber"
                               placeholder="{{'PhoneNumber'|translate}}"
                               [(ngModel)]="claims.contactphone"
                               pattern="\d*"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               type="text"
                               name="faxNumber"
                               placeholder="{{'FaxNumber'|translate}}"
                               [(ngModel)]="claims.contactfax"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               required
                               type="text"
                               name="email"
                               placeholder="{{'EmailAddress'|translate}}"
                               [(ngModel)]="claims.contactemail"
                               autocomplete="off">
                    </mat-form-field>
                </div>
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{'TypeOfInvestigation'|translate}}</h5>
                    </div>

                    <mat-form-field>
                        <mat-select required
                                    name="lossType"
                                    placeholder="Select Loss Type"
                                    [(ngModel)]="claims.typeofdamage">
                            <mat-option *ngFor="let item of lossTypes" value="{{item}}">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="claims.typeofdamage=='Others'">
                        <input matInput
                               required
                               type="text"
                               name="others"
                               placeholder="{{'AddOtherContentsHere'|translate}}"
                               [(ngModel)]="claims.other"
                               autocomplete="off">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <div class="form-actions">
                <button type="button" mat-raised-button (click)="SaveClaims()" class="page-action">
                    {{'Submit'|translate}}
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #instructions>
    <form class="claim-instructions ups-form">
        <div class="form-body">
            <div class="form-sections">
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">{{'ClaimsInstructions.Instructions'|translate}}</h5>
                    </div>
                    <ul class="steps">
                        <li class="step">
                        <p><strong> {{'ClaimsInstructions.Step1'|translate}}</strong></p>
                            {{'ClaimsInstructions.Step1_Msg'|translate}}
                        </li>
                        <li class="step">
                                <p><strong> {{'ClaimsInstructions.Step2'|translate}}</strong></p>
                            {{'ClaimsInstructions.Step2_Msg'|translate}}
                        </li>
                        <!--<li class="step">{{'ClaimsInstructions.Additions_Msg'|translate}}</li>-->
                    </ul>
                    <div class="additional">
                        <p><strong>{{'ClaimsInstructions.Additions'|translate}}</strong></p>
                        <p>{{'ClaimsInstructions.Additions_Msg'|translate}}
                            </p>
                    </div>
                    <div class="actions">
                        <button type="button" mat-raised-button (click)="openInvestigationForm()" class="form-action">
                            {{'Submit'|translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="form-footer">
            <div class="form-actions">
               <button type="button" mat-raised-button (click)="SaveClaims()" class="form-action">
                {{'Submit'|translate}}
            </button>
            </div>
        </div>-->
    </form>
</ng-template>