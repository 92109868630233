import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { Observable } from 'rxjs';
import { IScheduledPickup } from '../../shared/models/scheduled-pickup.interface';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class SchedulePickupService {

  constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {

  }

  /**
   * Gets scheduled pickups.
   *
   * @returns {Observable<IScheduledPickup[]>}
   */
  public getScheduledPickups(keyword: string): Observable<IScheduledPickup[]> {
    const params = {};
    if (keyword) {
      params['q'] = keyword;
    }
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getScheduledPickups'))
      .query(params)
      .toString();
    return this.httpClientService.get<IScheduledPickup[]>(endpoint);
  }


  /**
   * Deletes a schedule-pickup quote.
   *
   * @param {string} quoteId
   * @returns {Observable<any>}
   */
  public deleteSchedulePickupQuote(quoteId: string): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('deleteScheduledPickupQuote'))
      .param('quoteId', quoteId)
      .toString();
    return this.httpClientService.delete<any>(endpoint);
  }
}
