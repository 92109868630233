import { Injectable } from '@angular/core';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { IReportShipmentUS } from '../report-history/report-shipment-us-dialog/models/report-shipment-us.interface';
import { IReportShipmentEU } from '../report-history/report-shipment-eu-dialog/models/report-shipment-eu.interface';
import { Subject, Observable, map } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { IReportService } from '../models/report-service.interface';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { IReportedShipmentFileUpload } from 'app/shared/models/shipments/reported-shipment-file-upload.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportShipmentService {
  public cancelRecentUploadSubmission$ = new Subject<number>();
  public saveProgress$ = new Subject();
  public deleteRow$ = new Subject<number>();
  public submitForInsurance$ = new Subject<boolean>();
  public rowAfterEdit$ = new Subject<{ data: IReportShipmentEU, isValid: boolean }>();
  public dataSubmitted$ = new Subject<{ data: IReportShipmentEU[], isFinal: boolean, hasErrors: boolean }>();
  public fileDownloaded$ = new Subject<{ file: File, fileId: number }>();
  public downloadAndSubmitFile$ = new Subject<number>();
  public deleteFile$ = new Subject();
  public backToUpload$ = new Subject();
  public showHeaders$ = new Subject<boolean>();
  public submitFileResult$ = new Subject<{ fileId: number; success: boolean }>();

  public constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {
  }

  public saveReportShipmentUS(reportShipments: IReportShipmentUS[]): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('reportShipmentUS'))
      .toString();
    return this.httpClientService.post<any>(endpoint, reportShipments);
  }

  public saveReportShipmentEU(reportShipment: IReportShipmentEU): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('reportShipmentEU'))
      .toString();
    return this.httpClientService.post<any>(endpoint, reportShipment);
  }

  public saveBulkReportShipmentEU(reportShipments: IReportShipmentEU[]): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('bulkReportShipmentEU'))
      .toString();
    return this.httpClientService.post<any>(endpoint, reportShipments);
  }

  public validateTrackingNumber(trackingNumber: string): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('validateTrackingNumber'))
      .param('trackingNumber', trackingNumber)
      .toString();

    return this.httpClientService.get<any>(endpoint);
  }

  public validateTrackingNumbers(trackingNumbers: string[]): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('validateTrackingNumbers'))
      .toString();
    return this.httpClientService.post<any>(endpoint, trackingNumbers);
  }

  public getReportServices(): Observable<IReportService[]> {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getReportServices'))
      .toString();

    return this.httpClientService.get<IReportService[]>(endpoint);
  }

  public uploadFile(file: File, currentTime: Date, numberOfShipments: number, status: string, hasErrors: boolean): Observable<any> {
    const endpoint = this.apiEndpointService.getEndpoint('uploadFile');
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('numberOfShipments', numberOfShipments.toString());
    formData.append('status', status);
    formData.append('importedOnUserTime', currentTime.toISOString());
    formData.append('hasErrors', hasErrors.toString());

    return this.httpClientService.post<any>(endpoint, formData);
  }

  public updateFile(fileId: number, file: File, numberOfShipments: number, status: string, hasErrors: boolean): Observable<any> {
    const endpoint = this.apiEndpointService.getEndpoint('updateFile');
    const formData = new FormData();
    formData.append('fileId', fileId.toString());

    if (file) {
      formData.append('file', file, file.name);
    }

    formData.append('numberOfShipments', numberOfShipments.toString());
    formData.append('status', status);
    formData.append('hasErrors', hasErrors.toString());

    return this.httpClientService.post<any>(endpoint, formData);
  }

  public getReportedShipmentFileUploads(): Observable<IReportedShipmentFileUpload[]> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getFileUploads'))
      .toString();
    return this.httpClientService.get<IReportedShipmentFileUpload[]>(endpoint).pipe(
      map(fileUploads =>
        fileUploads.map(upload => ({
          ...upload,
          ImportedOn: new Date(upload.ImportedOn), // To use proper time zone
        }))
      )
    );
  }

  public getFileContent(fileId: number): Observable<File> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getFileContent'))
      .param('id', fileId.toString())
      .toString();

    return this.httpClientService.get<any>(endpoint).pipe(
      map(response => {
        const fileName = response.FileName || response.fileName;
        const fileContentBase64 = response.FileContent || response.fileContent;
        const mimeType = this.getMimeType(fileName);

        const file = this.base64ToFile(fileContentBase64, fileName, mimeType);
        return file;
      })
    );
  }

  private base64ToFile(base64Data: string, fileName: string, mimeType: string = ''): File {
    // Convert base64 string to array buffer
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  }

  private getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      default:
        return 'application/octet-stream';
    }
  }
}
