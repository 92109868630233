<div class="recent-shipments white-content-page">
    <upsc-page-header [pageTitle]="'MyRecentShipments'|translate">
        <div class="refresh" page-actions>
            <button type="button"
                    mat-raised-button
                    class="secondary-button alternative-button action text"
                    (click)="GetHighvalueShipments(false)">
                    {{'refreshPage'|translate}}
            </button>
            <label class="action icon" (click)="GetHighvalueShipments(false)">
                <img src="../../../assets/images/refresh_button.png" alt="refresh"/>
            </label>
            <!-- <button type="button"
                    mat-raised-button
                    class="secondary-button alternative-button action icon"
                    (click)="GetHighvalueShipments()" >
                    <mat-icon>replay</mat-icon>
            </button> -->
        </div>
    </upsc-page-header>
    <div class="ups-content-block content-actions">
        <div class="left-actions">
            <div class="notice">
                <span [innerHtml]="'RecentShipmentInfo'|translate: {hours: filterLastHours}"></span>
            </div>
        </div>
        <div class="right-actions">
        </div>
    </div>
    <div class="ups-content-block">
        <div class="off-hours-message">
            <mat-icon>warning</mat-icon>
            <span class="message" [innerHtml]="'pendingShipments.offHoursMessage'|translate"></span>
        </div>
    </div>

    <!--<upsc-responsive-table *ngIf="false"-->
    <!--[columns]="pendingShipmentColumns"-->
    <!--[items]="pendingShipmentItems"-->
    <!--(rowActionClicked)="onPendingShipmentActionClicked($event)"></upsc-responsive-table>-->

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive" *ngIf="!dontShowHighvalueGrid">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!filteredHighvalueShipmentList">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th [class.hide-me]="!isShowQuoteId">Quote ID</th>
                <th>
                    {{'Carrier' | translate}}
                </th>
                <th>
                    {{'ShipDate'|translate}}
                </th>
                <th>
                    {{'ShipTo'|translate}}
                </th>
                <th>
                    {{'PostalCode'|translate}}
                </th>
                <th>
                    {{'Coverage'|translate}}
                </th>
                <th>
                    {{'Status'|translate}}
                </th>
                <th class="text-center all"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of filteredHighvalueShipmentList">
                <td class="trigger-cell"></td>
                <td [class.hide-me]="!isShowQuoteId">{{ item.QuoteId }}</td>
                <td> {{ item.CarrierCode | carrier }}</td>
                <td> {{ item.ShipDate | date : 'MM-dd-yyyy' }}</td>
                <td>
                    <span *ngIf="item.ShipTo.CompanyName!=''">{{ item.ShipTo.CompanyName }}</span>
                    <span *ngIf="item.ShipTo.CompanyName==''">
                                {{ item.ShipTo.FirstName + ' ' + item.ShipTo.LastName }}
                            </span>
                </td>
                <td> {{ item.ShipTo.Zip }}
                </td>
                <td><strong> {{ item.InsuredValue | number : '1.2-2' }} USD </strong>
                </td>
                <td>
                    <span class="status" *ngIf="item.Status==11">
                        <button type="button" class="btn btn-bluebullet"></button>
                        <!--{{item.Status | highvalueshipmentstatus | translate }}-->
                        <span>{{item.Status | highvalueShipmentStatus  }}</span>
                    </span>
                    <span class="status" *ngIf="item.Status==10||item.Status==12 || item.Status==0 || item.Status==1 ">
                        <button type="button" class="btn btn-yellowbullet"></button>
                        <span>{{item.Status | highvalueShipmentStatus }}</span>
                    </span>
                    <span class="status" *ngIf="item.Status==2">
                        <button type="button" class="btn btn-greenbullet"></button>
                        <span>{{item.Status | highvalueShipmentStatus  }}</span>
                    </span>
                    <!-- [MV3-4175] Tooltip content changed -->
                    <span *ngIf="item.Status==3"
                          class="status declined"
                          matTooltip="{{ item.Notes || 'N/A' }}"
                          [matTooltipPosition]="'above'">
                        <button type="button" class="btn btn-darkredbullet"></button>
                        <span>{{item.Status | highvalueShipmentStatus }}</span>
                        <mat-icon>warning</mat-icon>
                    </span>
                </td>
                <td class="text-right">
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                (click)="confirmShipment($event, item)"
                                *ngIf="item.Status==2 || item.Status==11">
                            {{'Print'|translate}}
                        </button>
                        <button type="button"
                                mat-raised-button
                                class="alternative-button"
                                (click)="confirmQuoteCancellation(item.QuoteId)"
                                *ngIf="item?.Status === 11">
                            {{'Cancel'|translate}}
                        </button>
                    </div>
                    <!--<a href="#cancelquoteconfirmation" role="button" class="btn btn-default btn-grey btn-xs text-uppercase" data-toggle="modal" (click)="cancelQuoteId=item.QuoteId"
                        *ngIf="item.Status==2 || item.Status==10 || item.Status==11 || item.Status==12" >{{'Cancel'|translate}}</a>-->
                    <!--<a href="javascript:;" role="button" class="btn btn-default btn-darkgrey btn-xs text-uppercase" (click)="printWindow(item.QuoteId)"
                        *ngIf="item.Status==2 || item.Status==11" >{{'Print'|translate}}</a>-->
                </td>
            </tr>
            </tbody>
        </table>
        <upsc-element-blocker *ngIf="isLoadingShipments"></upsc-element-blocker>
    </div>

    <!-- <upsc-cancel-quote-info></upsc-cancel-quote-info> -->
</div>
