import { Pipe, PipeTransform } from '@angular/core';
import { LocaleNumberPipe } from '../locale-number/locale-number.pipe';
import { User } from '../../services/user/models/user.model';
import { UserService } from '../../services/user/user.service';

@Pipe({ name: 'userLocaleNumber' })
export class UserLocaleNumberPipe implements PipeTransform {
  constructor(private userService: UserService,
              private localeNumberPipe: LocaleNumberPipe) {
  }

  public transform(value: number, user: User, minimumFractionDigits = 2): string {
    let locale = 'en-US';

    if (!user) {
      return this.localeNumberPipe.transform(0, minimumFractionDigits, locale);
    }

    switch (true) {
      case this.userService.isDEUser(user):
        locale = 'de-DE';
        break;
      case this.userService.isGBUser(user):
        locale = 'en-GB';
        break;
      default:
        locale = 'en-US';
        break;
    }

    return this.localeNumberPipe.transform(value, minimumFractionDigits, locale);
  }
}
