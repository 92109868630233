import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { AppState } from '../../app.state';
import { NotificationType } from '../../shared/models/notification-type';
import { IScheduledPickup } from '../../shared/models/scheduled-pickup.interface';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { UserService } from '../../shared/services/user/user.service';
import { SchedulePickupService } from '../services/schedule-pickup.service';
import { SiteConfig } from 'config/site-config';
import { DataTableDirective } from 'angular-datatables';

@Component({
    selector: 'upsc-schedule-pickup',
    templateUrl: './schedule-pickup.component.html',
    styles: [],
})
export class SchedulePickupComponent implements OnInit, OnDestroy {
    public searchFormGroup: UntypedFormGroup;
    public noRecordFound: boolean = false;
    public schedulePickupList: IScheduledPickup[];
    public filteredSchedulePickupList: IScheduledPickup[];
    public quoteId: string;
    public customMessage: string;
    public CancelPickupWarning: string = 'Are you sure you want to cancel this pickup?';
    public isEUUser: boolean;

    public dtOptions: any = {};
    public dtTrigger = new Subject();

    @ViewChild('CustomInformation') customInfo: ElementRef;
    @ViewChild('wrapper') wrapper: ElementRef;

    @ViewChild(DataTableDirective, {static: false})
    public dtElement: DataTableDirective;

    private isEmpty = true;

    public constructor(private schedulepickupservice: SchedulePickupService,
                       private userService: UserService,
                       private dialogService: DialogService,
                       private notificationService: NotificationService,
                       private translate: TranslateService,
                       private formBuilder: UntypedFormBuilder,
                       private readonly appState: AppState,
    ) {
        this.GetUserCountryCode();
        this.GetSchedulePickups('');

        this.translate.onLangChange.subscribe(() =>{
            this.updateDTOptionsLanguage();
        })
    }

    public ngOnInit() {
        this.dtOptions = SiteConfig.tableConfig;
        this.dtOptions.language = {
            emptyTable: this.translate.instant('dashboard.noShipments'),
          };

        this.searchFormGroup = this.formBuilder.group({
            keyword: [''],
        });

        this.searchFormGroup.controls.keyword.valueChanges.subscribe(value => {
            if (!value) {
                this.GetSchedulePickups(value);
            }
        });
    }


    public ngOnDestroy() {
        this.dtOptions.searching = false;
    }

    onMenuToggle() {
        if (!this.wrapper.nativeElement.classList.contains('toggled')) {this.wrapper.nativeElement.className = this.wrapper.nativeElement.className + ' toggled';} else {this.wrapper.nativeElement.classList.remove('toggled');}
    }

    public GetSchedulePickups(keyword: string = '') {
        this.schedulepickupservice.getScheduledPickups(keyword).subscribe(
            (pickups) => {
                this.schedulePickupList = pickups;

                if (!pickups) {
                    this.isEmpty = true;
                    this.filteredSchedulePickupList = [];
                    this.dtTrigger.next(null);
                    return;
                }

                if(pickups.length > 0)
                    this.isEmpty = false;
                else
                    this.isEmpty = true;

                this.filteredSchedulePickupList = _.cloneDeep(pickups.filter(item => moment(item.PickupDate)
                    .isSameOrAfter(moment(), 'day')));
                if (this.schedulePickupList && this.schedulePickupList.length === 0) {this.noRecordFound = true;} else {this.noRecordFound = false;}
                this.dtTrigger.next(null);
            },
            (_error) => {
                this.dtTrigger.next(null);
            });
    }

    confirmVoidPickup(quoteId: string) {
        this.dialogService.confirm('Void Confirmation', this.CancelPickupWarning).subscribe(isConfirmed => {
            if (isConfirmed) {this.voidPickup(quoteId);}
        });
    }

    voidPickup(quoteId: string) {
        this.schedulepickupservice.deleteSchedulePickupQuote(quoteId).subscribe(_response => {
            this.notificationService.notify('Pickup successfully canceled.', 'Voided Successfully', NotificationType.SUCCESS);
            const keyword = this.searchFormGroup.controls['keyword'].value;
            this.GetSchedulePickups(keyword);
        });
    }

    public onSearchSubmit(event, form) {
        event.preventDefault();
        this.GetSchedulePickups(form.keyword);
    }

    public updateDTOptionsLanguage(): void{
        this.dtOptions.language = {
                emptyTable: this.translate.instant('dashboard.noShipments')
            };
    
        this.rerender();
    }

    private rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            if(this.isEmpty) {
                dtInstance.destroy();
            }
            // Call the dtTrigger to rerender again
            this.dtTrigger.next(null);
        });
      }

    private GetUserCountryCode() {
        const user = this.appState.user$();
        this.isEUUser = this.userService.isEUUser(user?.CountryCode);
    }
}
