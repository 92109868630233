import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recentShipmentStatus'
})
export class RecentShipmentStatusPipe implements PipeTransform {

 transform(value: any): any {
        switch (value) {
            case 0: return 'BIR';
            case 1: return 'Manifest';
            case 2: return 'Pickup Scan';
            case 3: return 'Voided';
            case 4: return 'Printed';
            case 5: return 'In Transit';
            case 6: return 'Delivered';
            case 7: return 'Exception';
            case 14: return 'Reinstated';
            default: '';
        }
    }

}
