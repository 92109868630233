import { Component, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../shared/services/user/user.service';
import { Claims } from '../models/claims.model';
import { ClaimService } from '../services/claim.service';
import { EmailService } from '../../shared/services/email/email.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { InvestigationFormComponent }from './investigation-form/investigation-form.component';

@Component({
  selector: 'upsc-claims',
  templateUrl: './package-investigation.component.html',
  styleUrls: ['./package-investigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PackageInvestigationComponent {
  
  //@ViewChild('claimsForm') claimsForm: any;
  //@ViewChild('claimsRequestedInfo') claimsRequestedInfo: ElementRef;
  //@ViewChild('invalidTrackingNumber') invalidTrackingNumber: ElementRef;

  constructor(public claims: Claims,
    private userservice: UserService,
    private claimservice: ClaimService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private emailService: EmailService) {
    
  }

  openInvestigationForm() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '80%',
      height:'70%',
      data: {
      //  investigationData: this.claims
      }
    };
    let dialogRef: MatDialogRef<InvestigationFormComponent> = this.dialog.open(InvestigationFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }

  

  /* No longer used 
  SaveClaims() {
    if (this.validateForm()) {
      if (this.claimsForm.controls.lossType.value == "Others") //If the loss type is others, populate the other's content in damagetype to post the content.
        this.claims.typeofdamage = this.claims.other;
      this.claimservice.submitClaim(this.claims).subscribe(response => {
        if (response.status == 200) {
          this.notificationService.notify('', 'THANK YOU FOR YOUR REQUEST. A CLAIMS ASSOCIATE WILL BE CALLING TO ASSIST YOU AS SOON AS POSSIBLE.', NotificationType.SUCCESS);

        }
        else if (response.json().code.toString() == '400' && response.json().message.toString().toLowerCase() == 'tracking number provided is not on our records. please call customer support for assistance.')
          this.notificationService.notify('', 'TRACKING NUMBER PROVIDED IS NOT ON OUR RECORDS. PLEASE CALL CUSTOMER SUPPORT FOR ASSISTANCE.', NotificationType.ERROR);
        this.GetUser();
      }, error => {
        this.notificationService.notify('', 'Error saving claims', NotificationType.ERROR);
      });
      //reset the values

    }
  } */

  // Submit claims API will take care of email 
  // sendClaimsEmail() {
  //   this.emailService.SendClaimsEmail(this.claims.contactemail, 'spulla@parcelpro.com', JSON.stringify(this.claims)).subscribe(response => {
  //   }, error => {
  //     this.notificationService.notify('', 'Error Sending Email', NotificationType.ERROR);
  //   });
  // }

  /* no longer needed 
  validateForm(): boolean {
    if (this.claimsForm.controls.trackingNumber.status == 'INVALID')
    { this.notificationService.notify('Please Enter Valid Tracking Number', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.companyName.status == 'INVALID')
    { this.notificationService.notify('Please Enter Valid Company Name', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.contactName.status == 'INVALID')
    { this.notificationService.notify('Please Enter Valid Contact Name', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.phoneNumber.status == 'INVALID')
    { this.notificationService.notify('Please Enter Valid Phone Number', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.email.status == 'INVALID')
    { this.notificationService.notify('Please Enter Valid Email Address', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.lossType.status == 'INVALID' || this.claimsForm.controls.lossType.value == "0")
    { this.notificationService.notify('Please Select Valid Loss Type', 'Validation Failed', NotificationType.ERROR); }
    else if (this.claimsForm.controls.lossType.value == "Others" && this.claimsForm.controls.others.status == 'INVALID')
    { this.notificationService.notify('Please Enter Comments for Other Type of Loss', 'Validation Failed', NotificationType.ERROR); }
    else return true;
  } */

  

}
