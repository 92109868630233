import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highvalueShipmentStatus',
})
export class HighvalueShipmentStatusPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 0:
        return 'Pending';
      case 1:
        return 'Assigned';
      case 2:
        return 'Approved';
      case 3:
        return 'Declined';
      case 10:
        return 'Waiting for AES';
      case 11:
        return 'EEI Received';
      case 12:
        return 'Pending'; // 'Pending for Correction';
      default:
        return value;
    }
  }

}
