<div class="saved-quote-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ 'QuotePreview' | translate }}</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="preview" [ngTemplateOutletContext]="{ quote: data.quote }"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #preview let-quote="quote">
    <upsc-ship-details [shipmentPackage]="quote"></upsc-ship-details>
</ng-template>
