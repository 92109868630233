<div class="schedule-pickup white-content-page">
    <upsc-page-header [pageTitle]="'siteMenu.track.scheduledPickups'|translate"></upsc-page-header>
    <div class="ups-content-block content-actions">
        <div class="left-actions"></div>
        
        <div class="right-actions">
            <form [formGroup]="searchFormGroup" (ngSubmit)="onSearchSubmit($event, searchFormGroup.value)" class="action">
                <mat-form-field>
                    <mat-label>{{'searchShipment'|translate}}</mat-label>
                    <input matInput type="text" formControlName="keyword">
                    <mat-icon matSuffix (click)="onSearchSubmit($event, searchFormGroup.value)" class="search">search</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!filteredSchedulePickupList">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th>
                    {{'Carrier'|translate}}
                </th>
                <th>
                    {{'PickupDate'|translate}}
                </th>
                <th *ngIf="isEUUser">
                    {{'ShipToCountry'|translate}}
                </th>
                <th>
                    {{'ShipTo'|translate}}
                </th>
                <th>
                    {{'Zipcode'|translate}}
                </th>
                <th>
                    {{'DispatchInformation'|translate}}
                </th>
                <th *ngIf="isEUUser">
                    {{'InsuredValue'|translate}}
                </th>
                <th>
                    {{'Status'|translate}}
                </th>
                <th class="all"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of filteredSchedulePickupList">
                <td class="trigger-cell"></td>
                <td class="text-uppercase"> {{item.CarrierCode}}
                </td>
                <td>
                    {{item.PickupDate | date : 'MM-dd-yyyy'}}
                </td>
                <td *ngIf="isEUUser">
                    <span *ngIf="item.ShipToCountry!==''">{{item.ShipToCountry}}</span>
                </td>
                <td>
                    <span *ngIf="item.ShipToCompanyName!==''">{{item.ShipToCompanyName}}</span>
                    <span *ngIf="item.ShipToCompanyName===''">{{item.ShipToName}}</span>
                </td>
                <td>
                    {{item.ShipToZip}}
                </td>                
                <td>{{item.DispatchConfirmationNumber}}
                </td>
                <td *ngIf="isEUUser">
                    {{item.InsuredValue}}
                </td>
                <td>
                                        <span *ngIf="item.DispatchStatus===0">
                                            <button role="button"
                                                    class="btn btn-transparentbullet"
                                                    style="background-color:#3fa700;"></button>
                                            {{'Active'|translate}}
                                        </span>
                    <span *ngIf="item.DispatchStatus===1">
                                            <button role="button" class="btn btn-redbullet"></button>
                                            {{'Cancelled'|translate}}
                                        </span>
                </td>
                <td>
                    <!--<a href="#customConfirmation"
                       role="button"
                       data-toggle="modal"
                       data-dismiss="modal"
                       mat-raised-button
                       class="alternative-button"
                       (click)="quoteId=item.QuoteId"
                       *ngIf="item.DispatchStatus==0"
                       > {{'Void'|translate}} </a>-->

                    <div class="actions">
                        <button mat-raised-button
                                *ngIf="item.DispatchStatus===0"
                                class="alternative-button"
                                (click)="confirmVoidPickup(item.QuoteId)">{{'Void'|translate}}
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<!--<upsc-custom-confirmation [CustomMessage]="CancelPickupWarning"
                          [Id]="quoteId"
                          (notify)="onSuccess($event)"></upsc-custom-confirmation>
<upsc-custom-info [CustomMessage]="customMessage"></upsc-custom-info>
<a href="#customInfo" #CustomInformation role="button" data-toggle="modal" data-dismiss="modal" class="btn hidden">Custom
    Message</a>-->