import { NgModule } from '@angular/core';
import { UserLocaleNumberPipe } from './user-locale-number.pipe';
import { LocaleNumberPipe } from '../locale-number/locale-number.pipe';
import { UserGBDELocaleNumberPipe } from './user-gbde-locale-number.pipe';

@NgModule({
  imports: [],
  declarations: [UserLocaleNumberPipe,UserGBDELocaleNumberPipe],
  providers: [LocaleNumberPipe,],
  exports: [UserLocaleNumberPipe,UserGBDELocaleNumberPipe],
})
export class UserLocaleNumberModule {
}
