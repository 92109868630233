import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'upsc-bulk-upload-tips-tricks',
    templateUrl: './tips-tricks.component.html',
    styleUrls: ['./tips-tricks.component.scss'],
    standalone: true,
    providers: [],
    imports: [
        CommonModule,
        NgOptimizedImage,
        TranslateModule,
    ],
})
export class TipsTricksComponent {
    @HostBinding('class') public hostClass = 'bulk-upload-tips-tricks';
}
