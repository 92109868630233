import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'upsc-saved-quote-dialog',
  templateUrl: './saved-quote-dialog.component.html',
  styleUrls: ['./saved-quote-dialog.component.scss'],
})
export class SavedQuoteDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SavedQuoteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit() {
  }
}
