import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ShipDetailsActionsModule } from '../shared/components/ship-details-actions/ship-details-actions.module';
import { ShipDetailsModule } from '../shared/components/ship-details/ship-details.module';
import { UserCurrencyModule } from '../shared/pipes/user-currency/user-currency.module';
import { UserCurrencyPipe } from '../shared/pipes/user-currency/user-currency.pipe';
import { UserLocaleNumberModule } from '../shared/pipes/user-locale-number/user-locale-number.module';
import { ValidationService } from '../shared/services/validation/validation.service';
import { SharedModule } from '../shared/shared.module';
import { ShipmentService } from '../ship/services/shipment.service';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { ChargeCreditCardComponent } from './billing-history/charge-credit-card/charge-credit-card.component';
import { BirComponent } from './bir/bir.component';
import { Claims } from './models/claims.model';
import { ShipmentHistoryUserFields } from './models/shipment-history-user-fields';
import { InvestigationFormComponent } from './package-investigation/investigation-form/investigation-form.component';
import { PackageInvestigationComponent } from './package-investigation/package-investigation.component';
import { CarrierPipe } from './pipes/carrier.pipe';
import { ClaimStatusPipe } from './pipes/claim-status.pipe';
import { HighvalueShipmentStatusPipe } from './pipes/highvalue-shipment-status.pipe';
import { PackagesPipe } from './pipes/packages.pipe';
import { RecentShipmentStatusPipe } from './pipes/recent-shipment-status.pipe';
import { ServicesPipe } from './pipes/services.pipe';
import { ShipmentHistoryDatePipe } from './pipes/shipment-history-date.pipe';
import { ShipmentStatusBarPipe } from './pipes/shipment-status-bar.pipe';
import { ShipmentStatusPercentPipe } from './pipes/shipment-status-percent.pipe';
import { ShipmentStatusPipe } from './pipes/shipment-status.pipe';
import { CancelQuoteInfoComponent } from './recent-shipment/cancel-quote-info/cancel-quote-info.component';
import { RecentShipmentComponent } from './recent-shipment/recent-shipment.component';
import { ReportsDropdownMenuComponent } from './recent-shipment/reports-dropdown-menu/reports-dropdown-menu.component';
import { CustomDatePipe } from './report-history/datepipe.pipe';
import { DaysDropdownMenuComponent } from './report-history/days-dropdown-menu/days-dropdown-menu.component';
import { ReportHistoryComponent } from './report-history/report-history.component';
import {
    ReportShipmentEuDialogComponent,
} from './report-history/report-shipment-eu-dialog/report-shipment-eu-dialog.component';
import {
    ReportShipmentUsDialogComponent,
} from './report-history/report-shipment-us-dialog/report-shipment-us-dialog.component';
import {
    TrackingDetailsDialogComponent,
} from './report-history/tracking-details-dialog/tracking-details-dialog.component';
import { TrackingDetailsComponent } from './report-history/tracking-details/tracking-details.component';
import { SavedQuoteDialogComponent } from './saved-quotes/saved-quote-dialog/saved-quote-dialog.component';
import { SavedQuotesComponent } from './saved-quotes/saved-quotes.component';
import { SchedulePickupComponent } from './schedule-pickup/schedule-pickup.component';

import { BillingHistoryService } from './services/billing-history.service';
import { ClaimService } from './services/claim.service';
import { RecentShipmentService } from './services/recent-shipment.service';
import { ReportHistoryService } from './services/report-history.service';
import { ReportShipmentService } from './services/report-shipment.service';
import { SavedQuotesService } from './services/saved-quotes.service';
import { SchedulePickupService } from './services/schedule-pickup.service';
import { ShipmentHistoryService } from './services/shipment-history.service';
import { ShipmentDetailComponent } from './shipment-detail/shipment-detail.component';
import { ShipmentHistoryComponent } from './shipment-history/shipment-history.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        ShipDetailsModule,
        UserCurrencyModule,
        UserLocaleNumberModule,
        ShipDetailsActionsModule,
    ],
    declarations: [
        BillingHistoryComponent,
        BirComponent,
        CancelQuoteInfoComponent,
        CarrierPipe,
        ChargeCreditCardComponent,
        DaysDropdownMenuComponent,
        HighvalueShipmentStatusPipe,
        InvestigationFormComponent,
        PackageInvestigationComponent,
        PackagesPipe,
        RecentShipmentComponent,
        RecentShipmentStatusPipe,
        ReportHistoryComponent,
        ReportsDropdownMenuComponent,
        ReportShipmentEuDialogComponent,
        ReportShipmentUsDialogComponent,
        SavedQuoteDialogComponent,
        SavedQuotesComponent,
        SchedulePickupComponent,
        ServicesPipe,
        ShipmentDetailComponent,
        ShipmentHistoryComponent,
        ShipmentHistoryDatePipe,
        ShipmentStatusBarPipe,
        ShipmentStatusPercentPipe,
        ShipmentStatusPipe,
        TrackingDetailsComponent,
        TrackingDetailsDialogComponent,
        CustomDatePipe,
        ClaimStatusPipe,
    ],
    providers: [
        BillingHistoryService,
        CarrierPipe,
        Claims,
        ClaimService,
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        HighvalueShipmentStatusPipe,
        PackagesPipe,
        RecentShipmentService,
        RecentShipmentStatusPipe,
        ReportHistoryService,
        ReportShipmentService,
        SavedQuotesService,
        SchedulePickupService,
        ServicesPipe,
        ShipmentHistoryDatePipe,
        ShipmentHistoryService,
        ShipmentHistoryUserFields,
        ShipmentService,
        ShipmentStatusBarPipe,
        ShipmentStatusPercentPipe,
        ShipmentStatusPipe,
        UserCurrencyPipe,
        ValidationService, ClaimStatusPipe,
    ],
    exports: [
        ServicesPipe,
    ]
})
export class HistoryModule {
}
