import { Component, Input } from '@angular/core';

@Component({
  selector: 'upsc-tracking-details',
  templateUrl: './tracking-details.component.html',
  styles: []
})
export class TrackingDetailsComponent {
  @Input() trackingShipmentDetail;

  constructor() { }



}
