<!-- Review Errors Header Section -->
<section class="review-errors-container">
    <div class="ups-page-header">
        <h1 class="page-title" [innerHTML]="'Bulk Upload'"></h1>
    </div>

    <div class="review-errors-header" *ngIf="!isCompleted">
        <h2> Shipments Details </h2>
        <p> Please review the data uploaded. </p>
    </div>
    <div class="review-errors-header" *ngIf="isCompleted">
        <h2> Submission Complete </h2>
    </div>
    <div class="review-errors-progress-bar-container" [class.review-errors-success]="isCompleted">
        <div class="progress-bar-wrapper">
            <img class="indicator-icon start-icon" src="../../assets/icons/Indicator.svg" alt="indicator-icon"
                height="10" width="10">
            <span class="start-span"> Upload File </span>
        </div>
        <div class="progress-bar-wrapper">
            <img class="indicator-icon middle-icon" *ngIf="!isCompleted"
                src="../../assets/icons/Indicator (open circle).svg" alt="indicator-icon" height="17" width="17">
            <img class="indicator-icon middle-icon-done" *ngIf="isCompleted" src="../../assets/icons/Indicator.svg"
                alt="indicator-icon" height="10" width="10">
            <span class="middle-span"> Review Data </span>
        </div>
        <div class="progress-bar-wrapper">
            <img class="indicator-icon end-icon" *ngIf="!isCompleted"
                src="../../assets/icons/Indicator (gray open circle).svg" alt="indicator-icon" height="10" width="10">
            <img class="indicator-icon end-icon" *ngIf="isCompleted"
                src="../../assets/icons/Indicator.svg" alt="indicator-icon" height="17" width="17">
            <span class="end-span"> Submission Complete </span>
        </div>
    </div>

    <div *ngIf="isError && !isCompleted && !showEditForm" class="review-errors-message">
        <img class="error-icon" src="../../assets/icons/Error_16x16.svg" alt="error-icon" height="13" width="13">
        <p> {{ errorMessage }}
        <p>
    </div>

    <div *ngIf="!isError && !isCompleted" class="no-errors-message">
        <img class="error-icon" src="../../assets/icons/CheckCircle_16x16.svg" alt="check-circle-icon" height="13"
            width="13">
        <p> {{ errorMessage }}</p>
    </div>

    <div class="action-toolbar" *ngIf="!showEditForm && !isCompleted">
        <mat-button-toggle-group class="show-errors-toggle-slide">
            <mat-button-toggle value="all" [checked]="true" (click)="showAll()"> Show All </mat-button-toggle>
            <mat-button-toggle value="errors" (click)="showErrors()"> Show Errors </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</section>

<!-- Review Errors Table Section -->
<section class="review-errors-content" *ngIf="!showEditForm && !isCompleted">
    <div class="review-errors-grid-container">
        <span class="helper-text">Click pencil icon to view and edit shipment details</span>
        <div class="grid-wrapper" [class.show-edit]="isShowEdit">
            <table class="ups-table">
                <thead>
                    <tr class="ups-table-rows">
                        <th *ngFor="let header of tableHeaders; let first=first;"
                            [ngClass]="{'more-padding-left': first, 'more-min-width': first}">
                            {{header.label}}
                        </th>
                    </tr>
                </thead>
                <tbody #tb>
                    <tr class="my-tr"
                        *ngFor="let item of shipmentReportsReviewsData | paginate:paginate; let first=first; let i=index;">
                        <div class="street-container">
                            <div class="icon-container">
                                <img [src]="getIcon(item)" (mouseenter)="showErrorOverlay(item?.Index)"
                                    (mouseleave)="hideErrorOverlay(i)">
                                <div class="show-errors-container" *ngIf="isErrorsVisible(item?.Index)"
                                    [class.show-above]="isLastTwoRows(item?.Index)">
                                    <svg class="polygon-container" height="17" width="100">
                                        <polygon class="polygon-1" points="50,0 0,100 100,100" />
                                    </svg>

                                    <div class="show-errors-panel">
                                        <div class="show-errors-wrapper">
                                            <p><img src="../../assets/icons/Error_16x16.svg" class="error-icon"
                                                    height="15" width="15"> {{
                                                getErrorMessages(item.Index).length }} Error(s) Found</p>
                                            <ul>
                                                <li *ngFor="let error of getErrorMessages(item.Index)">{{ error }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="img-wrapper">
                                    <img class="cursor-pointer" src="../../assets/icons/trashcan.svg"
                                        alt="trashcan-icon" (click)="deleteRecords(item.Index, false)" height="13"
                                        width="12">
                                </div>

                                <div class="img-wrapper">
                                    <img class="cursor-pointer" src="../../assets/icons/edit.svg" alt="edit-icon"
                                        (click)="showEdit(item?.Index)" height="13" width="13" alt="edit-icon">
                                </div>
                            </div>
                            <td class="more-padding-left">
                                <span *ngIf="item?.ReportShipment.ShipDate; else invalidDate">
                                    {{ item?.ReportShipment.ShipDate | date: this.tableDateFormat }}
                                </span>
                                <ng-template #invalidDate>
                                    <span class="invalid-date">Invalid date</span>
                                </ng-template>
                            </td>
                        </div>
                        <td> {{ item?.ReportShipment.Carrier}} </td>
                        <td> {{ item?.ReportShipment.Service | services }} </td>
                        <td> {{ item?.ReportShipment.TrackingNumber }} </td>
                        <td> {{ item?.ReportShipment.Coverage }} </td>
                        <td> {{ (item?.ReportShipment.ShipToProvince && item?.ReportShipment.ShipToProvince !== '' &&
                            item?.ReportShipment.ShipToCountry && item?.ReportShipment.ShipToCountry !== '') ?
                            item.ReportShipment.ShipToProvince + ', ' + item.ReportShipment.ShipToCountry :
                            item?.ReportShipment.ShipToCountry }} </td>
                        <td> {{ (item?.ReportShipment.ShipFromProvince && item?.ReportShipment.ShipFromProvince !== '' &&
                            item?.ReportShipment.ShipFromCountry && item?.ReportShipment.ShipFromCountry !== '') ?
                            item.ReportShipment.ShipFromProvince + ', ' + item.ReportShipment.ShipFromCountry :
                            item?.ReportShipment.ShipFromCountry }} </td>
                    </tr>

                    <tr *ngIf="shipmentReportsReviewsData === undefined || shipmentReportsReviewsData.length === 0">
                        <td class="text-center" colspan="10">
                            <span style="align-items: center; font-size: 18px;"> {{'NoRecordsFound'|translate}} </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination-wrapper">
            <upsc-report-shipments-recent-cutom-paginator (page)="paginationChange($event)"
                [lengthChanged]="paginate.totalItems" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                [pageSizeLabel]="'Addresses per page'">
            </upsc-report-shipments-recent-cutom-paginator>
        </div>

        <!-- Cancel or Submit Buttons -->
        <div class="cancel-or-submit-container">
            <button mat-stroked-button color="primary" (click)="saveProgress()">
                <span class="material-symbols-outlined">chevron_left</span>
                <span> Save & Exit </span>
            </button>
            <button mat-flat-button color="primary" [disabled]="isError" (click)="submit()">
                <span> Submit </span>
                <span class="material-symbols-outlined">chevron_right</span>
            </button>

            <button mat-button class="save-progress-btn" (click)="cancelSubmission()">
                <span> Cancel upload </span>
            </button>
        </div>
    </div>
</section>
<section class="review-errors-content" *ngIf="showEditForm">
    <!-- Cancel or Submit Buttons -->
    <div class="cancel-or-submit-container">
        <h3 class="section-header"> Edit Shipments Data </h3>
        <upsc-report-shipments-single-upload [rowToEdit]="rowData"></upsc-report-shipments-single-upload>
        <button mat-stroked-button color="primary" (click)="cancelEdit()">
            <span class="material-symbols-outlined">chevron_left</span>
            <span> Save & Exit </span>
        </button>
        <button mat-flat-button color="primary" [disabled]="!isEditCorrect" (click)="updateRecord()">
            <span> Update </span>
            <span class="material-symbols-outlined">chevron_right</span>
        </button>
    </div>
</section>

<!-- Review Errors Success Section -->
<div class="review-errors-container" *ngIf="isCompleted">
    <div class="review-errors-header">
        <img src="../../assets/images/check_circle.svg" alt="check-circle">
        <h2 class="review-errors-header">You have successfully uploaded your file!</h2>
        <p>File Uploaded: {{recentUploadData?.Filename}}</p>
        <p>Shipment Uploaded: {{shipmentReportsReviewsData.length}}</p>
        <p>Date: {{submissionDate | date: submissionDateFormat}}</p>
        <div class="cancel-or-submit-container">
            <button mat-flat-button color="primary" (click)="backToUpload()">
                <span> Back to Bulk Upload </span>
                <span class="material-symbols-outlined">chevron_right</span>
            </button>
        </div>
    </div>
</div>