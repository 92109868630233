import { Injectable } from "@angular/core";
@Injectable()
export class ShipmentHistoryUserFields {
  Carrier: boolean = true;
  CreatedDate: boolean = true;
  ShipTo: boolean = true;
  Services: boolean = true;
  Status: boolean = true;
  Coverage: boolean = true;
  TrackingNumber: boolean = true;
  ReferenceNumber: string = 'Reference Number';
  User: boolean = true;
}
