import { Injectable } from "@angular/core";
@Injectable()
export class Claims {
    trackingnumber : string;
contactname : string;
companyname : string;
contactphone:string;
contactfax:string;
contactemail:string;
typeofdamage:string;
ParcelProAccount:string;
other:string;
customerId:string;
}
