<div class="saved-quotes white-content-page">
    <ng-container [ngTemplateOutlet]="header"></ng-container>

    <div class="ups-content-block">
        <div class="saved-quotes-info">
            <mat-icon>info_outline</mat-icon>
            <span [innerHtml]="'SavedQuotesInfo'|translate: {days: filterLastDays}"></span>
        </div>
    </div>

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!filteredSavedQuotes">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th class="text-center">{{'Carrier'|translate}}</th>
                <th class="text-center">{{'ShipDate'|translate}}</th>
                <th>{{'shipFrom'|translate}}</th>
                <th>{{'shipTo'|translate}}</th>
                <th class="text-right" *ngIf = "isEUUser">{{'EstimatedQuote'|translate}}</th>
                <th class="text-right">{{'InsuredValue'|translate}}</th>
                <th class="text-center">{{'Reference'|translate}}</th>
                <th class="text-right all"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let quote of filteredSavedQuotes | paginate:paginate">
                <td class="trigger-cell"></td>
                <td class="text-center">{{ quote.CarrierCode.toUpperCase() }}</td>
                <td class="text-center">{{ quote.ShipDate | date:'d. MMM y':'':this.translateService.currentLang}}</td>
                <td>{{ quote.ShipFromName }}</td>
                <td>{{ quote.ShipToName }}</td>                
                <td class="text-right" *ngIf = "isEUUser">{{ quote.TotalCharges | number: '1.2-2' }} {{ userCurrency }}</td>
                <td class="text-right">{{ quote.InsuredValue | number: '1.2-2' }} {{ userCurrency }}</td> 
                <td class="text-center">{{ quote.CustomerReferenceNumber || 'N/A' }}</td>
                <td>
                    <div class="actions">
                        <button mat-raised-button type="button" (click)="getSavedQuote($event, quote)">{{'View'|translate}}
                        </button>
                        <button mat-raised-button
                                *ngIf="shouldShowCreateButton(quote)"
                                type="button"
                                (click)="createShipment($event, quote)">
                            {{'Create'|translate}}
                        </button>
                        <button mat-raised-button
                                type="button"
                                class="alternative-button"
                                (click)="deleteSavedQuote($event, quote)">{{'Delete'|translate}}
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <pagination-controls [directionLinks]="true"
                         [previousLabel]="''"
                         [nextLabel]="''"
                         [autoHide]="true"
                         (pageChange)="onPageChanged($event)"></pagination-controls>
</div>

<ng-template #header>
    <div class="ups-page-header">
        <h1 class="page-title">{{'SavedQuotes'|translate}}</h1>
        <div class="page-actions">
        </div>
    </div>
</ng-template>
