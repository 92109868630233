import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'shipmentHistoryDate',
})
export class ShipmentHistoryDatePipe implements PipeTransform {

  transform(items: any[], args: any): any {
    // if (args && items) {
    //     var a = items.filter(
    // it => new Date(it.ShipDate) >= new Date(args.split('-')[0]) &&
    // new Date(it.ShipDate) <= new Date(args.split('-')[1]));
    //     return a;
    // }
    // else {
    //     return items;
    // }

    if (!items || !args) {
      return items;
    }

    const dateRanges = args.split('-');
    const dateRangeStart = moment(dateRanges[0], 'MM/DD/YYYY');
    const dateRangeEnd = moment(dateRanges[1], 'MM/DD/YYYY');

    return items.filter(
      (item) => {
        return moment(item.ShipDate, 'YYYY-MM-DDTHH:mm:ss').isBetween(dateRangeStart, dateRangeEnd, 'day', '[]');
      },
    );
  }
}
