import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { Claims } from '../../models/claims.model';
import { ClaimService } from '../../services/claim.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';

@Component({
  selector: 'upsc-investigation-form',
  templateUrl: './investigation-form.component.html',
  styleUrls: ['./investigation-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvestigationFormComponent implements OnInit {

  public investigationForm: UntypedFormGroup;
  public lossTypes: string[] = [this.translate.instant('lossTypes.shortage'), this.translate.instant('lossTypes.nonDelivered'), this.translate.instant('lossTypes.damaged'), this.translate.instant('lossTypes.others')];

  constructor(private claims: Claims,
              private claimservice: ClaimService,
              private formBuilder: UntypedFormBuilder,
              private selfDialogRef: MatDialogRef<InvestigationFormComponent>,
              private validationService: ValidationService,
              private errorHandlerService: ErrorHandlerService,
              private notificationService: NotificationService,
              private translate: TranslateService,) {
    this.GetUser();
  }

  ngOnInit() {
    //if (this.data && this.data.investigationData) {
    //  this.claims = this.data.investigationData;
    this.initForm();
    //}
  }

  initForm() {
    this.investigationForm = this.formBuilder.group({
      trackingNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      accountNo: ['',],
      contactName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      faxNumber: [''],
      email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator()])],
      lossType: ['', Validators.required],
      others: ['']
    });
  }

  GetUser() {
    this.claimservice.getClaimContact().subscribe(claim => {
      if (claim) {
        this.populateForm(claim);
      }
    }, error => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(error),
        this.translate.instant('errorgettingclaimscontact_msg'),
        NotificationType.ERROR);
    });
  }

  SaveClaims() {
    if (this.investigationForm.valid) {
      this.mapFormValuetoClaims();
      this.claimservice.submitClaim(this.claims).subscribe(response => {
        this.handleSubmitClaimSuccess(response);
        //this.GetUser();
      }, error => {        
        if (error.status === 400 && error.statusText.toString().toLowerCase() === 'tracking number provided is not on our records. please call customer support for assistance.')
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            this.translate.instant('ClaimsRequestInvalidTrackingNumber'),
            NotificationType.ERROR);
        else
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            this.translate.instant('errorsavingclaims_msg'),
            NotificationType.ERROR);
      });
    }
  }

  handleSubmitClaimSuccess(response) {
    // if (response.status === 200) {
      this.notificationService.notify('', this.translate.instant('ClaimsRequest_Msg'), NotificationType.SUCCESS);
      this.selfDialogRef.close();
    // }
    //else if (response.json().code.toString() == '400' && response.json().message.toString().toLowerCase() == 'tracking number provided is not on our records. please call customer support for assistance.')
    //this.notificationService.notify('', this.translate.instant('ClaimsRequestInvalidTrackingNumber'), NotificationType.ERROR);
  }

  onLossChanged(val) {
    if (!this.investigationForm) {
      return;
    }

    const otherControl = this.investigationForm.controls.others;

    if (val.toLowerCase() == 'others')
      this.validationService.setFormControlValidators(otherControl,
        Validators.compose([Validators.required]));
    else this.validationService.clearFormControlValidators([otherControl]);
  }

  mapFormValuetoClaims() {
    this.claims.contactname = this.investigationForm.controls['contactName'].value;
    this.claims.contactemail = this.investigationForm.controls['email'].value;
    this.claims.ParcelProAccount = this.claims.customerId = this.investigationForm.controls['accountNo'].value;
    this.claims.companyname = this.investigationForm.controls['companyName'].value;
    this.claims.contactphone = this.investigationForm.controls['phoneNumber'].value;
    this.claims.contactfax = this.investigationForm.controls['faxNumber'].value;
    this.claims.typeofdamage = this.investigationForm.controls['lossType'].value;
    this.claims.other = this.investigationForm.controls['others'].value;
    this.claims.trackingnumber = this.investigationForm.controls['trackingNumber'].value;

    if (this.claims.typeofdamage == 'Others') //If the loss type is others, populate the other's content in damagetype to post the content.
      this.claims.typeofdamage = this.claims.other;
  }


  populateForm(claimData) {
    this.investigationForm.setValue({
      contactName: claimData.contactname,
      trackingNumber: '',
      companyName: claimData.companyname,
      accountNo: claimData.customerid,
      phoneNumber: claimData.contactphone,
      faxNumber: claimData.contactfax,
      email: claimData.contactemail,
      lossType: '',
      others: ''
    })
    // this.claims.contactname = claimData.contactname;
    // this.claims.contactemail = claimData.contactemail;
    // this.claims.ParcelProAccount = claimData.customerid;
    // this.claims.customerId = claimData.customerid;
    // this.claims.companyname = claimData.companyname;
    // this.claims.contactphone = claimData.contactphone;
    // this.claims.contactfax = claimData.contactfax;
    // this.claims.typeofdamage = '0';
    // this.claims.other = '';
    // this.claims.trackingnumber = '';
  }


}
