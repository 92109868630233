import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { SiteConfig } from '../../../config/site-config';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { IRowActionData } from '../../shared/components/responsive-table/models/row-action-data.interface';
import { IRowColumn } from '../../shared/components/responsive-table/models/row-column.interface';
import { NotificationType } from '../../shared/models/notification-type';
import { IShipmentPackage } from '../../shared/models/shipments/shipment-package.interface';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { BlobService } from '../../shared/services/file/blob.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { UserService } from '../../shared/services/user/user.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { Package } from '../../ship/models/package.model';
import { ShipmentService } from '../../ship/services/shipment.service';
import { CarrierPipe } from '../pipes/carrier.pipe';
import { HighvalueShipmentStatusPipe } from '../pipes/highvalue-shipment-status.pipe';
import { RecentShipmentStatusPipe } from '../pipes/recent-shipment-status.pipe';
import { RecentShipmentService } from '../services/recent-shipment.service';
import { ShipmentHistoryService } from '../services/shipment-history.service';
import { ShipmentDetailComponent } from '../shipment-detail/shipment-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
    selector: 'upsc-recent-shipment',
    templateUrl: './recent-shipment.component.html',
    styleUrls: ['./recent-shipment.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RecentShipmentComponent implements OnInit, OnDestroy {
    public isShowQuoteId = false;
    public highvalueShipmentList: IShipmentPackage[];
    public filteredHighvalueShipmentList: IShipmentPackage[];
    public filterLastHours = 96;
    public recentShipmentList: any[];
    public shipmentdetails: any;
    public voidshipmentid: string;
    public showshipmentdetail: boolean;
    public cancelQuoteId: string;
    public dontShowHighvalueGrid = false;
    public customMessage: string;
    currentDate = new Date();
    difference_In_Time: any;
    public pendingShpimentColumns: IRowColumn[];
    public pendingShipmentItems: any[];

    public recentShpimentColumns: IRowColumn[];
    public recentShipmentItems: any[];
    public emptyTableMessage = ""
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    public dtOptions: any = {};
    public dtTrigger: Subject<any> = new Subject<any>();

    public isLoadingShipments = false;
    @ViewChild('CancelledInformation') CancelledInfo: ElementRef;
    @ViewChild('VoidedInformation') VoidedInfo: ElementRef;
    @ViewChild('ErrorCancellelation') CancelError: ElementRef;
    @ViewChild('CustomInformation') customInfo: ElementRef;
    private createHighValueShipmentSubscription: Subscription;
    private confirmShipmentSubscription: Subscription;
    private isEmpty = true;

    constructor(private recentshipmentservice: RecentShipmentService,
                private carrierPipe: CarrierPipe,
                private decimalPipe: DecimalPipe,
                private recentShipmentStatusPipe: RecentShipmentStatusPipe,
                private notificationService: NotificationService,
                private dialogService: DialogService,
                private dialog: MatDialog,
                private highValueShipimentStatusPipe: HighvalueShipmentStatusPipe,
                private blobService: BlobService,
                private shipmentService: ShipmentService,
                private spinnerService: SpinnerService,
                private utilityService: UtilityService,
                private router: Router,
                private storageService: StorageService,
                private errorHandlerService: ErrorHandlerService,
                private shipmentHistoryService: ShipmentHistoryService,
                private userService: UserService,
                private translateService: TranslateService,
    ) {
        this.pendingShpimentColumns = [
            { i18n: 'Carrier', itemProperty: 'carrier', classes: 'text-center' },
            { i18n: 'ShipDate', itemProperty: 'shipDate', classes: 'all' },
            { i18n: 'ShipTo', itemProperty: 'shipTo' },
            { i18n: 'PostalCode', itemProperty: 'zipCode', classes: 'text-center' },
            { i18n: 'ReferenceNumber', itemProperty: 'referenceNumber', classes: 'none' },
            { i18n: 'Coverage', itemProperty: 'insuredValue', classes: 'text-right' },
            { i18n: 'Status', itemProperty: 'status', classes: 'all' },
            { title: '', itemProperty: 'rowActions', isRowActions: true, classes: 'all' },
        ];

        this.recentShpimentColumns = [
            { i18n: 'Carrier', itemProperty: 'carrier', classes: 'text-center' },
            { i18n: 'ShipDate', itemProperty: 'shipDate' },
            { i18n: 'ShipTo', itemProperty: 'shipTo' },
            { i18n: 'PostalCode', itemProperty: 'zipCode', classes: 'text-center' },
            { i18n: 'ReferenceNumber', itemProperty: 'referenceNumber', classes: 'none' },
            { i18n: 'TrackingNumber', itemProperty: 'trackingNumber', classes: 'all' },
            { i18n: 'Coverage', itemProperty: 'insuredValue', classes: 'text-right' },
            { i18n: 'Status', itemProperty: 'status', classes: 'all' },
            { title: '', itemProperty: 'rowActions', isRowActions: true, classes: 'all' },
        ];
        this.translateService.onLangChange.subscribe(() =>{
            this.updateDTOptionsLanguage();
        })
        this.GetHighvalueShipments(false);
    }
    public updateDTOptionsLanguage(): void{
        this.dtOptions.language = {
                emptyTable: this.translateService.instant('dashboard.noShipments')
            };

        this.rerender();
    }
    public ngOnInit() {
        this.dtOptions = SiteConfig.tableConfig;
        this.dtOptions.language = {
            emptyTable: this.translateService.instant('dashboard.noShipments'),
        }
        
    }
        
    public ngOnDestroy() {
        this.utilityService.clearSubscriptions([
            this.createHighValueShipmentSubscription,
            this.confirmShipmentSubscription,
        ]);
    }
    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            if(this.isEmpty) {
                dtInstance.destroy();
            }
            // Call the dtTrigger to rerender again
            this.dtTrigger.next(null);
        });
    }


    GetHighvalueShipments(checkStatus: boolean) {
        this.spinnerService.show();
        this.isLoadingShipments = true;
        this.recentshipmentservice.getHighValueShipments().subscribe(
            (packages) => {
                if (packages) {
                    if (packages.length > 0) {
                        this.isEmpty = false;
                    } else {
                        this.isEmpty = true;
                    }
                    this.highvalueShipmentList = packages;

                    if (checkStatus) {
                        for (let i = 0; i < packages.length; i++) {
                            if (packages[i].Status === 10) {
                                const shipDate = moment(packages[i].ShipDate);
                                const past96Hours = moment().subtract(this.filterLastHours, 'hours');

                                // if (shipDate.isSameOrAfter(past96Hours) && shipDate.isBefore(moment())) {
                                //     this.shipmentService.aceCallback(packages[i].QuoteId).subscribe();
                                // }
                            }
                        }
                    }

                    this.filteredHighvalueShipmentList = _.cloneDeep(
                        packages.filter(
                            (item) => {
                                const lastXHoursMoment = moment().subtract(this.filterLastHours, 'hours');
                                // [MV3-1682] The pending shipments should also show shipments with a future ship date.
                                return moment(item.ShipDate).isSameOrAfter(lastXHoursMoment, 'hour');
                            }));

                    this.pendingShipmentItems = packages.map(item => this.createPendingShipmentItem(item));
                    this.dontShowHighvalueGrid = false;
                } else {
                    this.filteredHighvalueShipmentList = [];
                    this.dontShowHighvalueGrid = true;
                }

                this.dtTrigger.next(null);
                // This is called here because if we hit API in parallel, it is randomly throwing error.
                // this.GetRecentShipments();
                this.spinnerService.hide();
                this.isLoadingShipments = false;
            },
            (error) => {
                this.spinnerService.hide();
                this.isLoadingShipments = false;
                this.dtTrigger.next(null);
                console.error('Error Fetching Highvalue Shipments', error);
                // This is called here because if we parallely hit API, it is randomly throwing error.
                // this.GetRecentShipments();
            });
    }

    public onPendingShipmentActionClicked(actionData: IRowActionData<any>) {
        switch (actionData.key) {
            case 'cancel':
                this.cancelQuoteId = actionData.data.quoteId;
                $('#cancelquoteconfirmation').modal('show');

                break;
            case 'print':
                this.printWindow(actionData.data.quoteId);

                break;
        }
    }

    public onRecentShipmentActionClicked(actionData: IRowActionData<any>) {
        switch (actionData.key) {
            case 'view':
                this.shipmentHistoryService.getShipment(actionData.data.shipmentId)
                    .subscribe(
                        (shipmentPackage) => {
                            this.shipmentdetails = shipmentPackage;
                            $('#shipmentdetail').modal('show');
                        },
                    );

                break;
            case 'void':
                this.voidshipmentid = actionData.data.shipmentId;
                this.showshipmentdetail = false;
                $('#voidconfirmation').modal('show');

                break;
            case 'print':
                this.printWindow(actionData.data.shipmentId);

                break;
        }
    }

    GetRecentShipments() {
        this.recentshipmentservice.getRecentShipments().subscribe(
            (packages) => {
                // setTimeout(function () {
                if (packages) {
                    this.recentShipmentList = packages;
                    this.recentShipmentItems = packages.map(item => this.createRecentShipmentItem(item));
                }
                //  }, 500);
            },
            (error) => {
                console.error('Error Fetching Recent Shipments', error);
            });
    }

    confirmQuoteCancellation(quoteId: string) {
        this.dialogService.confirm('Quote Cancellation', 'Are you sure you want to cancel the Shipment?')
            .subscribe(
                (isConfirmed) => {
                    if (!isConfirmed) {
                        return;
                    }

                    this.CancelQuote(quoteId);
                });
    }

    CancelQuote(quoteId: string) {
        this.recentshipmentservice.deleteQuote(quoteId).subscribe(
            (response) => {
                this.notificationService.notify('The shipment has successfully been cancelled.', 'Quote Cancelled', NotificationType.SUCCESS);
                // this.GetHighvalueShipments();
                this.filteredHighvalueShipmentList = this.filteredHighvalueShipmentList.filter(item => item.QuoteId !== quoteId);
                this.dtTrigger.next(null);
            },
            (err) => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(err),
                    'Error Cancelling the quote',
                    NotificationType.ERROR);
            });
    }

    openShipmentDetail(event, quote: Package) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '80%',
            data: {
                shipmentId: quote.ShipmentId,
            },
        };

        const dialogRef = this.dialog.open(ShipmentDetailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (result) => {
            });
    }

    confirmVoidShipment(shipmentId: string) {
        this.dialogService.confirm('Void Shipment', 'Are you sure you want to void this Shipment?')
            .subscribe(
                (isConfirmed) => {
                    if (!isConfirmed) {
                        return;
                    }
                    this.VoidShipment(shipmentId);
                });
    }

    VoidShipment(shipmentid: string) {
        this.shipmentHistoryService.deleteShipment(shipmentid)
            .subscribe(response => {

                    this.notificationService.notify('YOUR SHIPMENT HAS BEEN VOIDED. THIS WILL ALSO VOID ANY RETURN LABEL GENERATED WITH IT. PLEASE NOTE THIS HAS CANCELED INSURANCE COVERAGE.',
                        'Successfully Voided Shipment', NotificationType.SUCCESS);

                    //Refresh the data so in voided status record, "Void" button will not be shown
                    this.GetRecentShipments();
                },
                error => {
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(error),
                        'Error Voiding the Shipment',
                        NotificationType.ERROR);
                });
    }

    printWindow(shipmentid: string) {
        this.userService.getCurrentUser().subscribe(
            (currentUser: any) => {
                if (!currentUser) {
                    console.error('Failed retrieving current user');
                    return;
                }

                window.open('https://uat.parcelpro.com/printnormal.aspx?shipmentid=' + shipmentid + '&sessionid=' + currentUser.sessionid, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            },
        );
    }

    public confirmShipment(event, quote: Package) {
        event.preventDefault();

        this.spinnerService.show();
        this.utilityService.clearSubscriptions([this.confirmShipmentSubscription]);
        this.confirmShipmentSubscription = this.shipmentService.confirmShipment(quote.QuoteId)
                                               .subscribe(
                                                   shipment => this.handleConfirmShipmentSuccess(shipment),
                                                   err => this.handleConfirmShipmentFailure(err),
                                               );
    }

    private createPendingShipmentItem(shipment: IShipmentPackage) {
        return this.createShipmentItem(shipment, true);
    }


    // ShipmentDetail(shipmentid: string) {
    //   this.shipmentservice.getShipment(shipmentid)
    //     .subscribe(shipmentPackage => {
    //       this.shipmentdetails = shipmentPackage;
    //     },
    //     error => {
    //       console.error('Error Fetching the Shipment Details', error);
    //     });
    // }

    private createRecentShipmentItem(shipment: IShipmentPackage) {
        return this.createShipmentItem(shipment, false);
    }

    private createShipmentItem(shipment: IShipmentPackage, isPending: boolean): any {
        return {
            shipmentId: shipment.ShipmentId,
            quoteId: shipment.QuoteId,
            carrier: this.carrierPipe.transform(shipment.CarrierCode),
            referenceNumber: shipment.ReferenceNumber,
            shipDate: moment(shipment.ShipDate).format('ll'),
            shipTo: shipment.ShipTo.CompanyName || `${ shipment.ShipTo.FirstName } ${ shipment.ShipTo.LastName }`,
            zipCode: shipment.ShipTo.Zip,
            trackingNumber: shipment.TrackingNumber,
            insuredValue: `<strong>${ this.decimalPipe.transform(shipment.InsuredValue, '1.2-2') } USD</strong>`,
            status: this.getShipmentStatusHtml(shipment, isPending),
            rowActions: this.getRowActions(shipment, isPending),
        };
    }

    private getShipmentStatusHtml(shipment: IShipmentPackage, isPending: boolean) {
        const status = isPending ?
            this.highValueShipimentStatusPipe.transform(shipment.Status) :
            this.recentShipmentStatusPipe.transform(shipment.PhysicalStatus);

        return `<span class="status ${ _.kebabCase(status) }">${ status }</span>`;
    }

    private getRowActions(shipment: IShipmentPackage, isPending: boolean) {
        const actions = [];
        if (isPending) {
            if ([2, 10, 11, 12].includes(shipment.Status)) {
                actions.push({
                    key: 'cancel',
                    i18n: 'Cancel',
                    icon: 'cancel',
                });
            }

            if ([2, 11].includes(shipment.Status)) {
                actions.push({
                    key: 'print',
                    i18n: 'Print',
                    icon: 'print',
                });
            }

            return actions;
        }

        actions.push({
            key: 'view',
            i18n: 'View',
            icon: 'visibility',
        });

        if ([0, 1, 4].includes(shipment.PhysicalStatus)) {
            actions.push({
                key: 'void',
                i18n: 'Void',
                icon: 'cancel',
            });
        }

        if ([0, 4].includes(shipment.PhysicalStatus)) {
            actions.push({
                key: 'print',
                i18n: 'Reprint',
                icon: 'print',
            });
        }

        return actions;
    }

    private handleConfirmShipmentSuccess(quote: Package) {
        this.notificationService.notify('Shipment has been confirmed', 'Success', NotificationType.SUCCESS);
        this.goToShipmentDetails(quote);
    }

    private handleConfirmShipmentFailure(err) {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed confirming shipment',
            NotificationType.ERROR);
    }

    private goToShipmentDetails(quote: Package) {
        this.storageService.set('shipment-details', quote);

        this.shipmentService.clearQuote();
        this.spinnerService.hide();

        this.router.navigate(['/ship/details']);
    }
}
