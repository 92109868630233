import { Component } from '@angular/core';

@Component({
  selector: 'upsc-reports-dropdown-menu',
  templateUrl: './reports-dropdown-menu.component.html',
  styles: []
})
export class ReportsDropdownMenuComponent {

  constructor() { }

 

}
