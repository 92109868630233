import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButton } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle, MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { ReportShipmentsPopupsComponent } from 'app/report-shipments-popups/report-shipments-popups.component';
import { NotificationType } from 'app/shared/models/notification-type';
import { IReportedShipmentFileUpload } from 'app/shared/models/shipments/reported-shipment-file-upload.interface';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import {
  ReportShipmentsRecentCutomPaginatorComponent,
} from '../report-shipments-recent-cutom-paginator/report-shipments-recent-cutom-paginator.component';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import { AppState } from '../app.state';

@Component({
  selector: 'upsc-report-shipments-recent-uploads',
  templateUrl: './report-shipments-recent-uploads.component.html',
  styleUrls: ['./report-shipments-recent-uploads.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReportShipmentsRecentCutomPaginatorComponent,
    TranslateModule,
    ElementBlockerModule,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    ReactiveFormsModule,
    MatDatepickerToggle,
    MatDatepicker,
    MatDatepickerModule,
    NgxPaginationModule,
    MatButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatInput,
    MatTooltipModule,
  ],
  providers: [
    // We'll update this dynamically
    provideMomentDateAdapter({
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
  ],
})
export class ReportShipmentsRecentUploadsComponent implements OnInit, OnDestroy {

  public isLoadingData: boolean = true;
  public recentUploadsData: unknown[] = [];
  public showRefreshButtons: boolean = false;
  public refreshingUploadStatus: boolean = false;
  public tableHeaders = ['File Name', 'Expire In', 'Imported On', 'Number of Shipments', 'Import Status', 'Imported By'];
  public allUploads: IReportedShipmentFileUpload[];
  private _fileUploads: IReportedShipmentFileUpload[] = [];
  public currentTime: Date;
  public tableDateFormat = 'MM/dd/yyyy';
  public user: User;

  public startDateFormControl = new FormControl<Date | null>(null);
  public endDateFormControl = new FormControl<Date | null>(null);
  public dateFormat = 'MM/DD/YYYY';
  public isDateInputReadonly = true;
  public minDate: Date;
  public maxDate: Date;
  public minEndDate: Date;
  public maxEndDate: Date;

  public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 5 };
  public pageIndex = 1;
  public pageSize = 100;

  public statusOptions: string[] = ['All', 'Pending', 'Accepted', 'Cancelled', 'Expired'];
  public selectedStatus: string = 'All';

  public isTouchUi = true;
  private ngDestroyed$ = new Subject<void>();
  public dayOptions = [{key: 1, value: 7}, {key: 2, value: 15}, {key: 3, value: 30}];  // Receiving NG0955 warning/error due to unused/duplicate keys when parsing in html
  public startDate: string = '';
  public endDate: string = '';

  @Input()
  public set fileUploads(value: IReportedShipmentFileUpload[]) {
    if (value) {
      this._fileUploads = value;
      this.allUploads = [...value]; // Create a shallow copy
      this.isLoadingData = false;
      this.paginate.totalItems = this._fileUploads.length;
    }
  }

  public get fileUploads(): IReportedShipmentFileUpload[] {
    return this._fileUploads;
  }
  @ViewChild('startDateInput', {
    read: MatInput,
  }) public startDateInput: MatInput;

  @ViewChild('endDateInput', {
    read: MatInput,
  }) public endDateInput: MatInput;

  @ViewChild(ReportShipmentsRecentCutomPaginatorComponent) paginator: ReportShipmentsRecentCutomPaginatorComponent;

  public constructor(
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private reportShipmentService: ReportShipmentService,
    private notificationService: NotificationService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    public readonly appState: AppState,
    private injector: Injector,
  ) {
    if (!this.user) {
      this.userService.getUser()
        .subscribe((user) => {
          this.setUserValues(user);
        });
    }

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((result) => {
        this.isTouchUi = result.matches;
      });
  }

  public ngOnInit(): void {
    this.dateFormat = this.getUserDateFormat();
    this.updateDateFormat(this.dateFormat);

    this.currentTime = new Date();
    setInterval(() => {
      this.currentTime = new Date();
      this.cdRef.detectChanges();
    }, 60000);
  }

  public ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  public timeUntil(date: Date): string {
    const now = this.currentTime;
    const futureDate = this.add12Hours(date);
    const diffMs = futureDate.getTime() - now.getTime();

    if (diffMs <= 0) {
      return '0h 0min';
    }

    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${diffHours}h ${diffMinutes}min`;
  }

  public downloadFile(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        // Trigger download using the File object
        this.triggerFileDownload(file);
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.error,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public downloadAndEdit(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        this.reportShipmentService.fileDownloaded$.next({ file: file, fileId: fileId });
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.message,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public paginationChange(paginationDetails): void {
    this.pageIndex = paginationDetails.pageIndex;
    this.pageSize = paginationDetails.pageSize;

    this.paginate.currentPage = this.pageIndex;
    this.paginate.itemsPerPage = this.pageSize;

  }

  public updatePageNumber(newPageNumber: number): void {
    if (this.paginator && this.paginator.formGroup) {
      this.paginator.formGroup.get('txtPageNumber').setValue(newPageNumber);
    }
  }

  public cancelRow(fileId: number): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: 'Cancel Submission',
        message: 'Are you sure you want to cancel this upload?',
        noMessage: 'No, Don\'t Cancel',
        yesMessage: 'Yes, Cancel',
        fileId: fileId,
      },
    });
  }

  public submitRow(fileId: number): void {
    const currentRow = this._fileUploads.find(row => row.Id === fileId);

    const submitResultSubscription = this.reportShipmentService.submitFileResult$.subscribe((result) => {
      if (result.fileId === fileId) {
        if (result.success) {
          currentRow.ImportStatus = 'Accepted';
        }

        // Unsubscribe after handling the result
        submitResultSubscription.unsubscribe();
      }
    });

    this.reportShipmentService.downloadAndSubmitFile$.next(currentRow.Id);
  }

  public filterByStatus(status: string): void {
    if (status === 'All') {
      this._fileUploads = [...this.allUploads];
    } else {
      this._fileUploads = this.allUploads.filter(upload => upload.ImportStatus === status);
    }
  
    this.paginate.totalItems = this._fileUploads.length;
    this.paginate.currentPage = 1;

    this.updatePageNumber(this.paginate.currentPage);
  }

  public setDateRange(rangePicker: MatDateRangePicker<unknown>, dayOption: number): void {
    const startDate = dayjs().add(-dayOption, 'day').toDate();
    const endDate = dayjs().toDate();

    this.startDateInput.value = startDate;
    this.endDateInput.value = endDate;

    this.applyDateRange();

    rangePicker.close();
  }

  public setDateRange2(rangePicker: MatDateRangePicker<unknown>, value: number): void {
    this.startDateFormControl.setValue(dayjs().add(-value, 'day').toDate());
    this.endDateFormControl.setValue(dayjs().toDate());

    this.applyDateRange2();
    rangePicker.close();
  }

  public applyDateRange(): void {
    const startDate = this.startDateInput.value ? new Date(this.startDateInput.value) : null;
    const endDate = this.endDateInput.value ? new Date(this.endDateInput.value) : null;

    this.filterByDate(startDate, endDate);

    this.paginate.totalItems = this._fileUploads.length;
  }

  public applyDateRange2(): void {
    const startDate = dayjs(this.startDateFormControl.value);
    const endDate = dayjs(this.endDateFormControl.value);
    if (!startDate?.isValid() || !endDate?.isValid()) {
      return;
    }

    this.filterByDate(startDate.toDate(), endDate.toDate());
    this.paginate.totalItems = this._fileUploads.length;
  }

  public onDateInputChange(): void {
    const startDateStr = this.startDateInput.value;
    const endDateStr = this.endDateInput.value;

    const startDate = this.parseDate(startDateStr);
    const endDate = this.parseDate(endDateStr);

    this.filterByDate(startDate, endDate);

    this.paginate.totalItems = this._fileUploads.length;
  }

  private filterByDate(startDate: Date, endDate: Date): void {
    if (!startDate) {
      if (!endDate) {
        this._fileUploads = [...this.allUploads];
      } else {
        this._fileUploads = this.allUploads.filter(upload => upload.ImportedOn <= endDate);
      }
    }

    if (startDate) {
      this.minEndDate = startDate;
      this.startDate = this.datePipe.transform(this.minEndDate, this.tableDateFormat.slice(0, -6));
      this._fileUploads = this.allUploads.filter(upload => upload.ImportedOn >= this.minEndDate);
    }

    if (endDate) {
      // Set the time to 23:59:59 to include the entire day
      endDate.setHours(23, 59, 59, 999);
      this.maxEndDate = endDate;
      this.endDate = this.datePipe.transform(this.maxEndDate, this.tableDateFormat.slice(0, -6));
      this._fileUploads = this._fileUploads.filter(upload => upload.ImportedOn <= this.maxEndDate);
    }
  }

  private triggerFileDownload(file: File): void {
    const url = window.URL.createObjectURL(file);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release the object URL
    window.URL.revokeObjectURL(url);
  }

  private add12Hours(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 12);
    return newDate;
  }

  private setUserValues(user: User): void {
    this.user = user;

    switch (user?.CountryCode) {
      case 'HK':
        this.tableDateFormat = 'yyyy/MM/dd HH:mm';
        break;
      case 'DE':
      case 'FR':
      case 'IT':
      case 'GB':
        this.tableDateFormat = 'dd/MM/yyyy HH:mm';
        break;
      default:
        this.tableDateFormat = 'MM/dd/yyyy HH:mm';
        break;
    }
  }

  private parseDate(dateStr: string): Date | null {
    if (!dateStr || dateStr === '') {
      return null;
    }

    const dateParts = dateStr.split('/');
    if (dateParts.length !== 3) {
      return null;
    }

    switch (this.tableDateFormat) {
      case 'yyyy/MM/dd HH:mm':
        return new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
      case 'dd/MM/yyyy HH:mm':
        return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
      default:
        return new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`);
    }
  }

  public getTranslatedStatus(status: string): string {
    const user = this.appState.user$();
    if (status === 'Cancelled' && user?.CountryCode === 'US') {
      return this.translateService.instant('RecentUploadStatusList.Canceled');
    }

    return this.translateService.instant(`RecentUploadStatusList.${status}`);
  }

  public getUserDateFormat(): string {
    switch (this.appState?.user$().CountryCode) {
      case 'DE':
      case 'FR':
      case 'IT':
      case 'GB':
        return 'DD/MM/YYYY';
      case 'HK':
        return 'YYYY/MM/DD';
      case 'US':
      default:
        return 'MM/DD/YYYY';
    }
  }

  private updateDateFormat(format: string): void {
    // Get the current provider
    const provider = this.injector.get(MAT_DATE_FORMATS);

    // Update the formats
    provider.parse.dateInput = format;
    provider.display.dateInput = format;

    // Force update the view
    this.cdRef.markForCheck();
  }
}
