import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'services',
})
export class ServicesPipe implements PipeTransform {

  transform(value: any): any {

    switch (String(value)) {
      case '01-DOM':
        return 'Priority Overnight';
      case '03-DOM':
        return '2 Day';
      case '03-INT':
        return 'International Economy';
      case '05' || '5':
        return 'Standard Overnight';
      case '06-DOM':
        return 'First Overnight';
      case '06-INT':
        return 'International First';
      case '01-INT':
        return 'International Priority';
      case '20':
        return 'Express Saver';
      case '49':
        return '2 Day AM';
      case '92':
        return 'Ground';
      case 'CA-DOM':
        return 'Intra-Canada';
      case 'EU-DOM':
        return 'Europe 1st';
      case 'FXFE':
        return 'FedEx Freight';
      case 'UPSS':
        return 'UPS Freight';
      case '01' || '1':
        return 'Next Day Air';
      // case 'EU-DOM':
      //   return 'UE Express';
      // case 'CA-DOM':
      //   return 'Intra-Canada';
      case '54':
        return 'Worldwide Express Plus';
      case '59':
        return '2nd Day Air AM';
      case '65':
        return 'Worldwide Express Saver';
      case '70':
        return 'Access Point Economy';
      case '02' || '2':
        return '2nd Day Air';
      case '03' || '3':
        return 'Ground';
      case '07' || '7':
        return 'Worldwide Express';
      case '08' || '8':
        return 'Worldwide Expedited';
      case '11':
        return 'Standard';
      case '11-CA-DOM':
        return 'Intra Canada Standard';
      case '12':
        return '3 Day Select';
      case '13':
        return 'Next Day Air Saver';
      case '14':
        return 'Next Day Air Early AM';
      case '74':
        return 'Express 12:00';
      /*  case '04' || '4': return 'UPS3DaySelect';
        case '06' || '6': return 'UPSNextDayAirEarlyAM';
        case '09' || '9': return 'FedEx2ndDay';
        case '10': return 'FedExStandardOvernight'; */
      default:
        return value;
    }
  }
}
