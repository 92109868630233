<div class="recent-uploads-layout">
    <div class="recent-uploads-content">
        <div class="grid-actions-wrapper">
            <h2>Recent Uploads</h2>

            <div class="date-picker-container">
                <div class="date-picker-wrapper">
                    <mat-form-field>
                        <mat-label>{{ 'bulkUpload.importStatus.label' | translate }}</mat-label>
                        <mat-select [(value)]="selectedStatus" (selectionChange)="filterByStatus($event.value)">
                            <mat-option *ngFor="let status of statusOptions" [value]="status">{{ getTranslatedStatus(status) }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    @if (false) {
                    <mat-form-field>
                        <mat-label>Search by Ship Dates</mat-label>
                        <mat-date-range-input [rangePicker]="rangePicker" [max]="maxDate">
                            <input matInput matStartDate [value]="startDate"
                                placeholder="{{ 'trackShipments.search.startDate' | translate }}" #startDateInput
                                (blur)="onDateInputChange()">
                            <input matInput matEndDate [value]="endDate"
                                placeholder="{{ 'trackShipments.search.endDate' | translate }}" #endDateInput
                                (blur)="onDateInputChange()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker [touchUi]="isTouchUi" #rangePicker>
                            <mat-date-range-picker-actions>
                                <div class="options">
                                    @for (dayOption of dayOptions; track dayOption.key) {
                                    <button mat-stroked-button (click)="setDateRange(rangePicker, dayOption.value)">
                                        {{ 'trackShipments.search.options.xDays' | translate: { days: dayOption.value } }}
                                    </button>
                                    }
                                </div>

                                <div class="datepicker-actions">
                                    <button mat-button matDateRangePickerCancel>{{ 'trackShipments.search.cancel' |
                                        translate }}
                                    </button>
                                    <button mat-flat-button color="primary" matDateRangePickerApply
                                        (click)="applyDateRange()">{{
                                        'trackShipments.search.apply' | translate }}
                                    </button>
                                </div>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                    }

                    <mat-form-field>
                        <mat-label>{{ 'bulkUpload.dateRangePicker.label' | translate }}</mat-label>
                        <mat-date-range-input [rangePicker]="rangePicker2"
                                              (input)="applyDateRange2()"
                                              [max]="maxDate">
                            <input matStartDate
                                   [readOnly]="isDateInputReadonly"
                                   [formControl]="startDateFormControl"
                                   placeholder="Start Date">
                            <input matEndDate
                                   [readOnly]="isDateInputReadonly"
                                   [formControl]="endDateFormControl"
                                   placeholder="End Date">
                        </mat-date-range-input>
                        @if (!isDateInputReadonly) {
                            <mat-hint>{{ dateFormat }} - {{ dateFormat }}</mat-hint>
                        }
                        <mat-error>{{ 'bulkUpload.dateRangePicker.error' | translate }}</mat-error>
                        <mat-datepicker-toggle matIconSuffix
                                               [for]="rangePicker2"></mat-datepicker-toggle>
                        <mat-date-range-picker [touchUi]="isTouchUi"
                                               #rangePicker2>
                            <mat-date-range-picker-actions>
                                <div class="options">
                                    @for (dayOption of dayOptions; track dayOption.key) {
                                        <button mat-stroked-button
                                                (click)="setDateRange2(rangePicker2, dayOption.value)">
                                            {{ 'trackShipments.search.options.xDays' | translate: { days: dayOption.value } }}
                                        </button>
                                    }
                                </div>

                                <div class="datepicker-actions">
                                    <button mat-button
                                            matDateRangePickerCancel>{{ 'trackShipments.search.cancel' | translate }}
                                    </button>
                                    <button mat-flat-button
                                            color="primary"
                                            matDateRangePickerApply
                                            (click)="applyDateRange2()">{{
                                            'trackShipments.search.apply' | translate }}
                                    </button>
                                </div>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="recent-uploads-grid-container">
            <div class="grid-wrapper" *ngIf="!isLoadingData">
                <table class="ups-table">
                    <thead>
                        <tr class="ups-table-rows">
                            <th *ngFor="let header of tableHeaders let i = index"
                                [ngClass]="{'narrow-column': i === 3}"
                                [ngClass]="{'first-header': i === 0}">
                                {{header}}
                            </th>
                        </tr>
                    </thead>
                    <tbody #tb *ngIf="fileUploads?.length > 0">
                        <tr *ngFor="let item of _fileUploads | paginate:paginate; index as i">
                            <td class="download-link">
                                <table class="fixed-table">
                                    <colgroup>
                                        <col class="col-fixed">
                                        <col class="col-fixed">
                                        <col>
                                    </colgroup>
                                    <tr>
                                        <td class="first-column">
                                            <span class="row-actions" *ngIf="item?.ImportStatus === 'Pending'">
                                                <button mat-button type="button" [matMenuTriggerFor]="actions"
                                                    class="actions">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #actions="matMenu" xPosition="before">
                                                    <button mat-menu-item type="button" (click)="submitRow(item?.Id)"
                                                        [disabled]="item?.HasErrors">
                                                        Submit
                                                    </button>
                                                    <button mat-menu-item type="button"
                                                        (click)="downloadAndEdit(item?.Id)">
                                                        Edit
                                                    </button>
                                                    <button mat-menu-item type="button" (click)="cancelRow(item?.Id)">
                                                        Cancel
                                                    </button>
                                                    <button mat-menu-item type="button"
                                                        (click)="downloadFile(item?.Id)">
                                                        Download
                                                    </button>
                                                </mat-menu>
                                            </span>
                                        </td>
                                        <td class="second-column">
                                            <span *ngIf="item?.ImportStatus === 'Accepted'"
                                                class="material-symbols-outlined download-icon"
                                                (click)="downloadFile(item?.Id)">download</span>
                                            <span *ngIf="item?.ImportStatus === 'Pending'"
                                                class="material-symbols-outlined download-icon"
                                                (click)="downloadAndEdit(item?.Id)">edit</span>
                                        </td>
                                        <td class="first-column">
                                            <span class="file-name" title="{{ item?.FileName }}">{{ item?.FileName
                                                }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="custom-centre">{{ (item?.ImportStatus === 'Pending') ?
                                timeUntil(item?.ImportedOn, currentTime) : '-' }}</td>
                            <td>{{ item?.ImportedOn | date : this.tableDateFormat }}</td>
                            <td class="narrow-column">{{ (item?.ImportStatus === 'Accepted') ? item?.NumberOfShipments :
                                '-' }}</td>
                            <td class="upload-status-wrapper">
                                <div [ngClass]="{
                                    'Accepted' : 'green-status', 
                                    'Pending' : 'blue-status', 
                                    'Cancelled' : 'yellow-status', 
                                    'Expired': 'grey-status'
                                }[item?.ImportStatus]" class="chip">
                                    {{getTranslatedStatus(item?.ImportStatus)}}
                                </div>
                                <mat-icon class="icon" *ngIf="item?.ImportStatus === 'Expired'"
                                        matTooltip="Your file expired due to inactivity and was not submitted within the allowable time limit."
                                        matTooltipPosition="above">error_outline</mat-icon>
                            </td>
                            <td>{{ item?.ImportedBy }}</td>
                        </tr>
                    </tbody>

                    <tbody #tb *ngIf="fileUploads?.length === 0">
                        <tr>
                            <td class="text-center" colspan="5">
                                <span style="font-size: 18px;"> {{ 'NoRecordsFound' | translate }} </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pagination-wrapper">
                <upsc-report-shipments-recent-cutom-paginator (page)="paginationChange($event)"
                    [lengthChanged]="paginate.totalItems" [pageIndex]="pageIndex"
                    [pageSizeOptions]="[5, 10, 25, 50]" [pageSizeLabel]="'Files per page'"
                    [customDDLPageSize]="paginate.itemsPerPage">
                </upsc-report-shipments-recent-cutom-paginator>
            </div>

            <upsc-element-blocker *ngIf="isLoadingData"></upsc-element-blocker>
        </div>
    </div>
</div>
