import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'upsc-days-dropdown-menu',
    templateUrl: './days-dropdown-menu.component.html',
})
export class DaysDropdownMenuComponent {

    public selectedText: string;
    public reportDays: string;
    @Output() notify: EventEmitter<string> = new EventEmitter<string>();

    constructor(private translateService: TranslateService) {
        //this.selectedText = translateService.instant('Last 15 Days');
        this.reportDays = '15';
    }

    ToggleDays() {
        /* if (this.reportDays == '1')
             this.selectedText = this.translateService.instant('Last 1 Day');
         else {
             this.translateService.get('Last n Days', {n: this.reportDays}).subscribe((result: string) => {
                 this.selectedText = result;
             });
         } */
        this.notify.emit(this.reportDays);
    }

}
