<div class="report-shipment-eu-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'reportShipment.title' | translate}}</h2>
        <mat-dialog-actions>
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <div class="notifications">
        <div class="notification">
            {{ 'pleaseNote' | translate}}
            <strong>{{ 'reportShipment.notification1' | translate }}</strong><br>{{ 'reportShipment.notification2' | translate }}
        </div>
    </div>

    <form [formGroup]="formGroup" class="ups-form" (ngSubmit)="onFormSubmit($event, formGroup.value)">
        <div class="form-body shipment-sections">
            <div class="shipment-section carrier-information" [formGroup]="formGroup.controls.carrierInformation">
                <h5 class="section-title">{{ 'reportShipment.carrierInformation' | translate }}</h5>
                <div class="section-content">
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{ 'reportShipment.shipFromLocation' | translate }}</mat-label>
                            <mat-select required formControlName="shipFromLocation">
                                <mat-option value="" disabled selected>{{ 'reportShipment.selectShipFromlocation' | translate }}</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                    {{ country.CountryName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.carrierInformation?.controls.shipFromLocation?.touched && formGroup.controls.carrierInformation?.controls.shipFromLocation?.invalid">
                            <span *ngIf="formGroup.controls.carrierInformation?.controls.shipFromLocation?.errors?.required">
                                <span>{{ 'reportShipment.shipFromLocationRequired' | translate }}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <upsc-element-blocker *ngIf="isCountriesLoading"></upsc-element-blocker>
                    </div>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{ 'ShipmentType' | translate }}</mat-label>
                            <mat-select required formControlName="shipmentType">
                                <mat-option value="" disabled selected>{{ 'reportShipment.selectShipmentType' | translate }}</mat-option>
                                <mat-option *ngFor="let shipmentType of shipmentTypes"
                                            [value]="shipmentType.shipmentTypeCode">
                                    {{ shipmentType.shipmentTypeName | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.carrierInformation?.controls.shipmentType?.touched && formGroup.controls.carrierInformation?.controls.shipmentType?.invalid">
                            <span *ngIf="formGroup.controls.carrierInformation?.controls.shipmentType?.errors?.required">
                                <span>{{ 'reportShipment.shipmentTypeRequired' | translate }}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{'Carrier' | translate}}</mat-label>
                            <mat-select required formControlName="carrier">
                                <mat-option value="" disabled selected>{{'reportShipment.selectCarrier'|translate}}</mat-option>
                                <mat-option *ngFor="let carrier of carriers" [value]="carrier.CarrierCode">
                                    {{ carrier.CarrierCode }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.carrierInformation?.controls.carrier?.touched && formGroup.controls.carrierInformation?.controls.carrier?.invalid">
                            <span *ngIf="formGroup.controls.carrierInformation?.controls.carrier?.errors?.required">
                                <span>{{'reportShipment.carrierRequired'|translate}}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <upsc-element-blocker *ngIf="isCarriersLoading"></upsc-element-blocker>
                    </div>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{'reportShipment.destination'|translate}}</mat-label>
                            <mat-select required formControlName="destination">
                                <mat-option value="" disabled selected>{{'reportShipment.selectDestination'|translate}}</mat-option>
                                <mat-option *ngFor="let destination of destinations" [value]="destination">
                                    {{ destination | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.carrierInformation?.controls.destination?.touched && formGroup.controls.carrierInformation?.controls.destination?.invalid">
                            <span *ngIf="formGroup.controls.carrierInformation?.controls.destination?.errors?.required">
                                <span>{{'reportShipment.destinationRequired'|translate}}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{'ServiceType'|translate}}</mat-label>
                            <mat-select required formControlName="serviceType">
                                <mat-option value="" disabled selected>{{'reportShipment.selectServiceType'|translate}}</mat-option>
                                <mat-option *ngFor="let service of filteredServices" [value]="service.ServiceCode">
                                    {{ service.Description || service.ServiceCode }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.carrierInformation?.controls.serviceType?.touched && formGroup.controls.carrierInformation?.controls.serviceType?.invalid">
                            <span *ngIf="formGroup.controls.carrierInformation?.controls.serviceType?.errors?.required">
                                <span>{{'ServiceTypeRequired'|translate}}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <upsc-element-blocker *ngIf="isServiceTypesLoading"></upsc-element-blocker>
                    </div>
                </div>
            </div>
            <div class="shipment-section tracking-information" [formGroup]="formGroup.controls.trackingInformation">
                <h5 class="section-title">{{'reportShipment.trackingInformation'|translate}}</h5>
                <div class="section-content">
                    <mat-form-field>
                        <mat-label>{{'TrackingNumber'|translate}}</mat-label>
                        <input required
                               matInput
                               #trackingNumber
                               formControlName="trackingNumber"
                               (blur)="validateTrackingNumber($event, trackingNumber.value)">
                        <mat-error *ngIf="formGroup.controls.trackingInformation?.controls.trackingNumber?.errors">
                            <span *ngIf="formGroup.controls.trackingInformation?.controls.trackingNumber?.errors?.required">
                                <span>{{'trackingnumberrequired'|translate}}</span>
                            </span>
                            <span *ngIf="formGroup.controls.trackingInformation?.controls.trackingNumber?.errors?.existed || formGroup.controls.trackingInformation?.controls.trackingNumber?.errors?.expect">
                                <span>{{'trackingNumberReported' | translate}}</span>
                            </span>
                        </mat-error>
                        <upsc-element-blocker *ngIf="isTrackingNumberValidating"></upsc-element-blocker>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'InsuredValue'|translate}}</mat-label>
                        <input type="number"
                               onfocus="this.select();"
                               required
                               matInput
                               formControlName="insuredValue">
                        <span matSuffix>{{ userCurrency }}</span>
                        <mat-error *ngIf="formGroup.controls.trackingInformation?.controls.insuredValue?.errors">
                            <span *ngIf="formGroup.controls.trackingInformation?.controls.insuredValue?.errors?.required">
                                <span>{{'InsuredValueRequired'|translate}}</span>
                            </span>
                            <span *ngIf="formGroup.controls.trackingInformation?.controls.insuredValue?.errors?.max">
                                 {{'maximumValueIs' | translate}} {{ maxInsuredValue }}
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="false">
                        <mat-label>{{'currencyCode' | translate}}</mat-label>
                        <input required matInput formControlName="currencyCode">
                        <mat-error *ngIf="formGroup.controls.trackingInformation?.controls.currencyCode?.errors">
                            <span *ngIf="formGroup.controls.trackingInformation?.controls.currencyCode?.errors?.required">
                                <span>{{'currencyRequired' | translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="shipment-section ship-to-information" [formGroup]="formGroup.controls.shipToInformation">
                <h5 class="section-title">{{'ShipToInformation' | translate}}</h5>
                <div class="section-content">
                    <mat-form-field>
                        <mat-label>{{'CompanyName'|translate}}</mat-label>
                        <input matInput formControlName="companyName">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'PhoneNumber'|translate}}</mat-label>
                        <input matInput formControlName="phoneNumber">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'FirstName'|translate}}</mat-label>
                        <input required matInput formControlName="firstName">
                        <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.firstName?.errors">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.firstName?.errors?.required">
                                <span>{{'FirstNameRequired'|translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'LastName'|translate}}</mat-label>
                        <input required matInput formControlName="lastName">
                        <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.lastName?.errors">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.lastName?.errors?.required">
                                <span>{{'LastNameRequired'|translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>{{'Country' | translate}}</mat-label>
                            <mat-select required formControlName="country">
                                <mat-option value="" disabled selected>{{'selectCountry'|translate}}</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                    {{ country.CountryName }} ({{ country.MaxCoverage }})
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.country?.touched && formGroup.controls.shipToInformation?.controls.country?.invalid">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.country?.errors?.required">
                                <span>{{'countryRequired'|translate}}</span>
                            </span>
                            </mat-error>
                        </mat-form-field>
                        <upsc-element-blocker *ngIf="isCountriesLoading"></upsc-element-blocker>
                    </div>
                    <mat-form-field>
                        <mat-label>{{'province'|translate}}</mat-label>
                        <input matInput formControlName="province">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'City'|translate}}</mat-label>
                        <input required matInput formControlName="city" maxlength="29" >
                        <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.city?.errors">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.city?.errors?.required">
                                <span>{{'CityRequired'|translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'ZipCode'|translate}}</mat-label>
                        <input [required]="isPostalCodeAware"
                               matInput
                               formControlName="postalCode">
                        <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.postalCode?.errors">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.postalCode?.errors?.required">
                                <span>{{'PostalCodeRequired'|translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Weight'|translate}}</mat-label>
                        <input type="text"
                               onfocus="this.select();"
                               required
                               matInput
                               formControlName="weight">
                        <mat-error *ngIf="formGroup.controls.shipToInformation?.controls.weight?.errors">
                            <span *ngIf="formGroup.controls.shipToInformation?.controls.weight?.errors?.required">
                                <span>{{'WeightRequired'|translate}}</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'ReferenceNumber'|translate}}</mat-label>
                        <input matInput formControlName="referenceNumber">
                    </mat-form-field>
                </div>
            </div>

            <mat-checkbox formControlName="isAgreeToTerms" class="terms">
                {{'reportShipment.terms1'|translate}} <a href="javascript:;" (click)="openTermsAndConditionsDialog($event)" > {{'reportShipment.terms2'|translate}} </a>
            </mat-checkbox>
        </div>

        <div class="form-footer">
            <div class="form-actions">
                <button type="button" mat-button mat-dialog-close>{{'Close'|translate}}</button>
                <button type="submit" mat-raised-button [disabled]="!isFormValid()">{{'Save'|translate}}</button>
            </div>
        </div>
    </form>
</ng-template>
