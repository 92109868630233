import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { MatDialog } from '@angular/material/dialog';
import { IReviewRecentUploadInterface } from 'app/delivery-defense/dd-address-confidence-tool/interfaces/IReviewRecentUpload.interface';
import { IReportShipmentRecord } from 'app/report-shipments-bulkupload/report-shipments.model';
import { ReportShipmentsPopupsComponent } from 'app/report-shipments-popups/report-shipments-popups.component';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { IReportShipmentEU } from 'app/history/report-history/report-shipment-eu-dialog/models/report-shipment-eu.interface';
import { Subscription } from 'rxjs';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { FormGroup } from '@angular/forms';
import { HistoryModule } from '../history/history.module';
import {
  ReportShipmentsRecentCutomPaginatorComponent
} from '../report-shipments-recent-cutom-paginator/report-shipments-recent-cutom-paginator.component';
import { ReportShipmentsSingleUploadComponent } from '../report-shipments-single-upload/report-shipments-single-upload.component';
import { UserService } from 'app/shared/services/user/user.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UtilityService } from 'app/shared/services/utility/utility.service';

@Component({
  selector: 'upsc-report-shipments-review-errors',
  templateUrl: './report-shipments-review-errors.component.html',
  providers: [DatePipe],
  styleUrls: ['./report-shipments-review-errors.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleGroup,
    MatButtonToggle,
    TranslateModule,
    ReportShipmentsRecentCutomPaginatorComponent,
    ReportShipmentsSingleUploadComponent,
    MatButton,
    HistoryModule,
    NgxPaginationModule,
  ],
})
export class ReportShipmentsReviewErrorsComponent implements OnInit, OnChanges, OnDestroy {
  public tableHeaders = ['Ship Date', 'Carrier', 'Service Level', 'Tracking Number', 'Insured Value', 'To Country', 'From Country']
    .map(value => ({
      label: value,
    }));
  public reviewErrorsData = [];

  public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 10 };
  public pageIndex: number = 1;
  public pageSize: number = 100;

  public savedAllErrorsData = [];
  public selectedRowIndex: number = -1;

  public isClosed: boolean = false;
  public isShowErrorsToggled: boolean = false;

  public editAddressFormGroup: FormGroup[] = [];  // Contains multiple formGroups inside
  public savedEditAddressFormGroup: FormGroup[] = [];

  public isError: boolean = false;
  public errorMessage: string = '';

  public isDeleteAllRecordsClicked: boolean = false;
  public onBlurInputHasNoError: boolean = false;
  public isRecentUpload: boolean = false;
  public isShowEdit = false;
  public hoveredRowIndex: number = -1;
  public allShipmentReportsReviewsData: IReportShipmentRecord[] = [];
  public rowData: IReportShipmentEU;
  public tableDateFormat = 'MM/dd/yyyy';
  public user: User;

  public showEditForm: boolean = false;
  public isCompleted: boolean = false;
  public isEditCorrect: boolean = false;

  public submissionDate: Date = new Date();
  public submissionDateFormat = 'MM/dd/YYYY';

  private deleteRowSubscription: Subscription;
  private submitForInsuranceSubscription: Subscription;
  private rowAfterEditSubscription: Subscription;
  private saveProgressSubscription: Subscription;
  private currentRecordIndex: number;
  private correctRecordData: IReportShipmentEU;

  @Input() public shipmentReportsReviewsData: IReportShipmentRecord[] = [];
  @Input() public recentUploadData: IReviewRecentUploadInterface;
  @Input() public fileId: number = -1;

  public constructor(
    public dialog: MatDialog,
    private reportShipmentService: ReportShipmentService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private userService: UserService,
    private datePipe: DatePipe,
    private utilityService: UtilityService
  ) {
    if (!this.user) {
      this.userService.getUser()
        .subscribe((user) => {
          this.setUserValues(user);
        });
    }
  }

  public ngOnInit(): void {
    // Update submission date format based on domain
    const currentDomainName = this.utilityService.getCurrentDomainName();
    if (currentDomainName.includes('upscapitalde.com') || 
        currentDomainName.includes('upscapitaluk.com') || 
        currentDomainName.includes('upscapitalfr.com') ||
        currentDomainName.includes('upscapitalit.com')) {
      this.submissionDateFormat = "dd/MM/yyyy";
    }

    this.subscribeToDeleteRow();
    this.subscribeToSubmit();
    this.subscribeToEdit();
    this.subscribeToCancelRecentUpload();
    this.getErrorMessage();
    if (this.recentUploadData === undefined || this.recentUploadData.BatchId.length === 0) { this.isRecentUpload = true; }

    this.allShipmentReportsReviewsData = this.shipmentReportsReviewsData;
    this.reportShipmentService.showHeaders$.next(false);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentReportsReviewsData && changes.shipmentReportsReviewsData.currentValue) {
      this.allShipmentReportsReviewsData = changes.shipmentReportsReviewsData.currentValue;
      this.resetPageIndex();
      this.getErrorMessage();
    }

    if (changes.recentUploadData && changes.recentUploadData.currentValue) {
      this.recentUploadData = changes.recentUploadData.currentValue;
      this.isRecentUpload = !this.recentUploadData?.BatchId?.length;
    }
  }

  public ngOnDestroy(): void {
    if (this.deleteRowSubscription) {
      this.deleteRowSubscription.unsubscribe();
    }

    if (this.submitForInsuranceSubscription) {
      this.submitForInsuranceSubscription.unsubscribe();
    }

    if (this.rowAfterEditSubscription) {
      this.rowAfterEditSubscription.unsubscribe();
    }

    if (this.saveProgressSubscription) {
      this.saveProgressSubscription.unsubscribe();
    }
  }

  public paginationChange(paginationDetails): void {
    this.pageIndex = paginationDetails.pageIndex;
    this.pageSize = paginationDetails.pageSize;

    this.paginate.currentPage = this.pageIndex;
    this.paginate.itemsPerPage = this.pageSize;
  }

  private getCurrentPageData(): unknown[] {
    const startIndex = (this.pageIndex - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.shipmentReportsReviewsData.slice(startIndex, endIndex);
  }

  public getIcon(item: IReportShipmentRecord): string {
    if (item.Errors?.length >= 1) {
      return `../../assets/icons/Error_16x16.svg`;
    }
    else {
      return '';
    }
  }

  public showErrors(): void {
    this.isShowErrorsToggled = true;
    this.shipmentReportsReviewsData = this.allShipmentReportsReviewsData
      .filter(item => item.Errors?.length >= 1 && item.IsDeleted === false);

    this.resetPageIndex();
  }

  public showAll(): void {
    this.shipmentReportsReviewsData = this.allShipmentReportsReviewsData
      .filter(item => item.IsDeleted === false);
    this.isShowErrorsToggled = false;
    this.resetPageIndex();
  }

  public showErrorOverlay(index: number): void {
    this.hoveredRowIndex = index;
  }

  public hideErrorOverlay(): void {
    this.hoveredRowIndex = -1;
  }

  public getErrorMessages(index: number): string[] {
    return this.allShipmentReportsReviewsData[index]?.Errors || [];
  }

  public isErrorsVisible(index: number): boolean {
    return this.hoveredRowIndex === index;
  }

  public backToUpload(): void {
    this.reportShipmentService.backToUpload$.next(null);
  }

  private resetPageIndex(): void {
    this.pageIndex = 1;
    this.paginate.currentPage = 1;
    this.paginate.totalItems = +this.shipmentReportsReviewsData.length || 0;
  }

  public showEdit(index: number): void {
    this.selectedRowIndex = (this.selectedRowIndex === index) ? -1 : index;
    if (this.selectedRowIndex >= 0) {
      this.rowData = this.allShipmentReportsReviewsData[this.selectedRowIndex].ReportShipment;
      this.currentRecordIndex = this.selectedRowIndex;
      this.isEditCorrect = false;
      this.showEditForm = true;
    }
  }

  public isEditVisible(index: number): boolean {
    return this.selectedRowIndex === index;
  }

  public cancelSubmission(): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: 'Cancel Submission',
        message: 'Are you sure you want to cancel this upload?',
        noMessage: 'No, Don\'t Cancel',
        yesMessage: 'Yes, Cancel',
        fileId: this.fileId,
      },
    });
  }

  public submit(): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: 'Submit for Insurance',
        message: `You are about to submit ${this.shipmentReportsReviewsData.length} shipment(s). Would you like to submit for Insurance coverage?`,
        noMessage: 'No, Go Back',
        yesMessage: 'Yes, Submit for Insurance',
        savedAllErrorsData: this.savedAllErrorsData,
        isRecentUpload: this.isRecentUpload,
        recentUploadData: this.recentUploadData,
      }
    });
  }

  public deleteRecords(recordIndex: number, removeAllErrors: boolean): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '312px',
      data: {
        title: 'Delete Record(s)',
        message: 'Removed record(s) will not be submitted for insurance. Are you sure you want to delete the record(s)?',
        noMessage: 'No, Don\'t Delete',
        yesMessage: 'Yes',
        removeAllErrors: removeAllErrors,
        index: recordIndex + ((this.pageIndex - 1) * this.paginate.itemsPerPage),
        isShowErrorsToggled: this.isShowErrorsToggled,
        editAddressFormGroup: this.editAddressFormGroup,
        savedAllErrorsData: this.savedAllErrorsData,
        isRecentUpload: this.isRecentUpload,
        recentUploadData: this.recentUploadData,
        reviewErrorsData: this.reviewErrorsData,
      }
    });
  }

  public saveProgress(): void {
    this.reportShipmentService.saveProgress$.next(null);
  }

  public isLastTwoRows(index: number): boolean {
    const currentPageData = this.getCurrentPageData();
    return index >= currentPageData.length - 2;
  }

  public getErrorMessage(): void {
    let errorCount = 0;

    if (!Array.isArray(this.shipmentReportsReviewsData)) {
      this.isError = false;
      this.errorMessage = 'No shipment reports data available.';
      return;
    }

    for (const element of this.shipmentReportsReviewsData) {
      if (element.Errors?.length >= 1) {
        errorCount += 1;
        this.isError = true;
      }

      if (errorCount === 0) {
        this.isError = false;
      }
    }
    if (this.isError) {
      this.errorMessage = `${errorCount} Error(s) found. Please correct the errors before submitting.`;
    }

    else {
      this.errorMessage = `No errors found in this file. Please click “Submit” to validate your shipments.`;
    }
  }

  public updateRecord(): void {
    this.allShipmentReportsReviewsData[this.currentRecordIndex].ReportShipment = this.correctRecordData;
    this.allShipmentReportsReviewsData[this.currentRecordIndex].IsEdited = true;
    this.shipmentReportsReviewsData = this.allShipmentReportsReviewsData.filter(item => item.IsDeleted === false);
    this.resetPageIndex();
    this.getErrorMessage();
    this.showEditForm = false;
  }

  public cancelEdit(): void {
    this.showEditForm = false;
  }

  private checkIfDeleteAllRecordsIsClicked(): void {
    if (this.isDeleteAllRecordsClicked) {
      this.isError = false;
      this.errorMessage = `No errors found in this file. Please click “Submit” to validate your shipments.`;
    }

    if (!this.isDeleteAllRecordsClicked) {
      this.getErrorMessage();
    }
  }

  private checkIfSelectedRow(isClosed: boolean, index: number): void {
    this.selectedRowIndex = (this.selectedRowIndex === index) ? -1 : index;
    this.isClosed = isClosed && (this.selectedRowIndex == index);
  }

  private subscribeToDeleteRow(): void {
    this.deleteRowSubscription = this.reportShipmentService.deleteRow$.subscribe((indexNumber) => {
      if (indexNumber === -1) {
        return;
      }

      this.handleDeleteRow(indexNumber);
    });
  }

  private subscribeToSubmit(): void {
    this.submitForInsuranceSubscription = this.reportShipmentService.submitForInsurance$.subscribe((isSubmit) => {
      if (isSubmit) {
        this.submitForInsurance();
      }
    });
  }

  private subscribeToEdit(): void {
    this.rowAfterEditSubscription = this.reportShipmentService.rowAfterEdit$.subscribe((data) => {
      if (data.isValid) {
        this.handleCorrectRowAfterEdit(data.data);
      } else {
        this.isEditCorrect = false;
      }
    });
  }

  private handleDeleteRow(indexNumber: number): void {
    this.allShipmentReportsReviewsData[indexNumber].IsDeleted = true;
    this.shipmentReportsReviewsData = this.allShipmentReportsReviewsData.filter(item => item.IsDeleted === false);
    this.resetPageIndex();
    this.getErrorMessage();
  }

  private submitForInsurance(): void {
    this.spinnerService.show();
    const dataToSend = this.allShipmentReportsReviewsData
      .filter(item => item.IsDeleted === false)
      .map(item => item.ReportShipment);
    let hasErrors = this.allShipmentReportsReviewsData
      .filter(item => item.IsDeleted === false)
      .some(item => item.Errors?.length >= 1);

    const trackingNumbers = new Set<string>();
    let errorMessage: string;
    for (const item of dataToSend) {
      if (!trackingNumbers.has(item.TrackingNumber)) {
        trackingNumbers.add(item.TrackingNumber);
      } else {
        hasErrors = true;
        errorMessage = 'Found duplicated Tracking Number';
        break;
      }
    }

    if (!hasErrors) {
      this.reportShipmentService.saveBulkReportShipmentEU(dataToSend)
        .subscribe({
          next: () => {
            const dataWithStringDate = dataToSend.map(item => {

              if (item.ShipDate) {
                item.ShipDate = this.datePipe.transform(
                  item.ShipDate,
                  this.tableDateFormat
                );
              }

              return item;
            });

            this.handleSaveReportShipmentEUSuccess(dataWithStringDate);
          },
          error: err => this.handleSaveReportShipmentEUFailure(err.error),
        });
    } else {
      this.handleSaveReportShipmentEUFailure(errorMessage);
    }
  }

  private handleSaveReportShipmentEUSuccess(dataSaved: IReportShipmentEU[]): void {
    this.isCompleted = true;
    this.reportShipmentService.dataSubmitted$.next({ data: dataSaved, isFinal: true, hasErrors: false });
    this.spinnerService.hide();
  }

  private handleSaveReportShipmentEUFailure(err: any): void {
    this.spinnerService.hide();
    this.notificationService.notify(
      err.Message,
      'Failed saving report shipment',
      NotificationType.ERROR
    );
  }

  private handleCorrectRowAfterEdit(data: IReportShipmentEU): void {
    this.correctRecordData = data;
    this.allShipmentReportsReviewsData[this.currentRecordIndex].Errors = [];
    this.shipmentReportsReviewsData = this.allShipmentReportsReviewsData.filter(item => item.IsDeleted === false);
    this.isEditCorrect = true;
  }

  private subscribeToCancelRecentUpload(): void {
    this.saveProgressSubscription = this.reportShipmentService.saveProgress$.subscribe({
      next: () => {
        this.handleSaveProgress();
      },
      error: (err) => {
        console.error('Error in saveProgress$ subscription:', err);
      },
    });
  }

  private handleSaveProgress(): void {
    const dataToSend = this.allShipmentReportsReviewsData
      .filter(item => item.IsDeleted === false)
      .map(item => {
        const reportShipment = { ...item.ReportShipment };

        if (reportShipment.ShipDate && (reportShipment.ShipDate as any) instanceof Date) {
          reportShipment.ShipDate = this.datePipe.transform(
            reportShipment.ShipDate,
            this.tableDateFormat
          );
        }

        return reportShipment;
      });

    const hasErrors = this.allShipmentReportsReviewsData
      .filter(item => item.IsDeleted === false)
      .some(item => item.Errors?.length >= 1);

    this.reportShipmentService.dataSubmitted$.next({ data: dataToSend, isFinal: false, hasErrors: hasErrors });
  }

  private setUserValues(user: User): void {
    this.user = user;

    switch (user?.CountryCode) {
      case 'HK':
        this.tableDateFormat = 'yyyy/MM/dd HH:mm';
        break;
      case 'DE':
      case 'FR':
      case 'IT':
        this.tableDateFormat = 'dd/MM/yyyy HH:mm';
        break;
      case 'GB':
        this.tableDateFormat = 'dd/MM/yyyy HH:mm';
        break;
      default:
        this.tableDateFormat = 'MM/dd/yyyy HH:mm';
        break;
    }
  }
}
