import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatOption, MatSelect } from '@angular/material/select';

@Component({
  selector: 'upsc-report-shipments-recent-cutom-paginator',
  templateUrl: './report-shipments-recent-cutom-paginator.component.html',
  styleUrls: ['./report-shipments-recent-cutom-paginator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatSelect,
    MatOption,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class ReportShipmentsRecentCutomPaginatorComponent implements OnInit, OnChanges {
  public length: number;
  public formGroup: UntypedFormGroup;
  public pageSize: number;
  @Output() public data = new EventEmitter();
  public totalNumPages: number;
  public finalIndex: boolean;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  @Input() public pageSizeLabel: string = 'Results per page';
  @Input() public pageIndex: number = 1;
  @Input() public pageSizeOptions: number[];
  @Input() public set lengthChanged(length: number) {
    this.length = length;
    this.totalNumPages = Math.ceil(this.length / this.customDDLPageSize);
    this.pageIndex = 1;
    this.finalIndex = this.pageIndex >= this.totalNumPages ? true : false;
  }
  @Input() public customDDLPageSize: number = 10;

  @Output() public page = new EventEmitter<PageEvent>();

  public constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      txtPageNumber: [this.pageIndex],
      ddlPageSize: [this.customDDLPageSize],
    });

    // Subscribe to page number changes
    this.formGroup.get('txtPageNumber').valueChanges.subscribe((value) => {
      this.onPageNumberChanged(value);
    });

    // Subscribe to page size changes
    this.formGroup.get('ddlPageSize').valueChanges.subscribe((value) => {
      this.onPageSizeChanged(value);
    });

    if(this.customDDLPageSize) {
      this.pageSize = this.customDDLPageSize;
    }

    this.calculateTotalPages();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageIndex && !changes.pageIndex.firstChange) {
      const newPageIndex = changes.pageIndex.currentValue;
      if (Number.isInteger(newPageIndex) && newPageIndex > 0) {
        this.formGroup.get('txtPageNumber').setValue(newPageIndex);
        this.updateFinalIndex();
      }
    }
  
    if (changes.customDDLPageSize && !changes.customDDLPageSize.firstChange) {
      const newPageSize = changes.customDDLPageSize.currentValue;
      if (Number.isInteger(newPageSize) && newPageSize > 0) {
        this.formGroup.get('ddlPageSize').setValue(newPageSize);
        this.calculateTotalPages();
        this.updateFinalIndex();
      }
    }
  }

  public emitPageEvent(pageEvent: PageEvent): void {
    this.updateFinalIndex();
    this.page.emit(pageEvent);
  }

  public onKeypressEvent(event: any, form): boolean {
    const key = event.key;

    // Allow control keys: Backspace, Delete, Arrow keys, Home, End, Tab
    if (
      key === 'Backspace' ||
      key === 'Delete' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'Home' ||
      key === 'End' ||
      key === 'Tab'
    ) {
      return; // Allow the keypress
    }

    // Allow digits (0-9)
    if (/^\d$/.test(key)) {
      const inputElement = event.target as HTMLInputElement;
      const currentValue = inputElement.value || '';
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;

      // Simulate the new value after the keypress
      let newValue: string;
      if (selectionStart !== null && selectionEnd !== null) {
        newValue =
          currentValue.substring(0, selectionStart) +
          key +
          currentValue.substring(selectionEnd);
      } else {
        newValue = currentValue + key;
      }

      const numericValue = parseInt(newValue, 10);

      // Prevent entering a page number greater than totalNumPages
      if (numericValue > this.totalNumPages || numericValue === 0) {
        event.preventDefault();
      }
    } else {
      // Prevent any other keys
      event.preventDefault();
    }
  }

  public previous(): void {
    const currentPageIndex = this.formGroup.get('txtPageNumber').value;
    if (currentPageIndex > 1) {
      this.formGroup.get('txtPageNumber').setValue(currentPageIndex - 1);
    }
  }

  public next(): void {
    const currentPageIndex = this.formGroup.get('txtPageNumber').value;
    if (currentPageIndex < this.totalNumPages) {
      this.formGroup.get('txtPageNumber').setValue(currentPageIndex + 1);
    }
  }

  private calculateTotalPages(): void {
    const pageSize = this.formGroup.get('ddlPageSize').value || this.customDDLPageSize;
    this.totalNumPages = Math.ceil(this.length / pageSize) || 1;
  }

  private updateFinalIndex(): void {
    const value = this.formGroup.get('txtPageNumber').value;
  
    if (value && /^\d+$/.test(value)) {
      const pageIndex = parseInt(value, 10);
      this.finalIndex = pageIndex >= this.totalNumPages;
    } else {
      this.finalIndex = false;
    }
  }

  private onPageNumberChanged(value: string): void {
    if (value === '') {
      // Input is empty; reset finalIndex and do not emit event
      this.finalIndex = false;
      return;
    }
  
    if (this.formGroup.get('txtPageNumber').valid) {
      const pageIndex = parseInt(value, 10);
      this.updateFinalIndex();
      const event: PageEvent = {
        length: this.length,
        pageIndex: pageIndex,
        pageSize: this.formGroup.get('ddlPageSize').value,
      };
      this.emitPageEvent(event);
    } else {
      // Optionally handle invalid input
    }
  }

  private onPageSizeChanged(value: number): void {
    this.calculateTotalPages();
    this.formGroup.get('txtPageNumber').setValue(1); // Reset to first page
    const event: PageEvent = {
      length: this.length,
      pageIndex: 1,
      pageSize: value,
    };
    this.emitPageEvent(event);
  }
}
