<mat-form-field>
    <mat-select (selectionChange)="ToggleDays()" [(ngModel)]="reportDays" placeholder="{{ selectedText |translate}}">
        <mat-option value="1">
            {{'Last 1 Day'|translate}}
        </mat-option>
        <mat-option value="7">
            {{'Last 7 Days'|translate}}
        </mat-option>
        <mat-option value="15">
            {{'Last 15 Days'|translate}}
        </mat-option>
        <mat-option value="30">
            {{'Last 30 Days'|translate}}
        </mat-option>
        <mat-option value="60">
            {{'Last 60 Days'|translate}}
        </mat-option>
        <mat-option value="90">
            {{'Last 90 Days'|translate}}
        </mat-option>
    </mat-select>
</mat-form-field>
