import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { CCTransaction } from '../../history/billing-history/models/credit-card-transaction.model'
import { CreditCard } from '../models/credit-card.model';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class CreditCardsService {

  constructor(private httpClientService: HttpClientService,    
    private apiEndpointService: ApiEndpointService) {
    
  }

  /**
   * get Credit Cards
   *
   */
  getCreditCards() {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getCreditCards')).toString();
    return this.httpClientService.get<any>(endpoint); 
  }

  changePrimaryCreditCard(data) {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('changePrimaryCreditCard')).toString();    
    return this.httpClientService.put<any>(endpoint, data); 
  }

  removeCreditCard(usercreditcardid: string) {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('removeCreditCard'))
      .param('usercreditcardid', usercreditcardid)
      .toString();
    return this.httpClientService.delete<any>(endpoint); 
  }

  getPrimaryCreditCard(): Observable<CreditCard> {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getPrimaryCreditCard')).toString();
    return this.httpClientService.get<CreditCard>(endpoint); 
  }

  chargeCreditCard(ccTransaction: CCTransaction) {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('chargeCreditCard'))
      .toString();
    return this.httpClientService.post<any>(endpoint, ccTransaction);
  }
}
