import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class BillingHistoryService {

  constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {

  }

  /**
   * Gets billing history.
   *
   * @param {string} pageIndex
   * @param {string} pageSize
   * @returns {Observable<any>}
   */
  public getBillingHistory(pageIndex?: string, pageSize?: string, fromDate?: string, toDate?: string): Observable<any> {
    const params = {};
    if (pageIndex) {
      params['page'] = pageIndex;
    }

    if (pageSize) {
      params['per_page'] = pageSize;
    }

    if (fromDate) {
      params['from'] = fromDate;
    }

    if (toDate) {
      params['to'] = toDate;
    }

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getBillingHistory'))
      .query(params)
      .toString();
    return this.httpClientService.get<any>(endpoint);
  }


  public getInvoice(invoiceId: string): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getInvoice'))
      .param('invoiceId', invoiceId)
      .toString();
    return this.httpClientService.get<any>(endpoint);
  }

  public searchInvoice(invoiceId: string): Observable<any> {
    const params = {};
    if (invoiceId) {
      params['q'] = invoiceId;
    }

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('searchInvoice'))
      .query(params)
      .toString();

    return this.httpClientService.get<any>(endpoint); //.map(res => res.json());
  }
}
