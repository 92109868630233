import { Component } from '@angular/core';

@Component({
  selector: 'upsc-cancel-quote-info',
  templateUrl: './cancel-quote-info.component.html',
  styles: []
})
export class CancelQuoteInfoComponent {

  constructor() { }

 

}
