<div id="outer">
    <div class="inner"> 
        <a (click)="previous()" [ngClass]="{'disabled-link': pageIndex == 1, 'prev-and-next-btn': pageIndex !== 1}"
        href="javascript:;" ><span class="arrow">&#8249;&nbsp;</span>Previous</a>
    </div>
    <div class="inner">
        <form [formGroup]="formGroup">
            <mat-form-field appearance="outline" style="height:25px; width: 50px; align-content: center;">
                <input matInput type="text" formControlName="txtPageNumber"
                (keydown)="onKeypressEvent($event, formGroup.value)">
            </mat-form-field>
        </form>
    </div>
    <div class="inner">&nbsp;<span>of </span>&nbsp;<span>{{ totalNumPages === 0? 1: totalNumPages }}</span></div>
    <div class="inner"> 
        <a (click)="next()" [ngClass]="{'disabled-link': finalIndex, 'prev-and-next-btn': !finalIndex}"
        href="javascript:;" >Next <span class="arrow">&nbsp;&#8250;</span></a>
    </div>
    <div class="inner"> 
        <form [formGroup]="formGroup" >
            <mat-form-field appearance="outline" style="width: 160px;" floatLabel="always">
                <mat-label>{{ pageSizeLabel }}</mat-label>
                <mat-select placeholder="{{ pageSizeLabel }}" formControlName="ddlPageSize">
                    <mat-option *ngFor="let pSize of pageSizeOptions" [value]="pSize">
                        {{pSize}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</div> 